import React, { useEffect, useState } from "react";
import ImageResizer from "react-image-file-resizer";
import { withStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { teal, grey } from "@material-ui/core/colors";
import "./product.css";
import { ethers } from "ethers";
import { addProduct } from "apiServices";
import moment from "moment";
import toast from "react-hot-toast";
import { getCollectionsOwner } from "apiServices";
import { validationMessage } from "config/messages";
import { messages } from "config/messages";
import WalletCheckModal from "components/common/walletCheckModal";
import { Logout } from "helpers/setters";
import { getCurrentAccount, getCurrentProvider } from "../authentication/connectWallet";
import { account } from "config/localStorageNames";
import closeIcon from "../../../assets/images/small-logos/close-btn.png";
import "./../../../App.css";
import { pageLimit } from "helpers/constants";
import ImgIcon from "../../../assets/upload.png";
import { getCollectionInfo } from "apiServices";
import PreviewProduct from "./previewProduct";
import eventEmitter from "./../../../events/events";
import { getNetworkNameByChain } from "config/rpcs";
import { checkIfOwned } from "helpers/getters";
import Loader from "components/common/Loader";
import { Upload } from "@mui/icons-material";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    overFlow: "hidden",
  },
  primaryColor: {
    color: teal[500],
  },
  secondaryColor: {
    color: grey[700],
  },

  padding: {
    padding: 0,
  },
  mainHeader: {
    backgroundColor: "rgba(0, 122, 219, 0.8)",
    padding: 20,
    alignItems: "center",
  },
  mainContent: {
    padding: 40,
    margin: 0,
  },
  secondaryContainer: {
    padding: "20px 25px",
    backgroundColor: grey[200],
  },
});

function AddProduct(props) {
  const { classes } = props;
  const [productName, setProductName] = useState("");
  const [currAccount, setCurrAccount] = useState("");
  const [addEvent, setAddEvent] = useState(0);
  const [startDatetime, setStartDatetime] = useState("");
  const [endDatetime, setEndDatetime] = useState("");
  const [collections, setCollections] = useState("none");
  const [selectedCollection, setSelectedCollection] = useState("");
  const [productDesc, setProductDesc] = useState("");
  const [productType, setProductType] = useState("");
  const [wrongWallet, setWrongWallet] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [productImage, setProductImage] = useState();
  const [showPreview, setShowPreview] = useState(false);
  const [previewData, setPreviewData] = useState({
    edit: false,
  });
  const [notes, setNotes] = useState("");
  const [validatingInput, setValidatingInput] = useState(false);
  const [compressedImage, setCompressedImage] = useState();
  useEffect(() => {
    setCurrAccount(localStorage.getItem(account)?.toLowerCase());
  }, [localStorage.getItem(account)]);

  useEffect(() => {
    const fetchCollections = async () => {
      let coll = await getCollectionsOwner({
        page: currentPage,
        limit: 100000,
        owner: currAccount,
      });

      if (coll?.results?.length > 0) {
        let data = [];
        coll = coll?.results[0]?.filter((c) => {
          return c?.isImported === true;
        });
        setCollections(coll);
      }
    };
    if (currAccount) fetchCollections();
  }, [currAccount]);

  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const type = e.target.files[0].type;
    if (
      type !== "image/webp" &&
      type !== "image/jpeg" &&
      type !== "image/jpg" &&
      type !== "image/png" &&
      type !== "image/gif"
    ) {
      toast.error("Please enter recommended file format");
      return;
    }
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      // const type1 = file?.type?.split("/");
      // const fileType = type1[1];
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);

      if (e.target.files && e.target.files[0]) {
        ImageResizer.imageFileResizer(
          file,
          300, // width
          400, // height
          "webp", // format
          100, // quality
          0, // rotation
          (uri) => {
            setCompressedImage(uri);
          },
          "file" // outputType
        );

        setProductImage(e.target.files[0]);
        setPreviewData({
          ...previewData,
          image: URL.createObjectURL(e.target.files[0]),
        });
      }
    }
  };

  let postData = async () => {
    try {
      const col = await getCollectionInfo({
        collectionAddress: selectedCollection,
      });

      let prodLimit = col?.productLimit;
      let totalProducts = col?.totalProducts;

      if (Number(totalProducts) + 1 > Number(prodLimit)) {
        toast.error(messages.productLimitReached);
        setAddEvent(0);
        return;
      }
      setShowPreview(false);
      setAddEvent(1);
      let epochStartime = moment(startDatetime).unix();
      let epochEndTime = moment(endDatetime).unix();
      let signature = await sign();
      if (signature === false) {
        setAddEvent(0);
        return;
      }

      const messageSender = ethers.utils.verifyMessage(messages.signMessage, signature);
      if (messageSender?.toLowerCase() !== currAccount?.toLowerCase()) {
        setWrongWallet(true);
        setAddEvent(0);
        return;
      }

      let isOwned = await checkIfOwned(
        selectedCollection,
        messageSender,
        getNetworkNameByChain[col?.chainId]
      );

      if (!isOwned) {
        toast.error(messages.collectionNotOwned);
        setAddEvent(0);
        await props.handleReloadProducts();
        return;
      }

      let fd = new FormData();
      fd.append("productName", productName);
      fd.append("productCreator", currAccount);
      fd.append("signature", signature);
      fd.append("message", messages.signMessage);
      fd.append("walletAddress", currAccount);
      fd.append("productType", productType);
      fd.append("productDesc", productDesc);
      fd.append("startTime", epochStartime);
      fd.append("endTime", epochEndTime);
      fd.append("collectionAddress", col?.collectionAddress);
      fd.append("chainId", col?.chainId);
      fd.append("filename1", productImage);
      fd.append("lowfilename1", compressedImage);
      fd.append("notes", notes.trim() === "" ? "" : notes);

      let res = await addProduct(fd);
      if (res === "Product limit reached") {
        toast.error(messages.productLimitReached);
        setAddEvent(0);
      } else if (res) {
        await props.handleReloadProducts();
        toast.success(messages.addProductSuccess);
        setAddEvent(2);
      } else {
        toast.error(messages.addProductError);
        setAddEvent(0);
      }
    } catch (e) {
      console.log("err", e);
      setAddEvent(0);
    }
    await props.handleReloadProducts();
  };

  eventEmitter.removeAllListeners("addProduct");
  eventEmitter.on("addProduct", postData);

  const sign = async () => {
    try {
      let provider = await getCurrentProvider();
      // await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      let rawMessage = messages.signMessage;
      const signature = await signer.signMessage(rawMessage);
      const messageSender = ethers.utils.verifyMessage(rawMessage, signature);
      return signature;
    } catch (e) {
      console.log("err", e);
      if (e.code === "ACTION_REJECTED") {
        toast.error(messages.userDenied);
        setAddEvent(0);
        return false;
      }
    }
  };

  function handleChange(ev) {
    if (!ev.target["validity"].valid) {
      toast.error(messages.invalidDateTime);
      return;
    }
    const dt = ev.target["value"];
    setStartDatetime(dt);
  }

  const handleConfirmClose = () => {
    setWrongWallet(false);
  };

  const handleConfirm = async () => {
    setWrongWallet(false);
    await Logout();
  };

  const handlePreviewProduct = async () => {
    try {
      setValidatingInput(true);
      setTimeout(async () => {
        let originallyConnectedAccount = await getCurrentAccount();
        if (currAccount?.toLowerCase() !== originallyConnectedAccount?.toLowerCase()) {
          setWrongWallet(true);
          setValidatingInput(false);
          return;
        }
        if (selectedCollection === "" || selectedCollection === undefined) {
          toast.error(validationMessage("Collection", "Select"));
          setValidatingInput(false);
          return;
        }
        if (productName === "" || productName?.trim() === "" || productName === undefined) {
          toast.error(validationMessage("Item Name", "Add"));
          setValidatingInput(false);

          return;
        }
        if (
          productType === "" ||
          productType === undefined ||
          productType?.toLowerCase() === "Please Select type".toLowerCase()
        ) {
          toast.error(validationMessage("Item Type", "Select"));
          setValidatingInput(false);
          return;
        }
        if (productDesc === "" || productDesc?.trim() === "" || productDesc === undefined) {
          toast.error(validationMessage("Item Description", "Add"));
          setValidatingInput(false);

          return;
        }
        if (startDatetime === "" || startDatetime === undefined) {
          toast.error(validationMessage("Start Date", "Choose"));
          setValidatingInput(false);
          return;
        }
        if (endDatetime === "" || endDatetime === undefined) {
          toast.error(validationMessage("End Date", "Choose"));
          setValidatingInput(false);
          return;
        }
        if (productImage === "" || productImage === null || productImage === undefined) {
          toast.error(validationMessage("Item Image", "Choose"));
          setValidatingInput(false);

          return;
        }
        if (startDatetime > endDatetime) {
          toast.error("End date cannot be less than start date");
          setValidatingInput(false);
          return;
        }
        setValidatingInput(false);
        setShowPreview(true);
      }, 1000);
    } catch (error) {
      toast.error(messages.addProductError);
      console.log("error in form", error);
    }
  };

  const UploadHandle = () => {
    document.getElementById("uploadLogoImg").click();
  };

  return validatingInput ? (
    <Loader message="Validating Input.." />
  ) : addEvent === 1 ? (
    <Loader
    //  message="Submitting Form.."
    />
  ) : addEvent === 2 ? (
    "Completed"
  ) : (
    <Dialog
      className={classes.root}
      fullWidth
      maxWidth="md"
      open={props.show}
      onClose={() => {
        setAddEvent(0);
        props.handleClose();
      }}
      id="modal_container"
    >
      {wrongWallet ? (
        <WalletCheckModal
          header="Wrong Account Connected"
          handleClose={handleConfirmClose}
          handleConfirm={handleConfirm}
        />
      ) : (
        ""
      )}
      {showPreview ? (
        <PreviewProduct
          show={true}
          previewData={previewData}
          handleClose={() => setShowPreview(false)}
        />
      ) : (
        ""
      )}
      <DialogContent className={classes.padding} style={{ overflow: "hidden" }}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container direction="row" className={classes.mainHeader}>
              <Grid item xs={11}>
                <Typography variant="h5">Item Form</Typography>
              </Grid>
              <Grid
                item
                xs={1}
                align="right"
                className={classes.padding}
                onClick={() => {
                  setAddEvent(0);
                  props.handleClose();
                }}
              >
                <IconButton
                  edge="start"
                  align="right"
                  color="inherit"
                  aria-label="Close"
                  style={{ padding: 8 }}
                >
                  <img src={closeIcon} alt="close" />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              className={classes.mainContent}
              spacing={1}
            >
              {" "}
              <div className="outerrrBorder">
                <Grid item xs={12} className="modalInput addCollectionInputs">
                  <label htmlFor="collection" className="required">
                    Collections
                  </label>
                  <select
                    className="form-select"
                    disabled={collections === "none"}
                    id="collection"
                    aria-label="Select Option"
                    value={selectedCollection}
                    onChange={(e) => {
                      setSelectedCollection(e.target.value);
                    }}
                  >
                    <option selected>Please Select Collection</option>
                    {collections !== "none" && collections?.length > 0
                      ? collections.map((option, i) => {
                          return (
                            <option key={i} value={option?.collectionAddress}>
                              {option?.collectionName} ({option?.collectionAddress?.slice(0, 5)}...
                              {option?.collectionAddress?.slice(38, 42)})
                            </option>
                          );
                        })
                      : ""}
                  </select>
                </Grid>
                <Grid item xs={12} className="modalInput addCollectionInputs">
                  <label htmlFor="InputName" className="required">
                    Item Name
                  </label>
                  <input
                    type="text"
                    id="InputName"
                    className="form-control"
                    placeholder="Please Enter Name"
                    value={productName}
                    onChange={(e) => {
                      setPreviewData({
                        ...previewData,
                        pName: e.target.value,
                      });
                      setProductName(e.target.value);
                    }}
                    maxLength={25}
                  />
                </Grid>

                <Grid item xs={12} className="modalInput addCollectionInputs">
                  <label htmlFor="product-type" className="required">
                    Item Type
                  </label>
                  <select
                    className="form-select"
                    aria-label="Select Option"
                    id="product-type"
                    value={productType}
                    onChange={(e) => {
                      setProductType(e.target.value);
                    }}
                  >
                    <option selected>Please Select type</option>
                    <option value="Virtual">Virtual</option>
                    <option value="Physical">Physical</option>
                  </select>
                </Grid>

                <Grid item xs={12} className="modalInput addCollectionInputs">
                  <label htmlFor="product-desc" className="required">
                    Item Description
                  </label>
                  <textarea
                    rows="3"
                    id="product-desc"
                    className="form-control"
                    placeholder="Please Enter Description"
                    value={productDesc}
                    onChange={(e) => {
                      setProductDesc(e.target.value);
                      setPreviewData({
                        ...previewData,
                        desc: e.target.value,
                      });
                    }}
                    maxLength={300}
                  />
                </Grid>

                <Grid item xs={12} className="modalInput addCollectionInputs">
                  <label htmlFor="startDate" className="required">
                    Start Date
                  </label>
                  <input
                    id="startDate"
                    type="datetime-local"
                    className="form-control"
                    placeholder="Please Enter Start Date"
                    value={startDatetime}
                    min={moment(new Date()).format().substring(0, 16)}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} className="modalInput addCollectionInputs">
                  <label htmlFor="endDate" className="required">
                    End Date
                  </label>
                  <input
                    id="endDate"
                    type="datetime-local"
                    className="form-control"
                    placeholder="Please Enter End Date"
                    value={endDatetime}
                    min={moment(new Date()).format().substring(0, 16)}
                    onChange={(e) => {
                      setEndDatetime(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} className="modalInput addCollectionInputs">
                  <label htmlFor="product-notes">Notes</label>
                  <textarea
                    rows="3"
                    id="product-notes"
                    className="form-control"
                    placeholder="Please Notes Here"
                    value={notes}
                    onChange={(e) => {
                      setNotes(e.target.value);
                    }}
                    maxLength={300}
                  />
                </Grid>
                <Grid item xs={12} className="modalInput addCollectionInputs">
                  <label htmlFor="uploadLogoImg" className="required">
                    Item Image
                  </label>
                  <div className="form-control d-flex flex-wrap border-0 px-0">
                    <div className="chooseImageDiv">
                      <div className="chooseImageDivInner">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          className="field_Bar"
                        >
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            ref={imageUploader}
                            style={{
                              display: "none",
                            }}
                            id="uploadLogoImg"
                          />
                          <div
                            className="update_btn pointer"
                            style={{
                              height: "100px",
                              width: "100%",
                              position: "relative",
                            }}
                            onClick={() => imageUploader.current.click()}
                          >
                            {productImage ? (
                              <img
                                alt=""
                                ref={uploadedImage}
                                src={URL.createObjectURL(productImage)}
                                className="img-fluid profile_circle_img admin_profile_img"
                              />
                            ) : (
                              <img
                                alt=""
                                ref={uploadedImage}
                                src={ImgIcon}
                                className="img-fluid profile_circle_img admin_profile_img"
                              />
                            )}
                          </div>
                          <button
                            type="button"
                            className="pointer text-center mt-2 justify-content-center"
                            onClick={UploadHandle}
                          >
                            Choose File
                          </button>
                        </div>
                      </div>
                    </div>
                    <p className="recommendedText">
                      {" "}
                      We recommend an image of at least 450x450. PNG, JPG or GIF. Max 100 mb.
                    </p>
                  </div>
                  {/* <Grid item xs={10} align="center" >
                                <Button variant="contained" color="inherit" id="button-submit" onClick={() => { postData() }}>Add Collection</Button>
                            </Grid> */}
                </Grid>
              </div>
              <Grid item xs={12} align="center">
                <Button
                  variant="contained"
                  color="inherit"
                  id="button-submit"
                  className="textCapitalize "
                  onClick={handlePreviewProduct}
                >
                  Add Item
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default withStyles(styles)(AddProduct);
