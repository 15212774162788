import React, { useState } from "react";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { ColorRing } from "react-loader-spinner";
import { addFaq } from "../../../apiServices";
import "./faq.css";
const AddFaq = () => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const [loader, setLoader] = useState();
  const [disabledSubscribe, setDisabledSubscribe] = useState();

  const history = useHistory();
  const addFaqHandler = async (e) => {
    e.preventDefault();
    setLoader(true);
    setDisabledSubscribe(true);
    if (question?.trim() == "") {
      setTimeout(() => {
        toast.error("Please Fill Question Field");
        setLoader(false);
        setDisabledSubscribe(false);
      }, 800);
      return;
    } else if (answer?.trim() == "") {
      setTimeout(() => {
        toast.error("Please Fill Answer Field");
        setLoader(false);
        setDisabledSubscribe(false);
      }, 800);
      return;
    } else {
      let data = { question, answer };
      const res = await addFaq(data);
      console.log(res);
      if (res.statusCode == 200) {
        setQuestion("");
        setAnswer("");
        setTimeout(() => {
          toast.success("FAQ Saved");
          setLoader(false);
          history.push("/faqList");
          setDisabledSubscribe(false);
        }, 1000);
      } else if (res.statusCode == 406) {
        setTimeout(() => {
          toast.error("Role Invalid");
          setLoader(false);
          setDisabledSubscribe(false);
        }, 1000);
      } else if (res.statusCode == 404) {
        setTimeout(() => {
          toast.error("Role Invalid");
          setLoader(false);
          setDisabledSubscribe(false);
        }, 1000);
      } else {
        setTimeout(() => {
          toast.error("FAQ Saving Error!");
          setLoader(false);
          setDisabledSubscribe(false);
        }, 1000);
      }
    }
  };
  return (
    <>
      <div className="collection__main">
        <VuiBox alignItems="center" mb="22px">
          <VuiTypography variant="lg" color="black" align="center">
            <div className="field_Bar">
              <form action="">
                <div className="fields_div"></div>
                <VuiButton
                  variant="gradient"
                  color="info"
                  size="medium"
                  onClick={() => history.push("/faqList")}
                >
                  FAQs
                </VuiButton>
              </form>
            </div>
            <section className="Banner_section my-0">
              <div className="container_FAQ ">
                <div className="banner_container">
                  <h2>Add Frequently Asked Questions</h2>
                </div>
              </div>
            </section>

            <section className="hot_collection">
              <div className="col-md-8 offset-md-2 mt-md-5">
                <form className="needs-validation">
                  <div className="form-row row ">
                    <div className="col-md-12 mx-auto mb-3 mt-3">
                      <input
                        type="text"
                        className="form-control p-2 "
                        placeholder="Type Question"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        required
                        disabled={disabledSubscribe}
                      />
                    </div>

                    <div className="col-md-12 mx-auto mb-3 mt-3">
                      <textarea
                        className="form-control"
                        placeholder="Type Answer"
                        rows="8"
                        value={answer}
                        onChange={(e) => setAnswer(e.target.value)}
                        required
                        disabled={disabledSubscribe}
                      ></textarea>
                    </div>
                    <div className="col-md-8 mx-auto mt-3 mb-3 field_Bar ">
                      <button
                        type="submit"
                        className="mx-auto justify-content-center"
                        onClick={addFaqHandler}
                        disabled={disabledSubscribe}
                      >
                        Save
                        {loader ? (
                          <ColorRing
                            visible={true}
                            height="25"
                            width="25"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
                          />
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </VuiTypography>
        </VuiBox>
      </div>
    </>
  );
};

export default AddFaq;
