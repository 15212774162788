import React, { useEffect, useState } from "react";
import { withStyles, MenuItem } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { teal, grey } from "@material-ui/core/colors";
import "./assign.css";
import { ethers } from "ethers";
import toast from "react-hot-toast";
import { assignProducts } from "apiServices";
import { validationMessage } from "config/messages";
import { messages } from "config/messages";
import { account } from "config/localStorageNames";
import { getCurrentAccount, getCurrentProvider } from "../authentication/connectWallet";
import WalletCheckModal from "components/common/walletCheckModal";
import closeIcon from "../../../assets/images/small-logos/close-btn.png";
import { checkIfOwned } from "helpers/getters";
import { getNetworkNameByChain } from "config/rpcs";
import { exportInstance } from "apiServices";
import erc721Abi from "./../../../config/abis/erc721.json"
import Loader from "components/common/Loader";
import { Logout } from "./../../../helpers/setters";


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    overFlow: "hidden",
  },
  primaryColor: {
    color: teal[500],
  },
  secondaryColor: {
    color: grey[700],
  },

  padding: {
    padding: 0,
  },
  mainHeader: {
    backgroundColor: "rgba(0, 122, 219, 0.8)",
    padding: 20,
    alignItems: "center",
  },
  mainContent: {
    padding: 40,
  },
  secondaryContainer: {
    padding: "20px 25px",
    backgroundColor: grey[200],
  },
});

function AssignProductForm(props) {
  const { classes } = props;
  const [selectedTokenId, setSelectedTokenId] = useState("");
  const [currAccount, setCurrAccount] = useState("");
  const [assignEvent, setAssignEvent] = useState(0);
  const [wrongWallet, setWrongWallet] = useState(false);
  const [collection, setCollection] = useState();
  const [productName, setProductName] = useState();
  const [selectedTokenType, setSelectedTokenType] = useState("single")
  const [validatingInput, setValidatingInput] = useState(false)

  useEffect(() => {
    setCurrAccount(localStorage.getItem(account)?.toLowerCase());
  }, [localStorage.getItem(account)]);

  useEffect(() => {
    setProductName(props?.selectedProduct?.productName);
    setCollection(props?.selectedProduct?.collectionData[0]);

  }, [props.selectedProduct]);


  const handleOptionChange = (e) => {
    setSelectedTokenId("")
    setSelectedTokenType(e.target.value)
    if (e.target.value === "ALL") {
      setSelectedTokenId("ALL")
    }
  }
  const handleConfirmClose = () => {
    setWrongWallet(false);
  };


  let postData = async () => {
    try {
      setValidatingInput(true)
      console.log("props?.selectedProduct", props?.selectedProduct)
      let originallyConnectedAccount = await getCurrentAccount();
      if (currAccount?.toLowerCase() !== originallyConnectedAccount?.toLowerCase()) {
        setWrongWallet(true);
        setValidatingInput(false)
        return;
      }

      if (selectedTokenId == "") {
        toast.error(validationMessage("Token Id", "Select"));
        setValidatingInput(false)
        return;
      }

      if (selectedTokenType == "") {
        toast.error(validationMessage("Token Type", "Select"));
        setValidatingInput(false)
        return;
      }

      let contract = await exportInstance(props?.selectedProduct?.collectionAddress, erc721Abi, props?.selectedProduct?.collectionData?.length > 0 ? getNetworkNameByChain[props?.selectedProduct?.collectionData[0]?.chainId] : getNetworkNameByChain[80002])
      let totalSupply = await contract.totalSupply()
      console.log("totalSupply", totalSupply)
      const regex1 = /^[0-9]+(-[0-9]+)+$/;
      const regex3 = /^[0-9]+$/;
      const regex4 = /^([0-9]*)+(,[0-9]+)+$/;

      let list = []
      if (selectedTokenType === "single" && regex3.test(selectedTokenId)) {
        list.push(selectedTokenId)
      }


      else if (selectedTokenType === "range" && regex1.test(selectedTokenId)) {
        let splitted = selectedTokenId.split("-")
        let startIndex = splitted[0]
        let lastIndex = splitted[1]

        if (Number(startIndex) >= Number(lastIndex)) {
          toast.error(messages.startEndIndexError)
          setValidatingInput(false);
          return
        }
        console.log("splitted", splitted)
        console.log("startIndex", startIndex, "lastIndex", lastIndex)
        for (let i = Number(startIndex); i < Number(lastIndex); i++) {
          list.push(i)
        }
      }


      else if (selectedTokenType === "comma" && regex4.test(selectedTokenId)) {
        for (let i = 0; i < selectedTokenId?.length; i++) {
          list.push(selectedTokenId[i])
        }
      }

      else if (selectedTokenType === "ALL") {

      }
      else {
        toast.error("Invalid Token Format")
        setValidatingInput(false);
        return
      }

      // Check if valid token Ids
      list.sort()

      if (Number(list[list.length - 1]) > Number(totalSupply)) {
        toast.error("Range is out of bound")
        setValidatingInput(false);
        return
      }

      // if (
      //   selectedTokenId === "" ||
      //   regex1.test(selectedTokenId) ||
      //   regex2.test(selectedTokenId) ||
      //   selectedTokenId == "ALL" ||
      //   regex3.test(selectedTokenId) ||
      //   regex4.test(selectedTokenId)
      // ) {
      // } else {
      //   toast.error(validationMessage("Valid Token Id", "Add"));
      //   return;
      // }

      setValidatingInput(false)
      setAssignEvent(1);

      let signature = await sign();
      if (signature === false) {
        setAssignEvent(0);
        return;
      }
      const messageSender = ethers.utils.verifyMessage(messages.signMessage, signature);
      let isOwned = await checkIfOwned(props?.selectedProduct?.collectionAddress, messageSender, props?.selectedProduct?.collectionData?.length > 0 ? getNetworkNameByChain[props?.selectedProduct?.collectionData[0]?.chainId] : getNetworkNameByChain[80002])

      if (!isOwned) {
        toast.error(messages.collectionNotOwned)
        setAssignEvent(0)
        props.handleClose()
        return;
      }

      let reqBody = {
        tokenId: selectedTokenId,
        productId: props?.selectedProduct?.productId,
        collectionAddress: collection?.collectionAddress,
        signature: signature,
        walletAddress: currAccount,
        message: messages.signMessage,
        chainId: collection?.chainId,
      };
      let res = await assignProducts(reqBody);
      if (res) {
        toast.success(messages.assignProductSuccess);
        props.handleReloadProducts()
        props.handleClose();
        setAssignEvent(2);
      } else {
        toast.error(messages.assignProductError);
        setAssignEvent(0);
      }
    } catch (e) {
      console.log("err", e);
      setAssignEvent(0);
    }
  };

  const sign = async () => {
    try {
      let provider = await getCurrentProvider();
      // await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      let rawMessage = messages.signMessage;
      console.log(rawMessage);
      const signature = await signer.signMessage(rawMessage);
      const messageSender = ethers.utils.verifyMessage(rawMessage, signature);
      console.log("signature", signature, messageSender);
      return signature;
    } catch (e) {
      console.log("err", e);
      if (e.code === "ACTION_REJECTED") {
        toast.error(messages.userDenied);
        setAssignEvent(2);
        return false;
      }
    }
  };


  const handleConfirm = async () => {
    setWrongWallet(false);
    await Logout();
  };
  

  return validatingInput ? <Loader
    message=""
  /> :
    assignEvent === 1 ? (
      <Loader
      // message="" 
      />
    ) : assignEvent === 2 ? (
      ""
    ) : (
      <Dialog
        className={`${classes.root} borderRd25`}
        fullWidth
        maxWidth="md"
        open={props.show}
        onClose={() => {
          props.handleClose();
        }}
      >
        {wrongWallet ? (
          <WalletCheckModal
            header="Wrong Account Connected"
            handleClose={handleConfirmClose}
            handleConfirm={handleConfirm}
          />
        ) : (
          ""
        )}
        <DialogContent className={classes.padding} style={{ overflow: "hidden" }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container direction="row" className={classes.mainHeader}>
                <Grid item xs={11}>
                  <Typography variant="h5"> Assign Item</Typography>
                </Grid>

                <Grid
                  item
                  xs={1}
                  align="right"
                  className={classes.padding}
                  onClick={() => {
                    setAssignEvent(0);
                    props.handleClose();
                  }}
                >
                  <IconButton
                    edge="start"
                    align="right"
                    color="inherit"
                    aria-label="Close"
                    style={{ padding: 8 }}
                  >
                    <img src={closeIcon} alt="close" />
                  </IconButton>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="center"
                className={classes.mainContent}
                spacing={1}
              >
                <div className="outerrrBorder">
                  <Grid item xs={12} className="modalInput addCollectionInputs">
                    <label htmlFor="Collections" className="required">
                      Collections
                    </label>
                    <input
                      type="text"
                      id="Collections"
                      className="form-control"
                      placeholder="Please Enter Name"
                      value={
                        collection?.collectionName + " (" +
                        collection?.collectionAddress?.slice(0, 5) +
                        "..." +
                        collection?.collectionAddress?.slice(38, 42) +
                        ")"
                      }
                      disabled
                    />
                    {console.log("collection?.collectionName", collection?.collectionName &&
                      collection?.collectionName !== undefined &&
                      collection?.collectionName !== "undefined" &&
                      collection?.collectionName !== "" ? collection?.collectionName : "")}
                  </Grid>
                  <Grid item xs={12} className="modalInput addCollectionInputs">
                    <label htmlFor="product" className="required">
                      Items
                    </label>
                    <input
                      type="text"
                      id="products"
                      className="form-control"
                      placeholder="Please Enter Name"
                      value={productName}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} className="modalInput addCollectionInputs">
                    <label htmlFor="product" className="required">
                      Token Type
                    </label>
                    <div className="d-flex flex-wrap justify-content-start">
                      <div className="radio">
                        <label>
                          <input type="radio" className="me-1" value="single" checked={selectedTokenType === "single"} onChange={handleOptionChange} />
                          Single Token
                        </label>
                      </div>
                      <div className="radio">
                        <label>
                          <input type="radio" className="me-1" value="range" checked={selectedTokenType === "range"} onChange={handleOptionChange} />
                          Range
                        </label>
                      </div>
                      <div className="radio">
                        <label>
                          <input type="radio" className="me-1" value="comma" checked={selectedTokenType === "comma"} onChange={handleOptionChange} />
                          Comma Seperated
                        </label>
                      </div>
                      <div className="radio">
                        <label>
                          <input type="radio" className="me-1" value="ALL" checked={selectedTokenType === "ALL"} onChange={handleOptionChange} />
                          ALL
                        </label>
                      </div>
                    </div>

                  </Grid>



                  <Grid item xs={12} className="modalInput addCollectionInputs">
                    <label htmlFor="InputName" className="required">
                      Token Id(s)
                    </label>
                    <input
                      type="text"
                      id="InputName"
                      className="form-control"
                      placeholder="Please Enter Token ID(s)"
                      value={selectedTokenType === "ALL" ? "ALL" : selectedTokenId}
                      disabled={selectedTokenType === "ALL"}
                      onChange={(e) => {
                        setSelectedTokenId(e.target.value);
                      }}
                    />
                  </Grid>

                </div>
                <Grid item xs={12} align="center">
                  <Button
                    variant="contained"
                    color="inherit"
                    id="button-submit"
                    className="textCapitalize "
                    onClick={postData}
                  >
                    Assign Item
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
}

export default withStyles(styles)(AssignProductForm);
