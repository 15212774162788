// import WalletConnectProvider from "@walletconnect/web3-provider"
import { updateCollectionStatus } from "apiServices";
import { deleteNftFromScript } from "apiServices";
import { importNftFromScript } from "apiServices";
import { providers } from "ethers";
import eventEmitter from "./../events/events";
import toast from "react-hot-toast";
import { checkIfOwnerExist } from "apiServices";
import { messages } from "config/messages";
import { adminLogout } from "apiServices";
import { async } from "q";
import { chainId, account, connectedrole } from "config/localStorageNames";

export const handleImport = async (req) => {
  let reqData = {
    request_type: "initialise",
    address: req.collectionAddress,
    chain_id: req.chainId?.toString(),
    name: req.collectionName,
    total_supply_field: "totalSupply",
  };
  await importNftFromScript(reqData);
};

export const handleDelete = async (req) => {
  try {
    // let reqDataForImport = { "request_type": "initialise", "address": req.collectionAddress, "chain_id": process.env.REACT_APP_CHAIN_ID, "name": req.collectionName, "total_supply_field": "totalSupply" }
    let reqDataForDel = {
      request_type: "delete",
      address: req.collectionAddress,
      chain_id: req?.chainId?.toString(),
      name: req.collectionName,
      total_supply_field: "totalSupply",
    };

    let delRes = await deleteNftFromScript(reqDataForDel);
    if (delRes === false) {
      return false;
    }
    await updateCollectionStatus({
      collectionAddress: req.collectionAddress,
      isImported: false,
      chainId: req?.chainId,
      isError: false,
    });
    return true;
    // console.log("del res", delRes)
    // await importNftFromScript(reqDataForImport)
  } catch (e) {
    console.log("err", e);
    return false;
  }
};

export const handleUpdateImportedCollection = async (req) => {
  let reqData = {
    request_type: "update",
    address: req.collectionAddress,
    chain_id: req?.chainId?.toString(),
    name: req.collectionName,
    total_supply_field: "totalSupply",
  };
  await importNftFromScript(reqData);
};

export const Logout = async (withoutMessage) => {
  const Redirect = localStorage.getItem(connectedrole) === "admin" ? "/admin" : "/";
  console.log("Redirect", Redirect === "admin");
  if (localStorage.getItem(connectedrole) === "admin") {
    await adminLogout({ refreshToken: localStorage.getItem("decrypt_redeem_refresh_token") });
  }
  localStorage.clear();
  if (!withoutMessage) toast.success(messages.logoutSuccess);
  // eventEmitter.emit("reload")
  // eventEmitter.emit("reloadNav")
  window.location.href = Redirect;
};

eventEmitter.removeAllListeners("logout");
eventEmitter.on("logout", Logout);
