

// @mui material components
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Footer from "../Footer";
import Table from "../Tables/Table";
import "./../../../App.css"
import "./product.css"
import VuiButton from "components/VuiButton";
import { useEffect, useState } from "react";
import eventEmitter from "./../../../events/events";
import { getCollections } from "apiServices";
import { ethers } from "ethers";
import { getRequests } from "apiServices";
import CircularUnderLoad from "components/common/spinner";
import { acceptRedeemRequest } from "apiServices";
import toast from 'react-hot-toast';
import { messages } from "config/messages";
import { account } from "config/localStorageNames";
import { checkIfOwned } from "helpers/getters";
import { pageLimit } from "helpers/constants";
import { redeemTableColumns } from "helpers/constants";
import { rejectRedeemRequest } from "apiServices";
import { getCollectionInfo } from "apiServices";
import WalletCheckModal from "components/common/walletCheckModal";
import { getCurrentAccount, getCurrentProvider } from "../authentication/connectWallet";
import { Logout } from "helpers/setters";
import { requestFilterFields } from "helpers/constants";
import { isValidAddress } from "helpers/getters";
import Pagination, { bootstrap5PaginationPreset } from 'react-responsive-pagination';
import ViewItem from "./ViewItem";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { handleCopyToClipboard } from "helpers/getters";
import { getNetworkNameByChain } from "config/rpcs";

function Products() {
  const [rows, setRows] = useState([])
  const [openForm, setOpenForm] = useState(false);
  const [acceptEvent, setAcceptEvent] = useState(0);
  const [requests, setRequests] = useState("none")
  const [reloadData, setReloadData] = useState(false)
  const [currAccount, setCurrAccount] = useState("")
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [wrongWallet, setWrongWallet] = useState(false)
  const [searchedText, setSearchedText] = useState("");
  const [searchField, setSearchField] = useState("ALL");
  const [count, setCount] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [searched, setSearched] = useState(false);
  const [toggleSearch, setToggleSearch] = useState(false);


  useEffect(() => {
    if ((localStorage.getItem(account) === "null" || localStorage.getItem(account) == null)) {
      window.location.href = "/admin"
    }
  }, [])

  useEffect(() => {
    if (localStorage.getItem(account))
      setCurrAccount(localStorage.getItem(account)?.toLowerCase())
  }, [localStorage.getItem(account)])


  const handleReloadData = () => {
    setSearched(false)
    setSearchedText("")
    setSearchField("ALL")
    setReloadData(!reloadData)
  }
  eventEmitter.removeAllListeners("reload")
  eventEmitter.on("reload", handleReloadData)


  useEffect(() => {
    const fetchRequests = async () => {
      setLoading(true)
      let request = await getRequests({
        page: currentPage,
        limit: pageLimit,
        owner: currAccount
        // walletAddress: currAccount
      })
      console.log("request", request)
      if (request && request?.results?.length > 0) {
        setRequests(request?.results)
        setCount(request?.count)
      }
      else {
        setRequests([])
        setCount(0)
      }

      setLoading(false)
    }
    if (currAccount !== "" && currAccount !== undefined && currAccount !== "undefined")
      fetchRequests()
  }, [currAccount])

  const sign = async () => {
    try {
      let provider = await getCurrentProvider()
      console.log("prrrooo", provider)
      // await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner()
      let rawMessage = messages.signMessage
      console.log(rawMessage)
      const signature = await signer.signMessage(rawMessage);
      const messageSender = ethers.utils.verifyMessage(rawMessage, signature);
      console.log("signature", signature, messageSender)
      return signature
    }
    catch (e) {
      console.log("err", e)
      if (e.code === "ACTION_REJECTED") {
        toast.error(messages.userDenied)
        setAcceptEvent(2)
        return false
      }
    }
  }


  const handleAccept = async (ProductData) => {

    try {
      let originallyConnectedAccount = await getCurrentAccount()
      if (currAccount?.toLowerCase() !== originallyConnectedAccount?.toLowerCase()) {
        setWrongWallet(true)
        return
      }
      setAcceptEvent(1)
      const col = await getCollectionInfo({
        "collectionAddress": ProductData.collectionAddress
      });
      let signature = await sign();
      if (signature === false) {
        return
      }
      const messageSender = ethers.utils.verifyMessage(messages.signMessage, signature);
      let isOwned = await checkIfOwned(ProductData.collectionAddress, messageSender, getNetworkNameByChain[ProductData?.collectionData?.length > 0 ? ProductData?.collectionData[0]?.chainId : 80002])

      if (!isOwned) {
        toast.error(messages.collectionNotOwned)
        setAcceptEvent(0)
        handleReloadData()
        return;
      }

      const data = {
        "collectionAddress": ProductData.collectionAddress,
        "tokenId": ProductData.tokenId,
        "signature": signature,
        "message": messages.signMessage,
        "walletAddress": currAccount,
        "productId": ProductData.productId,
        "chainId": col?.chainId
      }

      let res = await acceptRedeemRequest(data);
      toast.success(messages.requestAcceptSuccess)

      setAcceptEvent(2)
      handleReloadData()
      console.log("res is------>", res);
    }
    catch (e) {
      console.log("err", e)
      toast.error(messages.requestAcceptError)
      setAcceptEvent(0)
    }
  }

  const handleReject = async (ProductData) => {
    try {
      let originallyConnectedAccount = await getCurrentAccount()
      if (currAccount?.toLowerCase() !== originallyConnectedAccount?.toLowerCase()) {
        setWrongWallet(true)
        return
      }
      setAcceptEvent(1)
      const col = await getCollectionInfo({
        "collectionAddress": ProductData.collectionAddress
      });
      let signature = await sign();
      if (signature === false) {
        return
      }
      const messageSender = ethers.utils.verifyMessage(messages.signMessage, signature);
      let isOwned = await checkIfOwned(ProductData.collectionAddress, messageSender, getNetworkNameByChain[ProductData?.collectionData?.length > 0 ? ProductData?.collectionData[0]?.chainId : 80002])

      if (!isOwned) {
        toast.error(messages.collectionNotOwned)
        setAcceptEvent(0)
        handleReloadData()
        return;
      }

      const data = {
        "collectionAddress": ProductData.collectionAddress,
        "tokenId": ProductData.tokenId,
        "signature": signature,
        "message": messages.signMessage,
        "walletAddress": currAccount,
        "productId": ProductData.productId,
        "chainId": col?.chainId
      }

      let res = await rejectRedeemRequest(data);
      toast.success(messages.requestRejectSuccess)
      setReloadData(!reloadData)
      setAcceptEvent(2)
      console.log("res is------>", res);
    }
    catch (e) {
      console.log("err", e)
      toast.error(messages.requestRejecttError)
      setAcceptEvent(0)
    }
  }


  const handleConfirmClose = () => {
    console.log("handleConfirmClose")
    setWrongWallet(false)
  }

  const handleConfirm = async () => {
    setWrongWallet(false)
    await Logout()
  }

  const getSearchOptions = () => {
    if (searchField === "status") {
      return ["Pending", "Rejected", "Accepted"]
    }

    return []
  }

  const showSelect = () => {
    if (searchField == "status") {
      return true
    }
    return false
  }

  useEffect(() => {
    console.log("here11", searchField)
    if (searchField === "ALL" && searched) {
      console.log("here", searchField)
      setSearchedText("")
      setSearched(false)
    }
    if (currAccount !== null && currAccount !== undefined && currAccount !== "")
      handleSearch(searched);

  }, [currentPage, reloadData, searched, toggleSearch])


  const handleSearch = async (searched) => {
    setLoading(true)
    console.log("searchedd", searched)
    let req = {
      page: currentPage, limit: pageLimit, owner: currAccount
    }
    let _requests;
    if (searched && searchField === "collectionAddress" && searchedText !== "") {
      if (!isValidAddress(searchedText) && searchedText !== "") {
        toast.error(messages.invalidAddress)
        setLoading(false)
        return
      }
      req = {
        ...req,
        collectionAddress: searchedText
      }
    }
    else if (searched && searchField === "tokenId" && searchedText !== "") {
      req = {
        ...req,
        tokenId: searchedText
      }
    } else if (searched && searchField === "productId" && searchedText !== "") {
      req = {
        ...req,
        productId: searchedText
      }
    }
    else if (searched && searchField === "userName" && searchedText !== "") {
      req = {
        ...req,
        userName: searchedText
      }
    }
    else if (searched && searchField === "status" && searchedText !== "") {
      req = {
        ...req,
        status: searchedText
      }
    }

    _requests = await getRequests(req)
    if (_requests && _requests?.results?.length > 0) {
      setRequests(_requests?.results)
      setCount(_requests?.count)
    }
    else {
      setRequests([])
      setCount(0)
    }
    setLoading(false)
  }

  const handleClose = () => {
    setOpenForm(false)
  }

  useEffect(() => {
    let _rows = requests !== "none" && requests?.length > 0 ? requests.map((p, i) => {
      return {
        "Item Id": (<VuiTypography variant="button" color="white" fontWeight="medium" mb="4px">
          {p?.productId}
        </VuiTypography>),
        "User Name": (<VuiTypography variant="button" color="white" fontWeight="medium" mb="4px">
          {p?.userName ? p?.userName : "-"}
        </VuiTypography>),
        "Email": (<VuiTypography variant="button" color="white" fontWeight="medium" mb="4px" style={{ textTransform: "lowerCase" }}>
          {p?.email ? p?.email : "-"}
        </VuiTypography>),

        "Wallet Address": (
          <VuiTypography variant="button" color="white" fontWeight="medium" mb="4px">
            {p?.walletAddress?.slice(0, 4) + "..." + p?.walletAddress?.slice(38, 42)}
          </VuiTypography>
        ),
        "Token Id": (
          <VuiTypography variant="button" color="white" fontWeight="medium" mb="4px">
            {p?.tokenId}
          </VuiTypography>
        ),

        "Collection Address": (
          <VuiTypography component="a" href="#" variant="caption" color="text" fontWeight="medium" onClick={() => handleCopyToClipboard(p?.collectionAddress)}>
            {p?.collectionData[0]?.collectionName} ({p?.collectionAddress?.slice(0, 4) + "..." + p?.collectionAddress?.slice(38, 42)})
            <ContentCopyIcon />
          </VuiTypography>
        ),
        "Status": (
          <VuiTypography component="div" variant="caption" color="text" fontWeight="medium" >
            {p?.status === "Rejected" ? <span className="red">{p?.status}</span> : p?.status === "Accepted" ? <span className="green">{p?.status}</span> : <span>{p?.status}</span>}
          </VuiTypography>
        ),
        "More Info": (
          <VuiTypography component="a" href="#" variant="caption" color="text" fontWeight="medium" >
            <div className="viewMore" title="Click to View More Info" onClick={() => {
              setSelectedProduct(p)
              setOpenForm(true)
            }}>View</div>
          </VuiTypography>
        ),
        "Action": (
          <>{
            p?.status === "Rejected" || p?.status === "Accepted" ? <p style={{ 'minHeight': '40px', 'padding': '0.75rem 1.5rem', 'margin': '0' }} >
              -
            </p> :
              <>
                <VuiButton variant="gradient" color="info" size="medium" style={{ 'marginRight': '5px', 'padding': '0.75rem 1.5rem', 'border-radius': '0.75rem' }} title="Accept Request" onClick={() => {
                  handleAccept(p)
                }}>
                  Accept
                </VuiButton>
                <VuiButton variant="gradient" color="info" size="medium" title="Reject Request" style={{ 'marginRight': '5px', 'padding': '0.75rem 1.5rem', 'border-radius': '0.75rem' }} onClick={() => {
                  handleReject(p)
                }}>Reject
                </VuiButton>
              </>}
          </>
        ),
      }
    }) : []

    setRows(_rows)
  }, [requests, reloadData])



  return (
    <div className="collection__main">
      {acceptEvent === 1 ? (<div className="spinner_div"><div className="spinner">
        <div className="spinner-item"></div>
        <div className="spinner-item"></div>
        <div className="spinner-item"></div>
      </div></div>) : ""}
      {wrongWallet ? <WalletCheckModal header="Wrong Account Connected" handleClose={handleConfirmClose} handleConfirm={handleConfirm} /> : ""}
      {openForm ? (
        <ViewItem
          show={true}
          data={selectedProduct}
          handleClose={handleClose}
        />
      ) : (
        ""
      )}
      <VuiBox alignItems="center" mb="22px">
        <VuiTypography variant="lg" color="black">
          <div className="field_Bar">
            <form action="" onSubmit={(e) => {
              e.preventDefault();
              if (searchedText === "") {
                return;
              }
              setCurrentPage(1);
              setSearched(true);
              setToggleSearch(!toggleSearch);
            }}>
              <div className="fields_div">
                <select className="form-select" value={searchField}
                  onChange={(e) => {
                    setSearchedText("")
                    setSearched(false)
                    setSearchField(e.target.value);
                  }}
                  aria-label="Select Option">
                  {requestFilterFields && requestFilterFields?.map((field, i) => {
                    return <option key={i} value={field?.value} selected>{field?.key}</option>
                  })}


                </select>
                {
                  searchField !== "ALL" && searchField !== "" && showSelect() &&
                  <select className="form-select search_field" value={searchedText} onChange={(e) => {
                    setSearchedText(e.target.value);
                    setSearched(false)
                  }} aria-label="Select Option">
                    <option value="" selected>Select Option</option>
                    {getSearchOptions() && getSearchOptions()?.length > 0 ? getSearchOptions().map((o, i) => {
                      return <option key={i} value={o}>{o?.toUpperCase()}</option>
                    }) : ""}
                  </select>
                }
                {
                  searchField !== "ALL" && searchField !== "" && !showSelect() &&
                  <input className="form-control search_field"
                    id="Input1"
                    value={searchedText}
                    onChange={(e) => {
                      setSearchedText((e.target.value).replace(/\s/g, ''))
                      setSearched(false)
                    }} placeholder="Enter Search" />
                }
                <VuiButton className='logoutBtn' size="medium" disabled={searchedText === "" && searchField !== "ALL"} onClick={(e) => {
                  e.preventDefault();
                  setCurrentPage(1);
                  setSearched(true);
                  setToggleSearch(!toggleSearch);
                }}>Search</VuiButton>
              </div>

            </form>
          </div>
        </VuiTypography >
      </VuiBox >

      <VuiBox className="contentBox" py={3}>
        <VuiBox mb={3}>
          <Card className="contentTable">
            <VuiBox
              sx={{
                "& th": {
                  borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                    `${borderWidth[1]} solid ${grey[700]}`,
                },
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                      `${borderWidth[1]} solid ${grey[700]}`,
                  },
                },
              }}
            >
              <Table columns={redeemTableColumns} rows={rows} />
            </VuiBox>
            {loading ? <CircularUnderLoad /> : requests?.length === 0 ? <VuiTypography variant="lg" className="NoData">
              No Request Found
            </VuiTypography> : ""}
          </Card>
        </VuiBox>

      </VuiBox>
      {/* {console.log("count", count)} */}
      {!loading && Math.ceil(count / pageLimit) > 1 &&
        <Pagination
          {...bootstrap5PaginationPreset}
          current={currentPage}
          total={Math.ceil(count / pageLimit)}
          onPageChange={(p) => setCurrentPage(p)}
        />}
      <Footer /></div>)




}

export default Products;
