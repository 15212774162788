// @mui material components
import Card from "@mui/material/Card";
import validator from "validator";
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Footer from "../Footer";
import Table from "../Tables/Table";
import "./../../../App.css";
import VuiButton from "components/VuiButton";
import { useEffect, useState } from "react";
import SendMail from "./sendMail.js";
import eventEmitter from "../../../events/events";
import { getCollections } from "apiServices";
import CircularUnderLoad from "components/common/spinner";
import { mailTableColumns } from "helpers/constants";
import { messages } from "config/messages";
import { toast } from "react-hot-toast";
import Pagination, { bootstrap5PaginationPreset } from "react-responsive-pagination";
import { pageLimit } from "helpers/constants";
import { CSVLink } from "react-csv";
import EmailIcon from "@mui/icons-material/Email";
import { connectedrole } from "config/localStorageNames";


function Mailer(props) {
  const [openForm, setOpenForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [collections, setCollections] = useState([]);
  const [reloadCollections, setReloadCollections] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toggleNotesBtn, setToggleNotesBtn] = useState("disable-notes");
  const [editForm, setEditForm] = useState(false);
  const [singleMail, setSingleMail] = useState();
  const [isBulk, setIsBulk] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState();
  const [selectedMail, setSelectedMail] = useState([]);
  const [selectedMailList, setSelectedMailList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [counts, setCounts] = useState();
  const [notAllSelect, setNotAllSelect] = useState("")

  const nextPage = (pageNumber) => {
    // if (currentPage !== Math.ceil(counts / pageLimit)) {
    setCurrentPage(pageNumber);
    const ele = document.getElementById("selectAll");
    ele.checked = false;
    setSelectedMail([]);
    // }
  };

  const handleReloadCollection = () => {
    setReloadCollections(!reloadCollections);
    setOpenForm(false);
    setEditForm(false);
  };

  const handleButtonToggle = () => {
    if (toggleNotesBtn === "active-notes") {
      setToggleNotesBtn("disable-notes");
    } else {
      setToggleNotesBtn("active-notes");
    }
  };

  eventEmitter.removeAllListeners("reload");
  eventEmitter.on("reload", handleReloadCollection);

  const handleClose = () => {
    setOpenForm(false);
  };

  useEffect(() => {
    if (localStorage.getItem(connectedrole) !== "admin") {
      window.location.href = "/admin";
    }
  }, [reloadCollections]);

  useEffect(() => {
    const fetchCollections = async () => {
      setLoading(true);
      let coll = await getCollections({ page: currentPage, limit: pageLimit, isAdmin: true });
      let count = coll?.count;
      setCounts(count);
      if (coll && coll?.results?.length > 0) {
        setCollections(coll?.results[0]);
      }

      setLoading(false);
    };
    fetchCollections();
  }, [reloadCollections, currentPage]);

  const handleMailSelect = (e, c) => {
    let res = selectedMail;
    if (!validator.isEmail(c?.email)) {
      return;
    }
    if (e.target.checked) {
      res.push(c.email);
      // let data = res?.filter(email => email === c.email)
      // if (data.length == 0)
      //   res.push(c.email)
     
      if (selectedMail?.length === collections?.length) {
        document.getElementById("selectAll").checked = true;
        setNotAllSelect("")
      }
    } else {
     
      let EmailIndex = res.indexOf(c.email);
      if (EmailIndex > -1) {
        res.splice(EmailIndex, 1);
      }
      if (selectedMail?.length === 0 || selectedMail == undefined ) {
        document.getElementById("selectAll").checked = false;
        setNotAllSelect("")
      }
      console.log("selectedMail?.length !== 0 && selectedMail?.length !== undefined && selectedMail?.length >= 2", selectedMail?.length !== 0 , selectedMail?.length !== undefined , selectedMail?.length >= 2)
      if(selectedMail?.length !== 0 && selectedMail?.length !== undefined && selectedMail?.length >= 2){
        document.getElementById("selectAll").checked = true;
        setNotAllSelect("notAllSelect")
      }
      if( selectedMail?.length !== 0 && selectedMail?.length !== undefined && selectedMail?.length <= 1){
        document.getElementById("selectAll").checked = false;
        setNotAllSelect("")
      }
     
    }
    setSelectedMail(res);
  };

  useEffect(() => {
    let _rows =
      collections?.length > 0 && collections !== "none"
        ? collections?.map((c, i) => {
            return {
              Select: (
                <input
                  type="checkbox"
                  className="checkbox"
                  id={"_checkbox" + i}
                  disabled={!c?.email ? true : false}
                  onClick={(e) => {
                    handleMailSelect(e, c);
                  }}
                />
              ),
              Owners: (
                <VuiTypography
                  variant="button"
                  color="white"
                  fontWeight="medium"
                  mb="4px"
                  className=""
                >
                  {c?.owner}
                </VuiTypography>
              ),
              "E-Mail": (
                <VuiTypography
                  variant="button"
                  color="white"
                  fontWeight="medium"
                  mb="4px"
                  style={{ textTransform: "lowercase" }}
                >
                  {c?.email ? c?.email : "-"}
                </VuiTypography>
              ),
              Operation: (
                <VuiTypography
                  className="btncont"
                  variant="button"
                  color="white"
                  fontWeight="medium"
                  mb="4px"
                >
                  <VuiButton
                    className="edit-btnn"
                    onClick={() => {
                      if (!validator.isEmail(c?.email)) {
                        toast.error(messages.invalidEmail);
                        return;
                      }
                      setSingleMail([c?.email]);
                      setIsBulk(false);
                      setEditForm(true);
                    }}
                    disabled={!c?.email ? true : false}
                  >
                    Send Mail &nbsp;
                    <EmailIcon className="mailSvg" color="white" />
                  </VuiButton>
                </VuiTypography>
              ),
            };
          })
        : [];

    setRows(_rows);
  }, [collections, reloadCollections]);

  const handleEditClose = () => {
    setEditForm(false);
  };

  const handleBulkMailRequest = () => {
    setIsBulk(true);
    if (selectedMail?.length === 0 || selectedMail == undefined) {
      toast.error(messages.noMailSelected);
      return;
    }
   let bulkEmails = [];
   for(let i = 0;i <selectedMail?.length; i++){
    if(!bulkEmails?.includes(selectedMail[i])){
      bulkEmails.push(selectedMail[i])
    }
   }
  
    setSelectedMailList(bulkEmails);
    setEditForm(true);
  };

  const handleSelectAll = (e) => {
    setNotAllSelect("")
    if (e.target.checked) {
      let res = selectedMail;
      for (let i = 0; i < collections?.length; i++) {
     
        const el = document.getElementById("_checkbox" + i);

        if (!el.disabled && !el.checked) {
          el.checked = e.target.checked;
          res.push(collections[i]?.email);
        }
      }
      console.log("response", res)
      setSelectedMail(res);
    } else {
      for (let i = 0; i < collections?.length; i++) {
        const el = document.getElementById("_checkbox" + i);
        if (!el.disabled) {
          el.checked = e.target.checked;
        }
      }
      setSelectedMail([]);
    }
    
  };

  return (
    <div className="collection__main">
      {editForm ? (
        <SendMail
          show={true}
          handleClose={handleEditClose}
          emails={isBulk ? selectedMailList : singleMail}
        />
      ) : (
        ""
      )}

      {/* <DashboardNavbar /> */}
      <VuiBox alignItems="center" mb="22px">
        <VuiTypography variant="lg" color="black">
          <div className="d-flex justify-content-between align-items-center">
            <VuiTypography className="inputCont">
              <input type="checkbox" id="selectAll" onClick={handleSelectAll} className={notAllSelect} />
              <label for="selectAll">Select All</label>
            </VuiTypography>
            <VuiTypography
              className="sendToManyCont"
              variant="button"
              color="white"
              fontWeight="medium"
              mb="4px"
            >
              <VuiButton className="sendToMany edit-btnn" onClick={handleBulkMailRequest}>
                Send Mail &nbsp;
                <EmailIcon className="mailSvg" color="white" />
              </VuiButton>
            </VuiTypography>
          </div>
        </VuiTypography>
      </VuiBox>
      <VuiBox className="contentBox" py={3}>
        <VuiBox mb={3}>
          <Card className="contentTable">
            <VuiBox
              sx={{
                "& th": {
                  borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                    `${borderWidth[1]} solid ${grey[700]}`,
                },
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                      `${borderWidth[1]} solid ${grey[700]}`,
                  },
                },
              }}
            >
              <Table columns={mailTableColumns} rows={rows} />
            </VuiBox>
            {loading ? (
              <CircularUnderLoad />
            ) : collections?.length === 0 ? (
              <VuiTypography variant="lg" className="NoData">
                No Record Found
              </VuiTypography>
            ) : (
              ""
            )}
          </Card>
          <CSVLink
            data={collections}
            filename={"EmailList.csv"}
            headers={[
              {
                label: "Owners",
                key: "owner",
              },
              {
                label: "Email Ids",
                key: "email",
              },
            ]}
          >
            <VuiButton variant="gradient" color="info" size="small">
              Export
            </VuiButton>
          </CSVLink>
          {!loading && Math.ceil(counts / pageLimit) > 1 && (
            <Pagination
              {...bootstrap5PaginationPreset}
              current={currentPage}
              total={Math.ceil(counts / pageLimit)}
              onPageChange={nextPage}
            />
          )}
        </VuiBox>
      </VuiBox>
      <div className={`sidebarNotesBtn ${toggleNotesBtn === "active-notes" ? "active" : ""}`}>
        <div className="toggleNotesBtn" onClick={handleButtonToggle}>
          Note
        </div>
        <div className={toggleNotesBtn}>
          Collection which you are adding should have Metadata URI set for each Token.
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Mailer;
