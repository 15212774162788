import { useState, useEffect, useMemo } from "react";

// react-router components
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Vision UI Dashboard React example components
import Sidenav from "./components/pages/Sidenav";

// Vision UI Dashboard React themes
import theme from "assets/theme";

// Vision UI Dashboard React routes
import { userRoutes } from "routes";
import { adminRoutes } from "routes";
// Vision UI Dashboard React contexts
import { useVisionUIController, setMiniSidenav } from "context";
import Dashboard from "components/pages/dashboard/index";
import SignIn from "components/pages/authentication/sign-in/index";
import Collections from "components/pages/collections";
import RedeemRequests from "components/pages/redeemRequests";
import Products from "components/pages/products";
import AssignProduct from "components/pages/productAssignments";
import PageNotFound from "components/pages/PageNotFound";
import DashboardLayout from "components/pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/pages/Navbars/DashboardNavbar";
import { Toaster } from "react-hot-toast";
import { checkIfOwnerExist, checkIfOwnerExistOwner } from "./apiServices";
import eventEmitter from "./events/events";
// import { Logout } from "components/pages/authentication/connectWallet";
import { checkIfIsUserOrAdmin } from "./helpers/getters";
import { defaultRoutes } from "routes";
import Home from "components/pages/Home/index";
import AdminLandingPage from "components/pages/Home/AdminLandingPage";
import { checkIfTokenExpired } from "apiServices";
import Items from "components/pages/items";
// import Wallet from "components/pages/authentication/connectWallet";

import Mailer from "components/pages/mailer/index";
// import WalletCheckModal from "components/common/walletCheckModal";
import { chainId, account, connectedrole } from "./config/localStorageNames";
import { Logout } from "helpers/setters";
import Newsletter from "components/pages/Newsletter";
// import Subscribers from "components/pages/Subscribers";
import OwnedCollections from "components/pages/ownedCollections";
import FaqList from "components/pages/faq/faqList";
import AddFaq from "components/pages/faq/addFaq";
import UpdateFaq from "components/pages/faq/updateFaq";

export default function App() {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const [currAccount, setCurrAccount] = useState();
  const [role, setRole] = useState();
  const [reloadContent, setReloadContent] = useState(false);
  const location = useLocation();

  let history = useHistory();
  useEffect(() => {
    console.log("useEffect called");
    let currRole = checkIfIsUserOrAdmin();
    setRole(currRole);
    if (currRole !== "none") {
      console.log("location.hash", location.pathname);
      if (location.pathname === "/admin") {
        window.location.href = "/";
      }
    }
  }, [reloadContent, localStorage.getItem("decrypt_redeem_role")]);

  eventEmitter.removeAllListeners("reload");
  eventEmitter.on("reload", () => {
    console.log("hhhh");
    setReloadContent(!reloadContent);
  });

  useEffect(
    () => {
      const fetch = async () => {
        setCurrAccount(localStorage.getItem(account)?.toLowerCase());
        console.log("account", localStorage.getItem(account));
        if (localStorage.getItem(account) !== "null" && localStorage.getItem(account) !== null) {
          let res = await checkIfOwnerExist({
            walletAddress: localStorage.getItem(account),
          });
          console.log("res", res);
          if (res === false) {
            await Logout(false);
          }
        }

        eventEmitter.emit("reload");
      };
      fetch();
    },
    [localStorage.getItem(account)],
    reloadContent
  );

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      {/* <Wallet /> */}
      {/* <WalletCheckModal /> */}
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          {console.log("rolee", checkIfIsUserOrAdmin())}
          {role === "none" ? (
            ""
          ) : (
            <Sidenav
              color={sidenavColor}
              brand=""
              // brandName="VISION UI FREE"
              routes={
                checkIfIsUserOrAdmin() === "admin"
                  ? adminRoutes
                  : checkIfIsUserOrAdmin() === "owner"
                  ? userRoutes
                  : defaultRoutes
              }
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          )}
        </>
      )}

      <DashboardLayout className="dashboard-1">
        {checkIfIsUserOrAdmin() !== "none" && <DashboardNavbar />}

        <Switch>
          {checkIfIsUserOrAdmin() === "none" && <Route exact path="/" component={Home} />}
          {checkIfIsUserOrAdmin() !== "none" && <Route exact path="/" component={Dashboard} />}
          {checkIfIsUserOrAdmin() !== "owner" && <Route exact path="/admin" component={SignIn} />}
          {checkIfIsUserOrAdmin() !== "none" && checkIfIsUserOrAdmin() === "admin" && (
            <Route exact path="/collections" component={Collections} />
          )}
          {/* {checkIfIsUserOrAdmin() !== "none" ? (
            <Route exact path="/products" component={Products} />
          ) : (
            ""
          )} */}
          {checkIfIsUserOrAdmin() !== "none" && checkIfIsUserOrAdmin() === "owner" && (
            <Route exact path="/assignProducts" component={AssignProduct} />
          )}
          {checkIfIsUserOrAdmin() !== "none" && checkIfIsUserOrAdmin() === "owner" && (
            <Route exact path="/ownedCollections" component={OwnedCollections} />
          )}
          {checkIfIsUserOrAdmin() !== "none" && checkIfIsUserOrAdmin() === "owner" && (
            <Route exact path="/items" component={Products} />
          )}
          {checkIfIsUserOrAdmin() !== "none" && checkIfIsUserOrAdmin() === "admin" && (
            <Route exact path="/mail" component={Mailer} />
          )}
          {checkIfIsUserOrAdmin() !== "none" && checkIfIsUserOrAdmin() === "admin" && (
            <Route exact path="/addFaq" component={AddFaq} />
          )}
          {checkIfIsUserOrAdmin() !== "none" && checkIfIsUserOrAdmin() === "admin" && (
            <Route exact path="/faqList" component={FaqList} />
          )}{" "}
          {checkIfIsUserOrAdmin() !== "none" && checkIfIsUserOrAdmin() === "admin" && (
            <Route exact path="/updateFaq/:id" component={UpdateFaq} />
          )}
          {/* {checkIfIsUserOrAdmin() !== "none" && checkIfIsUserOrAdmin() === "admin" && <Route exact path="/subscribers" component={Subscribers} />} */}
          {/* {checkIfIsUserOrAdmin() !== "none" && checkIfIsUserOrAdmin() === "admin" ? <Route exact path="/newsletter" component={Newsletter} /> : ""} */}
          {checkIfIsUserOrAdmin() !== "none" && checkIfIsUserOrAdmin() === "owner" && (
            <Route exact path="/redeemRequests" component={RedeemRequests} />
          )}
          <Route exact path="*" component={PageNotFound} />
        </Switch>
      </DashboardLayout>
      <div>
        <Toaster
          toastOptions={{
            success: {
              style: {
                background: "white",
                color: "green",
                fontFamily: "fantasy",
              },
              icon: "👏",
            },
            error: {
              style: {
                background: "white",
                color: "red",
                fontFamily: "fantasy",
              },
            },
          }}
        />
      </div>
    </ThemeProvider>
  );
}
