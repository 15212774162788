import React from 'react'

function Loader(props) {
  return (
    <div className="spinner_div"><div className="spinner">
      <div className="spinner-item"></div>
      <div className="spinner-item"></div>
      <div className="spinner-item"></div>
    </div>
      <p>{props?.message}</p></div>
  )
}

export default Loader