

import { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "../components/CoverLayout";
// Images
import bgSignIn from "assets/images/ban.png";
import { adminLogin } from "apiServices";
import toast from "react-hot-toast";
import { messages } from "config/messages";
import Loader from "components/common/Loader";
import { padding } from "@mui/system";


function SignIn() {
  const [userName, setUserName] = useState("")
  const [password, setPassword] = useState("")
  const [loader, setLoader] = useState(false)

  // useEffect(() => {
  //   if (localStorage.getItem("pie_role") === "admin"
  //     || (localStorage.getItem("pie_account") !== "null" || localStorage.getItem("pie_account") != null)) {
  //     window.location.href = "/"
  //   }
  // }, [])

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoader(true)
      if (userName === "" || password === "") {
        alert("Username and password are required")
        setLoader(false)
        return
      }
      let login = await adminLogin({
        "username": userName,
        "password": password
      })
      if (login) {
        console.log("login res", login)
        toast.success(messages.loginSuccess)
        setLoader(false)
        setTimeout(() => {
          window.location.href = "/"
        }, 500)

      }
      else {
        toast.error(messages.loginError)
      }
      setLoader(false)
    }
    catch (e) {
      console.log("error", e)
    }
  }



  return (
    <>
      {loader && <Loader />}
      <CoverLayout
        title="Welcome To Redemption Admin"
        color="white"
        // description="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur."
        premotto="Sign In"
        motto=""
        // image={bgSignIn}
        top='60px'
        sx={{ color: '#fff' }}
      >

        <VuiBox component="form" role="form" onSubmit={handleLogin}>
          <VuiBox mb={2}>
            <GradientBorder
              minWidth="100%"
              padding="0px"
              className="adminLoginInputDiv"
            >
              <VuiInput type="text" placeholder="Enter your username"
                className="signIn-Input"
                value={userName}
                sx={{
                  border: 'none',
                  borderBottom: '2px solid #fff',
                  borderRadius: '50px',
                  background: 'linear-gradient(89.75deg, #116796 0.12%, #3BB1EF 105.48%) !important',
                  color: '#fff !important',
                }}
                onChange={(e) => {
                  setUserName(e.target.value)
                }} />
            </GradientBorder>
          </VuiBox>
          <VuiBox mb={2}>
            <GradientBorder
              minWidth="100%"
              padding="0px"
              className="adminLoginInputDiv"
            >
              <VuiInput
                className="signIn-Input"
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
                sx={{
                  border: 'none',
                  borderBottom: '2px solid #fff',
                  borderRadius: '50px',
                  background: 'linear-gradient(89.75deg, #116796 0.12%, #3BB1EF 105.48%) !important',
                  color: '#fff !important',
                }}
              />
            </GradientBorder>
          </VuiBox>

          <VuiBox mt={4} mb={1} sx={{
            display: 'flex',
            justifyContent: 'center'
          }}>
            <VuiButton color="info" fullWidth type="submit"
              sx={{
                border: 'none',
                borderRadius: '5px',
                background: 'linear-gradient(89.75deg, #116796 0.12%, #3BB1EF 105.48%) !important',
                color: '#fff',
                width: 'fit-content',
                margin: 'auto'
              }}
              onClick={(e) => {
                handleLogin(e)
              }}>
              SIGN IN
            </VuiButton>
          </VuiBox>


        </VuiBox>
      </CoverLayout>
    </>
  );
}

export default SignIn;
