import { networks } from "config/rpcs";
import { ethers } from "ethers";
import eventEmitter from "./events/events";
import toast from "react-hot-toast";
import { role } from "config/localStorageNames";
import { connectedrole } from "config/localStorageNames";

export const exportInstance = async (SCAddress, ABI, networkName) => {
  console.log("networks", networkName);
  let provider = new ethers.providers.JsonRpcProvider(networks[networkName]?.rpcs);
  let signer = provider.getSigner();
  let a = new ethers.Contract(SCAddress, ABI, provider);
  console.log("contract", a);
  if (a) {
    return a;
  } else {
    return {};
  }
};

export const checkIfTokenExpired = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("decrypt_redeem_auth"),
    },
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/auth/checkIfAuthorized",
      requestOptions
    );

    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    console.log("responseData :", datas);
    if (datas?.statusCode === 401) {
      let resp = await refreshAuthToken({
        refreshToken: localStorage.getItem("decrypt_redeem_refresh_token"),
      });
      return resp;
    }
  } catch (err) {
    console.log("err", e);
    return false;
  }
};

export const checkIfCollectionExists = async (data) => {
  await checkIfTokenExpired();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("decrypt_redeem_auth"),
    },
    body: JSON.stringify(data),
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/collection/checkIfCollectionExists",
      requestOptions
    );

    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    console.log("responseData :", datas);

    return datas?.data;
  } catch (err) {
    return false;
  }
};



export const addCollection = async (data) => {
  await checkIfTokenExpired();
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("decrypt_redeem_auth"),
    },
    body: data,
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/collection/addCollection",
      requestOptions
    );

    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    console.log("responseData :", datas.data);
    if (datas?.statusCode === 409) {
      return 409;
    }
    if (datas?.statusCode !== 200) {
      return false;
    }
    return datas.data;
  } catch (err) {
    return false;
  }
};

export const getCollections = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("decrypt_redeem_auth"),
    },
    body: JSON.stringify(data),
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/collection/viewCollections",
      requestOptions
    );
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    return datas.data;
  } catch (err) {
    return err;
  }
};


export const getCollectionsOwner = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_AUTH_KEY,
    },
    body: JSON.stringify(data),
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/collection/viewCollectionsOwner",
      requestOptions
    );
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    return datas.data;
  } catch (err) {
    return err;
  }
};



export const addProduct = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: process.env.REACT_APP_AUTH_KEY,
    },
    body: data,
  };
  try {
    let response;
    try {
      response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/product/addProduct",
        requestOptions
      );
    } catch (e) {
      return false;
    }

    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    console.log("responseData :", datas.data);
    if (datas?.message === "Product limit reached error") {
      return "Product limit reached";
    }
    if (datas?.statusCode !== 200) {
      return false;
    }
    if (!datas.data || !datas?.data?.productName) {
      return false;
    }
    return datas.data;
  } catch (err) {
    return false;
  }
};

export const getProducts = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("decrypt_redeem_auth"),
    },
    body: JSON.stringify(data),
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/product/getProduct",
      requestOptions
    );
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    return datas.data;
  } catch (err) {
    return err;
  }
};

export const assignProducts = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_AUTH_KEY,
    },
    body: JSON.stringify(data),
  };
  try {
    let response;
    try {
      response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/product/assignProduct",
        requestOptions
      );
    } catch (err) {
      return false;
    }
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    console.log("responseData :", datas.data);
    return datas.data;
  } catch (err) {
    return false;
  }
};

// export const updateProducts = async (data) => {
//   const requestOptions = {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: process.env.REACT_APP_AUTH_KEY,
//     },
//     body: JSON.stringify(data),
//   };
//   try {
//     let response;
//     try {
//       response = await fetch(
//         process.env.REACT_APP_API_BASE_URL + "/product/updateProduct",
//         requestOptions
//       );
//     } catch (err) {
//       return false;
//     }
//     const isJson = response.headers.get("content-type")?.includes("application/json");
//     const datas = isJson && (await response.json());
//     return datas.data;
//   } catch (err) {
//     return false;
//   }
// };

export const deleteProducts = async (data) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_AUTH_KEY,
    },
    body: JSON.stringify(data),
  };
  try {
    let response;
    try {
      response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/product/deleteProduct",
        requestOptions
      );
    } catch (err) {
      return false;
    }
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    if(!Object.keys(datas.data).length){
      return datas
    }
    return datas.data;
  } catch (err) {
    return false;
  }
};

export const adminLogin = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  try {
    let response;
    try {
      response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/auth/adminLogin",
        requestOptions
      );
    } catch (err) {
      console.log("err", err);
      return false;
    }
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    localStorage.setItem("decrypt_redeem_auth", datas?.data?.token);
    localStorage.setItem("decrypt_redeem_refresh_token", datas?.data?.refreshToken);
    localStorage.setItem(connectedrole, "admin");
    console.log("datas", datas);
    if (datas.message === "Invalid credentials") return false;
    return datas.data;
  } catch (err) {
    return false;
  }
};

export const adminLogout = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("decrypt_redeem_auth"),
    },
    body: JSON.stringify(data),
  };
  try {
    let response;
    try {
      response = await fetch(process.env.REACT_APP_API_BASE_URL + "/auth/logout", requestOptions);
    } catch (err) {
      console.log("err", err);
      return false;
    }
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());

    return datas.data;
  } catch (err) {
    return false;
  }
};

export const refreshAuthToken = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  try {
    let response;
    try {
      response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/auth/refreshToken",
        requestOptions
      );
    } catch (err) {
      console.log("err", err);
      eventEmitter.emit("logout");
      return false;
    }
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    if (datas?.statusCode === 401) {
      toast.error("Auth Error, Please try logging in again");
      eventEmitter.emit("logout");
      return false;
    }
    console.log("datass", datas);
    localStorage.setItem("decrypt_redeem_auth", datas?.token);
    return datas;
  } catch (err) {
    return false;
  }
};

export const importNftFromScript = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_METADATA_AUTH_TOKEN,
    },
    body: JSON.stringify(data),
  };

  try {
    let response;
    try {
      response = await fetch(process.env.REACT_APP_IMP_LINK + "/impCollection/", requestOptions);
    } catch (err) {
      return false;
    }
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());

    // const onBoard = await fetch(`${process.env.REACT_APP_IMP_LINK}/api/v1/onboardRedemption?chain_id=${process.env.REACT_APP_CHAIN_ID}&contract_address=${data.address?.toLowerCase()}/`,
    //     {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //     })

    // console.log("onboard complete", onBoard)
    return datas;
  } catch (err) {
    NotificationManager.error("Error While Importing the Collection", "", NOTIFICATION_DELAY);
    window.location.href = "/";
    return err;
  }
};

export const deleteNftFromScript = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_METADATA_AUTH_TOKEN,
    },
    body: JSON.stringify(data),
  };

  try {
    let response;
    try {
      response = await fetch(process.env.REACT_APP_IMP_LINK + "/delCollection/", requestOptions);
    } catch (err) {
      return false;
    }
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());

    return datas.data;
  } catch (err) {
    NotificationManager.error("Error While Importing the Collection", "", NOTIFICATION_DELAY);
    window.location.href = "/";
    return err;
  }
};

export const checkImportStatus = async (data) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_METADATA_AUTH_TOKEN,
    },
  };

  try {
    let response = await fetch(
      process.env.REACT_APP_IMP_LINK +
        `/api/v1/collections?ChainId=${data?.chainId}&ContractAddress=${data?.collectionAddress}`,
      requestOptions

    );
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());

    return datas;
  } catch (err) {
    return err;
  }
};

export const getRequests = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_AUTH_KEY,
    },
    body: JSON.stringify(data),
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/product/viewRequest",
      requestOptions
    );
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    console.log("getRequests datas", datas);
    return datas.data;
  } catch (err) {
    return err;
  }
};

export const acceptRedeemRequest = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_AUTH_KEY,
    },
    body: JSON.stringify(data),
  };
  try {
    let response;
    try {
      response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/product/acceptRedeemRequest",
        requestOptions
      );
    } catch (err) {
      return false;
    }
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    return datas.data;
  } catch (err) {
    console.log("err", err);
    return [];
  }
};

export const rejectRedeemRequest = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_AUTH_KEY,
    },
    body: JSON.stringify(data),
  };
  try {
    let response;
    try {
      response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/product/rejectRedeemRequest",
        requestOptions
      );
    } catch (err) {
      return false;
    }
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    return datas.data;
  } catch (err) {
    console.log("err", err);
    return [];
  }
};

export const checkIfOwnerExist = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_AUTH_KEY,
    },
    body: JSON.stringify(data),
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/collection/checkIfOwnerExist",
      requestOptions
    );
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    return datas.data;
  } catch (err) {
    return err;
  }
};



export const sendMail = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Authorization": localStorage.getItem("decrypt_redeem_auth"),
    },
    body: JSON.stringify(data),
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/mail/sendMail",
      requestOptions
    );
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    if (datas.statusCode === 200) {
      return 200;
    } else if (datas.statusCode === 500) {
      return 500;
    } else {
      return false;
    }
  } catch (err) {
    console.log("Error", err);
    return false;
  }
};

export const deleteAssignment = async (data) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_METADATA_AUTH_TOKEN,
    },
  };

  try {
    let response = await fetch(
      process.env.REACT_APP_IMP_LINK +
        `/api/v1/unassignProduct?chain_id=${
          data?.chainId
        }&contract_address=${data.collectionAddress?.toLowerCase()}&product_id=${
          data.productId
        }&token_id=${data.tokenId}`,
      requestOptions
    );
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());

    return datas;
  } catch (err) {
    return err;
  }
};

export const updateCollectionStatus = async (data) => {
  await checkIfTokenExpired();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("decrypt_redeem_auth"),
    },
    body: JSON.stringify(data),
  };
  try {
    let response;
    try {
      response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/collection/updateCollectionStatus",
        requestOptions
      );
      try {
        const onBoard = await fetch(
          `${process.env.REACT_APP_IMP_LINK}/api/v1/onboardRedemption?chain_id=${
            data?.chainId
          }&contract_address=${data?.collectionAddress?.toLowerCase()}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: process.env.REACT_APP_METADATA_AUTH_TOKEN,
            },
          }
        );

        console.log("onboard", onBoard);
      } catch (e) {
        console.log("err", e);
        return false;
      }
    } catch (err) {
      console.log("err", err);
      return false;
    }
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());

    return datas.data;
  } catch (err) {
    return false;
  }
};

export const updateCollectionDetails = async (data) => {
  await checkIfTokenExpired();
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("decrypt_redeem_auth"),
    },
    body: data,
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/collection/updateCollectionDetails",
      requestOptions
    );
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    console.log("updateCollectionDetails responseData :", datas.data);
    if (datas?.statusCode === 409) {
      return 409;
    }
    return datas.data;
  } catch (err) {
    return err;
  }
};

export const blockUnblockCollection = async (data) => {
  await checkIfTokenExpired();
  console.log("blockUnblockCollection------>", data);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("decrypt_redeem_auth"),
    },
    body: JSON.stringify(data),
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/collection/blockUnblockCollection",
      requestOptions
    );
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    console.log("blockUnblockCollection responseData :", datas.data);
    return datas.data;
  } catch (err) {
    return false;
  }
};

export const hotCollectionUpdate = async (data) => {
  await checkIfTokenExpired();
  console.log("blockUnblockCollection------>", data);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("decrypt_redeem_auth"),
    },
    body: JSON.stringify(data),
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/collection/makeHotCollection",
      requestOptions
    );
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    console.log("blockUnblockCollection responseData :", datas.data);
    return datas.data;
  } catch (err) {
    return false;
  }
};

export const getFilteredCollections = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("decrypt_redeem_auth"),
    },
    body: JSON.stringify(data),
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/collection/getFilteredCollection",
      requestOptions
    );

    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    return datas.data;
  } catch (err) {
    return err;
  }
};


export const getFilteredCollectionsOwner = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_AUTH_KEY,
    },
    body: JSON.stringify(data),
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/collection/getFilteredCollectionUserPanel",
      requestOptions
    );

    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    return datas.data;
  } catch (err) {
    return err;
  }
};

export const getDashboardStats = async (data) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("decrypt_redeem_auth"),
    },
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/utils/getDashboardStats",
      requestOptions
    );
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    return datas.data;
  } catch (err) {
    return err;
  }
};

export const getCollectionInfo = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("decrypt_redeem_auth"),
    },
    body: JSON.stringify(data),
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/collection/getCollectionInfo",
      requestOptions
    );
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    return datas.data;
  } catch (err) {
    return err;
  }
};

export const viewAllProducts = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_AUTH_KEY,
    },
    body: JSON.stringify(data),
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/product/viewAllProducts",
      requestOptions
    );

    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    return datas.data;
  } catch (err) {
    return err;
  }
};



export const getAssignedTokens = async (data) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_METADATA_AUTH_TOKEN,
    },
  };

  try {
    let response = await fetch(
      process.env.REACT_APP_IMP_LINK +
        `/api/v1/getAssignedProductStatus?chain_id=${
          data?.chain_id
        }&contract_address=${data?.contract_address?.toLowerCase()}&product_id=${data?.product_id}`,
      requestOptions
    );
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());

    return datas;
  } catch (err) {
    return err;
  }
};

export const getOwnerStats = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization:  process.env.REACT_APP_AUTH_KEY
    },
    body: JSON.stringify(data),
  };
  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/utils/getOwnerStats",
      requestOptions
    );

    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    return datas.data;
  } catch (error) {
    return error;
  }
};


// export const getSubscribers = async (data) => {
//     const requestOptions = {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json",
//         },
//         body: JSON.stringify(data),
//     };
//     try {
//         let response = await fetch(
//             process.env.REACT_APP_API_BASE_URL + "/mail/getSubscribers",
//             requestOptions
//         );
//         const isJson = response.headers.get("content-type")?.includes("application/json");
//         const datas = isJson && (await response.json());
//         console.log("getRequests datas", datas)
//         return datas.data;
//     } catch (err) {
//         return err;
//     }
// };

export const updateProduct = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: process.env.REACT_APP_AUTH_KEY,
    },
    body: data,
  };
  try {
    let response;
    try {
      response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/product/updateProduct",
        requestOptions
      );
    } catch (e) {
      return false;
    }

    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    console.log("datas=====>", datas);
    if (datas?.statusCode !== 200) {
      return false;
    }

    return datas.data;
  } catch (err) {
    return false;
  }
};
export const addFaq = async (data) => {
  await checkIfTokenExpired();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("decrypt_redeem_auth"),
    },
    body: JSON.stringify(data),
  };
  try {
    let response;
    try {
      response = await fetch(process.env.REACT_APP_API_BASE_URL + "/utils/addFaq", requestOptions);
    } catch (e) {
      return false;
    }

    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    console.log("responseData :", datas.data);
    if (datas?.message === "Product limit reached error") {
      return "Product limit reached";
    }
    return datas;
  } catch (err) {
    return false;
  }
};
export const updateFaq = async (data) => {
  await checkIfTokenExpired();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("decrypt_redeem_auth"),
    },
    body: JSON.stringify(data),
  };
  try {
    let response;
    try {
      response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/utils/updateFaq",
        requestOptions
      );
    } catch (e) {
      return false;
    }

    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    console.log("responseData :", datas.data);
    if (datas?.message === "Product limit reached error") {
      return "Product limit reached";
    }
    return datas;
  } catch (err) {
    return false;
  }
};

export const getFaqList = async (data) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("decrypt_redeem_auth"),
    },
  };

  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/utils/getFaq",
      requestOptions
    );
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());

    return datas;
  } catch (err) {
    return err;
  }
};
export const deleteFaq = async (data) => {
  await checkIfTokenExpired();
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("decrypt_redeem_auth"),
    },
    body: JSON.stringify(data),
  };
  try {
    let response;
    try {
      response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/utils/deleteFaq",
        requestOptions
      );
    } catch (e) {
      return false;
    }

    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    console.log("responseData :", datas.data);
    if (datas?.message === "Product limit reached error") {
      return "Product limit reached";
    }
    return datas;
  } catch (err) {
    return false;
  }
};
export const getItemDetails = async (data) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_METADATA_AUTH_TOKEN,
    },
  };

  try {
    let response = await fetch(
      process.env.REACT_APP_IMP_LINK +
        `/api/v1/getProductDetail?chain_id=${
          data?.chain_id
        }&contract_address=${data?.contract_address?.toLowerCase()}&product_id=${data?.product_id}`,
      requestOptions
    );
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    console.log("getItemDetails data", datas);
    return datas;
  } catch (err) {
    return err;
  }
};

export const deleteCollection = async (data) => {
  await checkIfTokenExpired();
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("decrypt_redeem_auth"),
    },
    body: JSON.stringify(data),
  };

  try {
    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/collection/deleteCollection",
      requestOptions
    );
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());

    return datas;
  } catch (err) {
    return err;
  }
};
