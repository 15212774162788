import React from "react";
import { withStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { teal, grey } from "@material-ui/core/colors";
import eventEmitter from "../../../events/events";
import closeIcon from '../../../assets/images/small-logos/close-btn.png'
import "./../../../App.css"

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        overFlow: "hidden",
    },
    primaryColor: {
        color: teal[500],
    },
    secondaryColor: {
        color: grey[700],
    },


    padding: {
        padding: 0
    },
    mainHeader: {
        backgroundColor: "rgba(0, 122, 219, 0.8)",
        padding: 20,
        alignItems: "center"
    },
    mainContent: {
        padding: 30
    },
    secondaryContainer: {
        padding: "20px 25px",
        backgroundColor: grey[200]
    }

});

function ProductPreview(props) {

    const { classes } = props;
    const handleCollectionCreation = () => {
        if(!props?.previewData?.edit)
        eventEmitter.emit("addProduct")
        else
        eventEmitter.emit("editProduct")
    }



   


    return  (
        <Dialog
            className={classes.root}
            fullWidth
            maxWidth="md"
            open={props.show}
            onClose={() => {
                props.handleClose()
            }}
            id="modal_container">

            <DialogContent className={classes.padding} style={{ overflow: "hidden" }}>
                <Grid container >
                    <Grid item xs={12} >
                        <Grid container direction="row" className={classes.mainHeader}>
                            <Grid item xs={11} >
                                <Typography variant="h5">
                                Item Preview
                                </Typography>
                            </Grid>
                            <Grid item xs={1} align="right" className={classes.padding} onClick={() => props.handleClose()}>
                                <IconButton
                                    edge="start"
                                    align="right"
                                    color="inherit"
                                    aria-label="Close"
                                    style={{ padding: 8 }}
                                >
                                    <img src={closeIcon} alt='close' />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            className={classes.mainContent}
                            spacing={1}
                        >
                            <div className="outerrrBorder">
                                <Grid item xs={12} className="modalInput productPreview">
                                    <div className='custom_card pp' >
                                        <div className='collection_img'>
                                            <img src={props?.previewData?.image} alt='collection'/>
                                        </div>
                                        <div className="pp_content">
                                        <h5 className='productHeading text-center'> {props?.previewData?.pName}</h5>
                                        <p title={props?.previewData?.desc}>{props?.previewData?.desc}</p>
                                        </div>
                                    </div>
                                </Grid>
                                
                                <Grid item xs={12} className='btnGrid' align="center" >
                                    <Button variant="contained" className="canclBtn textCapitalize" color="inherit" onClick={props.handleClose}>Cancel</Button>
                                    <Button variant="contained" className="canclBtn textCapitalize" color="inherit" onClick={handleCollectionCreation}>Submit</Button>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                </DialogContent>
            </Dialog >
            );
}

export default withStyles(styles)(ProductPreview);
