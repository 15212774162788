

import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { teal, grey } from "@material-ui/core/colors";
import "./product.css"
import closeIcon from "../../../assets/images/small-logos/close-btn.png"
import "./../../../App.css";
import { getItemDetails } from "apiServices";


const styles = theme => ({
    root: {
        flexGrow: 1,
        overFlow: 'hidden'
    },
    primaryColor: {
        color: teal[500]
    },
    secondaryColor: {
        color: grey[700]
    },

    padding: {
        padding: 0
    },
    mainHeader: {
        backgroundColor: 'rgba(0, 122, 219, 0.8)',
        padding: 20,
        alignItems: "center"
    },
    mainContent: {
        padding: 40,
        margin: 0
    },
    secondaryContainer: {
        padding: "20px 25px",
        backgroundColor: grey[200]
    }
});


function ViewItem(props) {
    const { classes } = props;
    const [showMore, setShowMore] = useState(false)
    const [productInfo, setProductInfo] = useState()

    useEffect(() => {
        const fetch = async () => {
            let prodInfo = await getItemDetails({ contract_address: props?.data?.collectionAddress, chain_id: props?.data?.collectionData?.length > 0 ? props?.data?.collectionData[0]?.chainId : 80002, product_id: props?.data?.productId })
            console.log("prodInfo", prodInfo)
            setProductInfo(prodInfo)
        }
        if (props?.data?.collectionAddress && props?.data?.productId && props?.data?.collectionData)
            fetch()
    }, [props?.data?.productId, props?.data?.collectionAddress, props?.data?.collectionData])


    return (<Dialog
        className={classes.root}
        fullWidth
        maxWidth="md"
        open={props.show}
        onClose={() => {
            props.handleClose()
        }}
        id="modal_container"
    >
        <DialogContent className={classes.padding} style={{ overflow: "hidden" }}>
            <Grid container >
                <Grid item xs={12} >
                    <Grid item xs={1} align="right" style={{ float: "right" }} className={classes.padding} onClick={() => {
                        props.handleClose()
                    }}>
                        <IconButton
                            edge="start"
                            align="right"
                            color="inherit"
                            aria-label="Close"
                            style={{ padding: "20px" }}
                        >
                            <img src={closeIcon} alt='close' />
                        </IconButton>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        className={classes.mainContent}
                        spacing={1}
                    >  <div className="outerrrBorder w-100">
                            <Grid item xs={12} className="modalInput viewItemBox d-md-flex">
                                <div className="imgDivWidth">
                                    <div className="viewItemBoxImgDiv">
                                        <img src={productInfo?.image_url && productInfo?.image_url ? productInfo?.image_url : ""} alt='Product' />
                                    </div>

                                </div>
                                <div className="viewItemBoxContentDiv">
                                    <div className="viewItemBoxContentHeadingDiv ms-md-3">
                                        <h5>{productInfo?.name ? productInfo?.name : "-"}</h5>
                                        <p>{productInfo?.description ? productInfo?.description : "-"}</p>
                                    </div>

                                </div>
                            </Grid>
                            <Grid item xs={12} className="modalInput viewItemBox d-md-flex mt-3">
                                <div className="viewItemBoxContentDetailsDiv mt-2 w-100">
                                    <h5>Details</h5>
                                    <div className="d-flex justify-content-between w-100">
                                        <p className="left">Product Id </p>
                                        <p className="right">{props?.data?.productId}</p>
                                    </div>
                                    <div className="d-flex justify-content-between w-100">
                                        <p className="left">Collection Address</p>
                                        <p className="right">{props?.data?.collectionAddress ? (props?.data?.collectionAddress.slice(0, 3) + "..." + props?.data?.collectionAddress.slice(39, 41)) : ""}</p>
                                    </div>
                                    {/* {console.log("props?.data?.productData[0]?.productType", props?.data?.productData[0]?.productType)} */}
                                    {productInfo?.product_type && productInfo?.product_type === "Physical" &&
                                        <><div className="d-flex justify-content-between w-100">
                                            <p className="left">Address</p>
                                            <p className="right">{props?.data?.address ? props?.data?.address : "-"}</p>
                                        </div>
                                            <div className="d-flex justify-content-between w-100">
                                                <p className="left">Pincode</p>
                                                <p className="right">{props?.data?.pinCode ? props?.data?.pinCode : "-"}</p>
                                            </div>
                                        </>
                                    }
                                    <div className="d-flex justify-content-between w-100">
                                        <p className="left">Phone no.</p>
                                        <p className="right">{props?.data?.phoneNo ? props?.data?.phoneNo : "-"}</p>
                                    </div>
                                    <div className="d-flex justify-content-between w-100">
                                        <p className="left">Email</p>
                                        <p className="right">{props?.data?.email ? props?.data?.email : "-"}</p>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} className="modalInput viewItemBox d-md-flex">
                                <div className="viewItemBoxContentDiv">
                                    <div className="viewItemBoxContentDetailsDiv mt-2 text-start">
                                        <div className="d-flex my-2 align-items-center">
                                            <div className="blueBox"></div>
                                            <h5 className="m-0">Notes</h5>
                                        </div>
                                        {!showMore ? <p className="text-start">{props?.data?.notes ? props?.data?.notes?.slice(0, 120) + (props?.data?.notes?.length > 120 ? "..." : "") : "-"}</p> : <p className="text-start"> {props?.data?.notes ? props?.data?.notes : "-"}</p>}
                                        {props?.data?.notes?.length > 120 ? <a className="showmore" onClick={() => {
                                            setShowMore(!showMore)
                                        }}>{!showMore ? "Show More" : "Show Less"}</a> : ""}
                                    </div>
                                </div>
                            </Grid>
                        </div>
                    </Grid>


                </Grid>

            </Grid>
        </DialogContent>
    </Dialog>
    );
}

export default withStyles(styles)(ViewItem);
