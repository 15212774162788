export const collectionFilterFields = [
  { key: "Select Option", value: "ALL" },
  { key: "Collection Address", value: "collectionAddress" },
  { key: "Name", value: "collectionName" },
  { key: "Owner", value: "owner" },
  { key: "Email", value: "email" },
  { key: "Network", value: "chainId" },
  { key: "Imported", value: "isImported" },
  { key: "Hot Collection", value: "isHotCollection" },
  { key: "Blocked", value: "isBlocked" },
];

export const ownedCollectionFilterFields = [
  { key: "Select Option", value: "ALL" },
  { key: "Collection Address", value: "collectionAddress" },
  { key: "Name", value: "collectionName" },
  { key: "Email", value: "email" },
  { key: "Network", value: "chainId" },
  { key: "Hot Collection", value: "isHotCollection" },
  // { key: "Blocked", value: "isBlocked" },
];

export const itemFilterFields = [
  { key: "Select Option", value: "ALL" },
  { key: "Collection Address", value: "collectionAddress" },
  { key: "Item Name", value: "productName" },
  { key: "Item ID", value: "productId" },
  { key: "Sort By ID", value: "sortByproductId" },
  { key: "Sort By Name", value: "sortByproductName" },
  { key: "Sort By Popularity", value: "sortByPopularity" },
];

export const requestFilterFields = [
  { key: "Select Option", value: "ALL" },
  { key: "Collection Address", value: "collectionAddress" },
  { key: "Username", value: "userName" },
  { key: "Item ID", value: "productId" },
  { key: "Token ID", value: "tokenId" },
  { key: "Status", value: "status" },
];

export const collectionTableColumns = [
  { name: "Collection", align: "center" },
  { name: "Email", align: "center" },
  { name: "Chain ID", align: "center" },
  { name: "Status", align: "center" },
  { name: "Hot", align: "center" },
  { name: "Block", align: "center" },
  { name: "Action", align: "center" },
];

export const ownedCollectionTableColumns = [
  { name: "Collection", align: "center" },
  { name: "Email", align: "center" },
  { name: "Chain ID", align: "center" },
  { name: "Product Limit", align: "center" },
  { name: "Total Products", align: "center" },
];

export const mailTableColumns = [
  { name: "Select", align: "left", className: "radeem-1" },
  { name: "Owners", align: "center" },
  { name: "E-Mail", align: "center" },
  { name: "Operation", align: "center" },
];

export const itemTableColumns = [
  { name: "Item ID", align: "center" },
  { name: "Item Name", align: "center" },
  { name: "Item Type", align: "center" },
  { name: "Status", align: "center" },
  { name: "Collection Address", align: "center" },
  { name: "Assigned Count", align: "center" },
  { name: "Claimed Count", align: "center" },
  { name: "Action", align: "center" },
];

export const redeemTableColumns = [
  { name: "Item Id", align: "center" },
  { name: "User Name", align: "center" },
  { name: "Email", align: "center" },
  { name: "Wallet Address", align: "center" },
  { name: "Collection Address", align: "center" },
  { name: "Token Id", align: "center" },
  { name: "Status", align: "center" },
  { name: "More Info", align: "center" },
  { name: "Action", align: "center" },
];

export const subscribeTableColumns = [
  { name: "S NO.", align: "center" },
  { name: "Email Id", align: "center" },
  { name: "Joined On", align: "center" },
];

export const faqTableColumns = [
  { name: "S NO.", align: "center" },
  { name: "Questions", align: "center" },
  { name: "Answers", align: "center" },
  { name: "Update", align: "center" },
  { name: "Delete", align: "center" },
];
export const pageLimit = 4;
