import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Footer from "../Footer";
import Table from "../Tables/Table";
import "./../../../App.css";
import VuiButton from "components/VuiButton";
import { useEffect, useState } from "react";
import AddProduct from "./addProduct";
import eventEmitter from "./../../../events/events";
import { deleteProducts } from "apiServices";
import { ethers } from "ethers";
import CircularUnderLoad from "components/common/spinner";
import toast from "react-hot-toast";
import moment from "moment";
import deleteIcon from "../../../assets/images/logos/delete.png";
import editIcon from "../../../assets/images/logos/edit.png";
import { messages } from "config/messages";
import { getCurrentAccount, getCurrentProvider } from "../authentication/connectWallet";
import { account } from "config/localStorageNames";
import { itemTableColumns, itemFilterFields } from "helpers/constants";
import { viewAllProducts } from "apiServices";
import { pageLimit } from "helpers/constants";
import Pagination, { bootstrap5PaginationPreset } from "react-responsive-pagination";
import { isValidAddress } from "helpers/getters";
import ConfirmationModal from "components/common/ConfirmationModal";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AssignProductToToken from "../productAssignments/assignProductToToken";
import DeleteAssignment from "../productAssignments/deleteAssignment";
import { CSVLink } from "react-csv";
import { checkIfOwned, handleCopyToClipboard } from "helpers/getters";
import UpdateProduct from "./updateProduct";
import { getAssignedTokens } from "apiServices";
import { getNetworkNameByChain } from "config/rpcs";
import DownloadIcon from "@mui/icons-material/Download";

function Products() {
  const [openForm, setOpenForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [products, setProducts] = useState("none");
  const [reloadProducts, setReloadProducts] = useState(false);
  const [currAccount, setCurrAccount] = useState("");
  const [loading, setLoading] = useState(false);
  const [delEvent, setDelEvent] = useState(0);
  const [searchField, setSearchField] = useState("ALL");
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState();
  const [searchedText, setSearchedText] = useState("");
  const [showPopup, setShowPopup] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [allProducts, setAllProducts] = useState([]);
  const [confirmation, setConfirmation] = useState(false);
  const [searched, setSearched] = useState(false);
  const [toggleSearch, setToggleSearch] = useState(false);

  useEffect(() => {
    if (localStorage.getItem(account) === "null" || localStorage.getItem(account) == null) {
      window.location.href = "/admin";
    }
  }, [reloadProducts]);

  useEffect(() => {
    setCurrAccount(localStorage.getItem(account)?.toLowerCase());
  }, [localStorage.getItem(account)]);

  const handleReloadProducts = () => {
    setSearchField("ALL");
    setSearched(false)
    setSearchedText("");
    setShowPopup("");
    setReloadProducts(!reloadProducts);
    setOpenForm(false);
  };

  eventEmitter.removeAllListeners("reload");
  eventEmitter.on("reload", handleReloadProducts);

  const handleClose = () => {
    setOpenForm(false);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);

      let products_ = await viewAllProducts({
        page: currentPage,
        limit: pageLimit,
        owner: currAccount,
        isBlocked: false,
        isImported: true,
      });
      let count = products_?.count;
      setCount(count);
      products_ = products_?.results;

      for (let i = 0; i < products_?.length; i++) {
        try {
          
          let tokens = await getAssignedTokens({
            contract_address: products_[i]?.collectionAddress,
            chain_id: products_[i]?.collectionData[0]?.chainId,
            product_id: products_[i]?.productId,
          });
          products_[i].assigned = tokens;
        } catch (error) {
          console.log("error in getting assignedProducts", error)
        }
      }

      if (products_ && products_?.length > 0) setProducts(products_);
      else setProducts([]);

      let exportableItems = await viewAllProducts({
        page: 1,
        limit: 10000,
        owner: currAccount,
        isBlocked: false,
        isImported: true,
      });
      if (exportableItems && exportableItems?.results?.length > 0)
        setAllProducts(exportableItems?.results);

      setLoading(false);
    };

    if (currAccount !== "" && currAccount !== undefined && currAccount !== "undefined")
      fetchProducts();
  }, [currAccount]);

  const sign = async () => {
    try {
      let provider = await getCurrentProvider();
      // await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      let rawMessage = messages.signMessage;
      const signature = await signer.signMessage(rawMessage);
      const messageSender = ethers.utils.verifyMessage(rawMessage, signature);
      return signature;
    } catch (e) {
      setDelEvent(0);
      toast.error(e.code);
      return false;
    }
  };

  const deleteProduct = async () => {
    setConfirmation(false);
    try {
      let originallyConnectedAccount = await getCurrentAccount();
      if (currAccount?.toLowerCase() !== originallyConnectedAccount?.toLowerCase()) {
        setWrongWallet(true);
        return;
      }

      setDelEvent(1);
      let signature = await sign();
      if (signature === false) {
        return;
      }
      const messageSender = ethers.utils.verifyMessage(messages.signMessage, signature);
      if (messageSender?.toLowerCase() !== currAccount?.toLowerCase()) {
        setWrongWallet(true);
        setDelEvent(0);
        return;
      }
      let isOwned = await checkIfOwned(
        selectedProduct.collectionAddress,
        messageSender,
        getNetworkNameByChain[
        selectedProduct?.collectionData?.length > 0
          ? selectedProduct?.collectionData[0]?.chainId
          : 80002
        ]
      );

      if (!isOwned) {
        toast.error("You don't own this collection");
        setAcceptEvent(0);
        handleReloadData();
        return;
      }
      const data = {
        productId: selectedProduct?.productId,
        signature: signature,
        message: messages.signMessage,
        walletAddress: currAccount,
        collectionAddress: selectedProduct?.collectionAddress,
        chainId: selectedProduct?.collectionData[0]?.chainId,
      };
      let deleteResult = await deleteProducts(data);
      if(deleteResult.statusCode && deleteResult.statusCode === 401)
      {
        toast.error(deleteResult.message)
      }
      
      if (!deleteResult.statusCode && deleteResult?.status === "warning") {
        setDelEvent(0);
        toast.error(messages.delAssignedProductError);
        return;
      }else{
          toast.success(messages.delSuccess)
        
      }
     
      setDelEvent(2);
    } catch (e) {
      console.log("err", e);
      setDelEvent(0);
    }
    setCurrentPage(1);
    handleReloadProducts();
  };

  useEffect(() => {
    var d = new Date(0); // The 0 there is the key, which sets the date to the epoch

    let _rows =
      products !== "none" && products?.length > 0
        ? products?.map((p, i) => {
          let status = "";
          let currentTime = moment().unix();
          if (p?.start_date > currentTime && p?.end_date > currentTime) {
            status = "Upcoming";
          } else if (p?.end_date < currentTime) {
            status = "Expired";
          } else {
            status = "Active";
          }
          return {
            "Item ID": (
              <VuiTypography variant="button" color="white" fontWeight="medium" mb="4px">
                {p?.productId}
              </VuiTypography>
            ),
            "Item Name": (
              <VuiTypography variant="button" color="white" fontWeight="medium" mb="4px">
                {p?.productName}
              </VuiTypography>
            ),
            "Item Type": (
              <VuiTypography variant="button" color="white" fontWeight="medium" mb="4px">
                {p?.productType}
              </VuiTypography>
            ),
            Status: (
              <VuiTypography variant="button" color="white" fontWeight="medium" mb="4px">
                {status}
              </VuiTypography>
            ),
            "Collection Address": (
              <VuiTypography variant="button" mb="4px">
                {p?.collectionData[0]?.collectionName}
                <VuiTypography
                  variant="input"
                  mb="4px"
                  ml="4px"
                  className="copy-icon"
                  onClick={() => handleCopyToClipboard(p?.collectionAddress)}
                >
                  (
                  {p?.collectionAddress?.slice(0, 5) +
                    "..." +
                    p?.collectionAddress?.slice(38, 42)}
                  )
                  <ContentCopyIcon />
                </VuiTypography>
              </VuiTypography>
            ),
            "Assigned Count": (
              <VuiTypography variant="button" color="white" fontWeight="medium" mb="4px">
                {p?.assigned ? p?.assigned?.length : "0"}{" "}
                <CSVLink
                  data={p?.assigned}
                  filename={"TokenList.csv"}
                  headers={[
                    {
                      label: "Token Id",
                      key: "token_id",
                    },
                  ]}
                >
                  <DownloadIcon className="dwnld_icon" />
                </CSVLink>
              </VuiTypography>
            ),
            "Claimed Count": (
              <VuiTypography variant="button" color="white" fontWeight="medium" mb="4px">
                {p?.claimedCount ? p?.claimedCount : 0}
              </VuiTypography>
            ),
            Action: (
              <>
                <VuiButton
                  variant="gradient"
                  color="info"
                  size="medium"
                  style={{ marginRight: "5px" }}
                  onClick={() => {
                    if (p?.collectionData[0]?.isBlocked) {
                      toast.error(messages.blockedCollection);
                      return;
                    }
                    setSelectedProduct(p);
                    setShowPopup("assign");
                  }}
                >
                  Assign
                </VuiButton>
                <VuiButton
                  variant="gradient"
                  color="info"
                  size="medium"
                  disabled={p?.assigned?.length === 0}
                  title={"Unassign Item from Token Id"}
                  onClick={() => {
                    if (p?.collectionData[0]?.isBlocked) {
                      toast.error(messages.blockedCollection);
                      return;
                    }
                    setSelectedProduct(p);
                    setShowPopup("unassign");
                  }}
                >
                  Unassign
                </VuiButton>
                <VuiButton
                  className="edit-btnn whiteBg"
                  onClick={() => {
                    setSelectedProduct(p);
                    setConfirmation(true);
                  }}
                  title="Delete Product"
                >
                  <img src={deleteIcon} alt="delete" />
                </VuiButton>
                <VuiButton
                  className="edit-btnn whiteBg"
                  onClick={() => {
                    setSelectedProduct(p);
                    setShowPopup("edit");
                  }}
                  title="Modify Product"
                >
                  <img src={editIcon} alt="edit" />
                </VuiButton>
              </>
            ),
          };
        })
        : [];

    setRows(_rows);
  }, [products, reloadProducts]);

  const getSearchOptions = () => {
    if (searchField === "sortByproductName" || searchField === "sortByproductId") {
      return ["ASC", "DESC"];
    } else if (searchField === "sortByPopularity") {
      return ["High To Low", "Low To High"];
    }

    return [];
  };

  const showSelect = () => {
    if (
      searchField == "sortByproductName" ||
      searchField == "sortByproductId" ||
      searchField == "sortByPopularity"
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    console.log("searched and search field", searchField, searched)
    if (searchField === "ALL" && searched) {
      console.log("here", searchField)
      setSearchedText("")
      setSearched(false)
    }
    if (currAccount !== "" && currAccount !== undefined && currAccount !== "undefined") {
      handleSearch(searched);
    }
  }, [currentPage, reloadProducts, searched, toggleSearch]);

  const handleSearch = async (searched) => {
    setLoading(true);
    let req = {
      page: currentPage,
      limit: pageLimit,
      owner: currAccount,
      isBlocked: false,
      isImported: true,
    };
    let _products;
    if (searched && searchField === "collectionAddress") {
      if (!isValidAddress(searchedText) && searchedText !== "") {
        toast.error(messages.invalidAddress);
        setLoading(false);
        return;
      }
      req = {
        ...req,
        collectionAddress: searchedText,
      };
    } else if (searched && searchField === "productName") {
      req = {
        ...req,
        productName: searchedText,
      };
    } else if (searched && searchField === "productId") {
      req = {
        ...req,
        productId: searchedText,
      };
    } else if (
      searched && (searchField === "sortByproductId" ||
        searchField === "sortByproductName" ||
        searchField === "sortByPopularity")
    ) {
      req = {
        ...req,
        sortKey:
          searchField === "sortByproductId"
            ? "productId"
            : searchField === "sortByPopularity"
              ? "claimedCount"
              : "productName",
        sortValue:
          searchedText === "High To Low"
            ? "DESC"
            : searchedText === "Low To High"
              ? "ASC"
              : searchedText,
      };
    }

    _products = await viewAllProducts(req);

    setCount(_products?.count);
    _products = _products?.results;
    for (let i = 0; i < _products?.length; i++) {
      let tokens = await getAssignedTokens({
        contract_address: _products[i]?.collectionAddress,
        chain_id: _products[i]?.collectionData[0]?.chainId,
        product_id: _products[i]?.productId,
      });
      _products[i].assigned = tokens;
    }
    if (_products && _products?.length > 0) {
      setProducts(_products);
    } else {
      setProducts([]);
      setCount(0);
    }
    req.page = 1;
    req.limit = 10000;
    let exportableItems = await viewAllProducts({
      ...req,
    });
    if (exportableItems && exportableItems?.results?.length > 0)
      setAllProducts(exportableItems?.results);
    // setReloadProducts(!reloadProducts);
    setLoading(false);
  };

  return (
    <div className="collection__main">
      {delEvent === 1 ? (
        <div className="spinner_div">
          <div className="spinner">
            <div className="spinner-item"></div>
            <div className="spinner-item"></div>
            <div className="spinner-item"></div>
          </div>
        </div>
      ) : (
        ""
      )}
      {openForm ? (
        <AddProduct
          show={true}
          handleClose={handleClose}
          handleReloadProducts={handleReloadProducts}
        />
      ) : (
        ""
      )}
      {showPopup === "assign" ? (
        <AssignProductToToken
          show={true}
          handleClose={() => setShowPopup("")}
          selectedProduct={selectedProduct}
          handleReloadProducts={handleReloadProducts}
        />
      ) : (
        ""
      )}
      {showPopup === "unassign" ? (
        <DeleteAssignment
          show={true}
          handleClose={() => setShowPopup("")}
          selectedProduct={selectedProduct}
          handleReloadProducts={handleReloadProducts}
        />
      ) : (
        ""
      )}
      {showPopup === "edit" ? (
        <UpdateProduct
          show={true}
          handleClose={() => setShowPopup("")}
          selectedProduct={selectedProduct}
          handleReloadProducts={handleReloadProducts}
        />
      ) : (
        ""
      )}
      {confirmation ? (
        <ConfirmationModal
          header="Confirm Delete"
          handleClose={() => setConfirmation(false)}
          handleConfirm={deleteProduct}
        />
      ) : (
        ""
      )}
      {/* <DashboardNavbar /> */}
      <VuiBox alignItems="center" mb="22px">
        <VuiTypography variant="lg" color="black">
          <div className="field_Bar">
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                if (searchedText === "") {
                  return;
                }
                setCurrentPage(1);
                setSearched(true);
                setToggleSearch(!toggleSearch);
              }}
            >
              <div className="fields_div">
                <select
                  className="form-select"
                  value={searchField}
                  onChange={(e) => {
                    setSearchedText("")
                    setSearched(false)
                    setSearchField(e.target.value);
                  }}
                  aria-label="Select Option"
                >
                  {itemFilterFields &&
                    itemFilterFields?.map((field, i) => {
                      return (
                        <option key={i} value={field?.value} selected>
                          {field?.key}
                        </option>
                      );
                    })}
                </select>
                {searchField !== "ALL" && searchField !== "" && showSelect() && (
                  <select
                    className="form-select search_field"
                    value={searchedText}
                    onChange={(e) => {
                      setSearchedText(e.target.value);
                      setSearched(false)
                    }}
                    aria-label="Select Option"
                  >
                    <option value="" selected>
                      Select Option
                    </option>
                    {getSearchOptions() && getSearchOptions()?.length > 0
                      ? getSearchOptions()?.map((o, i) => {
                        return (
                          <option key={i} value={o}>
                            {o?.toUpperCase()}
                          </option>
                        );
                      })
                      : ""}
                  </select>
                )}
                {searchField !== "ALL" && searchField !== "" && !showSelect() && (
                  <input
                    className="form-control search_field"
                    id="Input1"
                    value={searchedText}
                    onChange={(e) => {
                      setSearched(false)
                      setSearchedText(e.target.value?.trim());
                    }}
                    placeholder="Enter Search"
                  />
                )}

                <VuiButton
                  className="logoutBtn"
                  size="medium"
                  disabled={searchedText === "" && searchField !== "ALL"}
                  onClick={(e) => {
                    console.log("enter click", e);
                    e.preventDefault();
                    setCurrentPage(1);
                    setSearched(true)
                    setToggleSearch(!toggleSearch);
                  }}
                >
                  Search
                </VuiButton>
              </div>
              <VuiButton
                variant="gradient"
                color="info"
                size="medium"
                onClick={() => setOpenForm(true)}
              >
                {" "}
                Add Item
              </VuiButton>
            </form>
          </div>
        </VuiTypography>
      </VuiBox>

      <VuiBox className="contentBox" py={3}>
        <VuiBox mb={3}>
          <Card className="contentTable">
            <VuiBox
              sx={{
                "& th": {
                  borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                    `${borderWidth[1]} solid ${grey[700]}`,
                },
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                      `${borderWidth[1]} solid ${grey[700]}`,
                  },
                },
              }}
            >
              <Table columns={itemTableColumns} rows={rows} />
            </VuiBox>
            {loading ? (
              <CircularUnderLoad />
            ) : products !== "none" && products?.length === 0 ? (
              <VuiTypography variant="lg" className="NoData">
                No Item Found
              </VuiTypography>
            ) : (
              ""
            )}
          </Card>
          <CSVLink
            data={allProducts}
            filename={"ProductList.csv"}
            headers={[
              {
                label: "Item Id",
                key: "productId",
              },
              {
                label: "Item Name",
                key: "productName",
              },
              {
                label: "Collection Address",
                key: "collectionAddress",
              },
              {
                label: "Item Type",
                key: "productType",
              },
              {
                label: "Claimed Count",
                key: "claimedCount",
              },
            ]}
          >
            <VuiButton variant="gradient" color="info" size="small">
              {" "}
              Export
            </VuiButton>
          </CSVLink>
          {!loading && Math.ceil(count / pageLimit) > 1 && (
            <Pagination
              {...bootstrap5PaginationPreset}
              current={currentPage}
              total={Math.ceil(count / pageLimit)}
              onPageChange={(p) => setCurrentPage(p)}
            />
          )}
        </VuiBox>
      </VuiBox>
      <Footer />
    </div>
  );
}

export default Products;
