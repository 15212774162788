

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import Footer from "../Footer";
import "./../../../App.css"

import VuiButton from "components/VuiButton";
import { useEffect, useState } from "react";
import AssignProductForm from "./assignProductToToken";
import eventEmitter from "./../../../events/events";
import { getCollectionsOwner } from "apiServices";
import { handleImport } from "helpers/setters";
import { checkImportStatus } from "apiServices";
import successTick from "./../../../assets/images/success.png"
import Grid from "@material-ui/core/Grid";
import DeleteAssignment from "./deleteAssignment";
import { chainId, account, connectedrole } from "config/localStorageNames";

function AssignProduct() {
  const [openForm, setOpenForm] = useState(false)
  const [openDeleteForm, setOpenDeleteForm] = useState(false)
  const [visiblity, setVisibility] = useState("hide")

  const [collections, setCollections] = useState([])
  const [reloadCollections, setReloadCollections] = useState(false)

  const handleReloadCollection = () => {
    setReloadCollections(!reloadCollections)
    setVisibility("hide")
  }

  eventEmitter.removeAllListeners("reloadCollections")
  eventEmitter.on("reloadCollections", handleReloadCollection)

  const handleClose = () => {
    setOpenForm(false)
    setOpenDeleteForm(false)
    console.log("fff")
  };

  useEffect(() => {
    if ((localStorage.getItem(account) === "null" || localStorage.getItem(account) == null)) {
      window.location.href = "/signIn"
    }
  }, [reloadCollections])

  useEffect(() => {
    console.log("reload cont", reloadCollections)
    const fetchCollections = async () => {
      let coll = await getCollectionsOwner()
      if (coll && coll?.results?.length > 0)
        setCollections(coll?.results[0])
    }
    fetchCollections()
  }, [reloadCollections])

  eventEmitter.removeAllListeners("reload")
  eventEmitter.on("reload", handleReloadCollection)


  useEffect(() => {
    let _rows = collections?.length > 0 ? collections.map((c, i) => {
      return {
        "Collection Address": c?.collectionAddress,

        "Owner": (
          <VuiTypography variant="button" color="white" fontWeight="medium" mb="4px">
            {c?.owner}
          </VuiTypography>
        ),
        "Collection Name": (
          <VuiTypography variant="button" color="white" fontWeight="medium" mb="4px">
            {c?.collectionName}
          </VuiTypography>
        ),
        "Status": (
          <VuiTypography component="a" href="#" variant="caption" color="text" fontWeight="medium" onClick={async () => {
            let res = await checkImportStatus({ collectionAddress: c?.collectionAddress, chainId: c?.chainId })
            console.log(res[0]?.status, "res")
          }}>
            Check Status
          </VuiTypography>
        ),
        "Action": (
          <VuiButton variant="gradient" color="info" size="medium" onClick={async () => {
            let req = {
              collectionAddress: c?.collectionAddress, collectionName: c?.collectionName, chainId: c?.chainId
            }
            await handleImport(req)
          }}>Import</VuiButton>
        ),
      }
    }) : []

  }, [collections, reloadCollections])

  return (

    <div className="collection__main">
      {openForm ? <AssignProductForm show={true} handleClose={handleClose} setVisibility={setVisibility} /> : ""}
      {openDeleteForm ? <DeleteAssignment show={true} handleClose={handleClose} setVisibility={setVisibility} /> : ""}
      {/* <DashboardNavbar /> */}
      <VuiBox alignItems="center" mb="22px">
        <VuiTypography variant="lg" color="black">
          <div className="field_Bar">
            <form action="">
              <div className="fields_div">
                <VuiTypography variant="lg" color="black">
                  Items Assignment
                </VuiTypography>
              </div>
              {/* <VuiButton variant="gradient" color="info" size="medium" onClick={() => setOpenForm(true)}> Add Product</VuiButton> */}
            </form>
          </div>
        </VuiTypography >
      </VuiBox >
      <VuiBox className="contentBox" py={3}>
        <VuiBox mb={3}>
          <Card className="contentTable">

            <VuiBox
              sx={{
                "& th": {
                  borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                    `${borderWidth[1]} solid ${grey[700]}`,
                },
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                      `${borderWidth[1]} solid ${grey[700]}`,
                  },
                },
              }}
            >

            </VuiBox>
          </Card>
        </VuiBox>
        <Grid container >
          <Grid item xs={12} justifyContent="center" >
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} align="center" >
                <VuiButton variant="gradient" color="info" size="medium" onClick={() => {
                  setOpenForm(true)
                  setVisibility("hide")
                }}>Assign Item to Token</VuiButton>
              </Grid>
              <Grid item xs={12} align="center">
                <VuiButton variant="gradient" color="info" size="medium" onClick={() => {
                  setOpenDeleteForm(true)
                  setVisibility("hide")
                }}>Delete Assigned Items</VuiButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <VuiBox>
          <div className={visiblity}> <img src={successTick}></img>
            Action Completed Successfully</div>
        </VuiBox>
      </VuiBox>
      <Footer />
    </div>
  );
}

export default AssignProduct;
