import React, { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

import Footer from "./../Footer/index";
import colors from "assets/theme/base/colors";
// Dashboard layout components
import WelcomeMark from "./components/WelcomeMark";
// import Projects from "./components/Projects";
import OrderOverview from "./components/OrderOverview";
import SatisfactionRate from "./components/SatisfactionRate";
import ReferralTracking from "./components/ReferralTracking";
import { useHistory } from "react-router-dom";
import eventEmitter from "./../../../events/events";
import { chainId, account, connectedrole } from "../../../config/localStorageNames";
import { getCollections, getProducts } from "apiServices";
import { getDashboardStats } from "apiServices";
import { checkIfIsUserOrAdmin } from "helpers/getters";
import { getOwnerStats } from "apiServices";
import Loader from "../../common/Loader";
import { toast } from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import { messages } from "config/messages";

function Dashboard() {
  const { gradients } = colors;
  const [currAccount, setCurrAccount] = useState("");
  const [stats, setStats] = useState({});
  const [loader, setLoader] = useState(false);
  const [reload, setReload] = useState(false)
  const [activeRefresh, setActiveRefresh] = useState(false)
  const history = useHistory();

  const contentLoader = () => {
    return (< ThreeDots
      height="30"
      width="30"
      radius="9"
      color="#232323"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}
      }
      wrapperClassName=""
      visible={true}
    />)
  }

  eventEmitter.on("reload", () => {
    if (
      localStorage.getItem(connectedrole) !== undefined &&
      localStorage.getItem(connectedrole) !== null &&
      localStorage.getItem(connectedrole) !== "undefined" &&
      localStorage.getItem(connectedrole) !== "null"
    ) {
      history.push("/");
    }
  });

  useEffect(() => {
    if (
      localStorage.getItem(connectedrole) !== undefined &&
      localStorage.getItem(connectedrole) !== null &&
      localStorage.getItem(connectedrole) !== "undefined" &&
      localStorage.getItem(connectedrole) !== "null"
    ) {
      history.push("/");
    }
    setCurrAccount(localStorage.getItem(account)?.toLowerCase());
  }, [localStorage.getItem(account)]);

  useEffect(() => {
    const fetchStats = async () => {
      setLoader(true);
      let stats;
      try {
        if (checkIfIsUserOrAdmin() === "admin") stats = await getDashboardStats();
        else if (checkIfIsUserOrAdmin() === "owner") {
          stats = await getOwnerStats({ owner: currAccount, isImported: true });
        }
        console.log("stats=====>", stats);
        setStats(stats);
        setLoader(false);
      } catch (err) {
        setLoader(false);
        toast.error("Something Went Wrong");
      }
    };

    fetchStats();
  }, [currAccount, reload]);



  return (
    <>
      {loader &&
        <Loader />
      }
      <div className="dashboard__main">

        <div className="refresh-icon">

          <i onClick={async () => {
            setActiveRefresh(true);
            setReload(!reload)
            toast.success(messages.refreshMessage)
            setTimeout(() => {
              setActiveRefresh(false);
            }, 5000)
          }} className={`${activeRefresh ? "active" : ""} fa fa-refresh`} aria-hidden="true"></i>
        </div>
        {/* <DashboardNavbar /> */}
        <VuiBox py={3}>
          <VuiBox></VuiBox>
          <VuiBox mb={3}>

            <Grid container spacing="18px">
              <Grid
                className="cardGrid"
                sx={{
                  background: "#FFFFFF",
                  paddingTop: "0",
                  height: "300px",
                  "& .MuiGrid-grid": {
                    borderRadius: "37px",
                    boxShadow: "4px 4px 4px 2px rgba(0, 0, 0, 0.25)",
                  },
                }}
                item
                xs={10}
                md={6}
                lg={4}
                xl={3}
                mt={3}
              >
                <SatisfactionRate collection={stats?.collectionCount >= 0 ? stats?.collectionCount : contentLoader()} />
              </Grid>
              <Grid
                className="cardGrid"
                sx={{
                  background: "#FFFFFF",
                  height: "300px",
                  "& .MuiGrid-grid": {
                    borderRadius: "37px",
                    boxShadow: "4px 4px 4px 2px rgba(0, 0, 0, 0.25)",
                  },
                }}
                item
                xs={10}
                md={6}
                lg={4}
                xl={3}
                mt={3}
              >
                <ReferralTracking
                  title="Total Items"
                  color="yellow"
                  percentage="100"
                  product={stats?.productCount >= 0 ? stats?.productCount : contentLoader()}
                />
              </Grid>
              {localStorage.getItem(connectedrole) === "owner" ? (
                <>
                  <Grid
                    className="cardGrid"
                    sx={{
                      background: "#FFFFFF",
                      height: "300px",
                      "& .MuiGrid-grid": {
                        borderRadius: "37px",
                        boxShadow: "4px 4px 4px 2px rgba(0, 0, 0, 0.25)",
                      },
                    }}
                    item
                    xs={10}
                    md={6}
                    lg={4}
                    xl={3}
                    mt={3}
                  >
                    <ReferralTracking
                      title="Items Redeemed"
                      color="success"
                      percentage="100"
                      product={stats?.redeemedRequestCount >= 0 ? stats?.redeemedRequestCount : contentLoader()}
                    />
                  </Grid>
                  <Grid
                    className="cardGrid"
                    sx={{
                      background: "#FFFFFF",
                      height: "300px",
                      "& .MuiGrid-grid": {
                        borderRadius: "37px",
                        boxShadow: "4px 4px 4px 2px rgba(0, 0, 0, 0.25)",
                      },
                    }}
                    item
                    xs={10}
                    md={6}
                    lg={4}
                    xl={3}
                    mt={3}
                  >
                    <ReferralTracking
                      title="Redemption Request"
                      color="yellow"
                      percentage="100"
                      product={stats?.requestCount >= 0 ? stats?.requestCount : contentLoader()}
                    />
                  </Grid>
                  <Grid
                    className="cardGrid"
                    sx={{
                      background: "#FFFFFF",
                      height: "300px",
                      "& .MuiGrid-grid": {
                        borderRadius: "37px",
                        boxShadow: "4px 4px 4px 2px rgba(0, 0, 0, 0.25)",
                      },
                    }}
                    item
                    xs={10}
                    md={6}
                    lg={4}
                    xl={3}
                    mt={3}
                  >
                    <ReferralTracking
                      title="Pending Requests"
                      color="red"
                      percentage="100"
                      product={stats?.pendingRequestCount >= 0 ? stats?.pendingRequestCount : contentLoader()}
                    />
                  </Grid>
                </>
              ) : (
                ""
              )}
            </Grid>

          </VuiBox>
        </VuiBox>
        <Footer />
      </div>
    </>
  );
}

export default Dashboard;
