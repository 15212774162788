

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Vision UI Dashboard React Context Provider
import { VisionUIControllerProvider } from "context";
// if (process.env.REACT_APP_ENV === 'STAGING' || process.env.REACT_APP_ENV === 'PRODUCTION') {
//   console.log = () => { }
//   console.error = () => { }
//   console.debug = () => { }
// }
ReactDOM.render(
  <BrowserRouter>
    <VisionUIControllerProvider>
      <App />
    </VisionUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
