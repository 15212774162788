import React, { useEffect, useState } from "react";
import { withStyles, MenuItem } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { teal, grey } from "@material-ui/core/colors";
import "./newsletter.css";
import { sendMail } from "apiServices";
import "./../../../App.css";
import toast from "react-hot-toast";
import { validationMessage } from "config/messages";
import { messages } from "config/messages";
import closeIcon from "../../../assets/images/small-logos/close-btn.png";

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        overFlow: "hidden",
    },
    primaryColor: {
        color: teal[500],
    },
    secondaryColor: {
        color: grey[700],
    },

    padding: {
        padding: 0,
    },
    mainHeader: {
        backgroundColor: "rgba(0, 122, 219, 0.8)",
        padding: 20,
        alignItems: "center",
    },
    mainContent: {
        padding: 10,
        margin: 0,
    },
    secondaryContainer: {
        padding: "20px 25px",
        backgroundColor: grey[200],
    },
});

function Newsletter(props) {
    const { classes } = props;
    const [email, setEmail] = useState([]);
    const [subject, setSubject] = useState("");
    const [greetings, setGreetings] = useState("");
    const [message, setMessage] = useState("");
    const [imageSource, setImageSource] = useState("");
    const [regards, setRegards] = useState("");
    const [position, setPosition] = useState("");
    const [link, setLink] = useState("");
    const [messageForLink, setMessageForLink] = useState("");
    const [addEvent, setAddEvent] = useState(0);

    useEffect(() => {
        const fetch = async () => {
            if (props?.emails) {
                setEmail(props?.emails);
            }
        };
        fetch();
    }, [props]);

    const sendMailHandler = async () => {
        try {
            if (subject.trim() == "") {
                toast.error(validationMessage("Subject", "Add"));
                return;
            }
            if (greetings.trim() == "") {
                toast.error(validationMessage("Greetings", "Add"));
                return;
            }
            if (message.trim() == "") {
                toast.error(validationMessage("Message", "Add"));
                return;
            }

            setAddEvent(1);

            let data = {
                email,
                subject,
                greetings,
                message,
                imageSource,
                regards,
                position,
                link,
                messageForLink,
            };

            let res = await sendMail(data);
            console.log("res", res);
            if (res === 200) {
                toast.success(messages.mailSuccess);
                props.handleClose();
                setAddEvent(2);
            } else if (res === 500) {
                toast.error(messages.mailDataValidation);
                props.handleClose();
                setAddEvent(0);
            } else {
                toast.error(messages.mailError);
                props.handleClose();
                setAddEvent(0);
            }
        } catch (e) {
            toast.error(messages.mailDataPostError);
            props.handleClose();
            console.log("error of add collection data", e);
        }
    };

    return addEvent === 1 ? (
        <div className="spinner_div">
            <div className="spinner">
                <div className="spinner-item"></div>
                <div className="spinner-item"></div>
                <div className="spinner-item"></div>
            </div>
        </div>
    ) : addEvent === 2 ? (
        "Completed"
    ) : (

        <Grid container>
            <Grid item xs={12}>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    className={classes.mainContent}
                    spacing={1}
                >
                    <div className="outerrrBorder" style={{ width: "76%" }}>
                        <Grid item xs={12} className="modalInput addCollectionInputs">
                            <label htmlFor="InputSubject" className="required">
                                Subject
                            </label>
                            <input
                                id="InputSubject"
                                className="form-control"
                                placeholder="Please Enter Subject"
                                value={subject}
                                onChange={(event) => setSubject(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} className="modalInput addCollectionInputs">
                            <label htmlFor="InputGreetings" className="required">
                                Greetings
                            </label>
                            <input
                                id="InputGreetings"
                                className="form-control"
                                placeholder="Please Enter Greeting"
                                value={greetings}
                                onChange={(event) => setGreetings(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} className="modalInput addCollectionInputs">
                            <label htmlFor="InputMessage" className="required">
                                Message
                            </label>
                            <textarea
                                id="InputMessage"
                                className="form-control"
                                placeholder="Please Enter Your Message Here"
                                value={message}
                                rows={24}
                                onChange={(event) => setMessage(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} className="modalInput addCollectionInputs">
                            <label htmlFor="InputImage">Image Source (optional)</label>
                            <input
                                id="InputImage"
                                className="form-control"
                                placeholder="Please Image Source"
                                value={imageSource}
                                onChange={(event) => setImageSource(event.target.value)}
                            />
                        </Grid>

                    </div>
                    <Grid item xs={12} align="center">
                        <Button
                            variant="contained"
                            color="inherit"
                            id="button-submit"
                            className="textCapitalize"
                            onClick={() => sendMailHandler()}
                        >
                            Send E-Mail
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    );
}

export default withStyles(styles)(Newsletter);
