import React from 'react';

import { Card } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import colors from 'assets/theme/base/colors';
import CircularProgress from '@mui/material/CircularProgress';
import { contentLoader } from 'helpers/getters';

const SatisfactionRate = (props) => {
	const { gradients } = colors;


	return (
		<Card sx={{
			background: '#FFFFFF',
			height: '300px',
			borderRadius: '37px',
			border: '1px solid #323A45',
			boxShadow: '4px 4px 4px 2px rgba(0, 0, 0, 0.25)',
		}}>
			<VuiBox display='flex' flexDirection='column'>
				<VuiTypography variant='lg'
					sx={{
						fontStyle: 'normal',
						fontSize: '25px',
						fontWeight: '600',
						lineHeight: '29px',
						display: 'flex',
						alignItems: 'center',
						textAlign: 'center',
						color: '#323A45',
						margin: '10px auto',
						borderBottom: '1px solid #8A959E',
					}}
					color='' mb='10px'>
					Total Collections
				</VuiTypography>

				<VuiBox sx={{ alignSelf: 'center', justifySelf: 'center', zIndex: '-1' }}>
					<VuiBox sx={{ position: 'relative', display: 'flex' }}>
						<CircularProgress variant='determinate' value={100} size={170} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} color='info' />
						<VuiBox
							sx={{
								top: 0,
								left: 0,
								bottom: 0,
								right: 0,
								position: 'absolute',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}
						>
							<VuiTypography color='#323A45'
								variant='d5'
								fontWeight='600'
								sx={({ breakpoints }) => ({
									[breakpoints.only('xl')]: {
										fontSize: '35px',
										fontWeight: '600'
									}
								})}>

								{props?.collection}
							</VuiTypography>

						</VuiBox>
					</VuiBox>
				</VuiBox>
				{/* <VuiBox
					sx={({ breakpoints }) => ({
						width: '90%',
						padding: '18px 22px',
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'row',
						height: '82px',
						mx: 'auto',
						borderRadius: '20px',
						background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
						transform: 'translateY(-90%)',
						zIndex: '1000'
					})}>

					<VuiBox
						flexDirection='column'
						display='flex'
						justifyContent='center'
						alignItems='center'
						sx={{ minWidth: '80px' }}>
						<VuiTypography color='white' variant='h3'>

							{props?.collection}
						</VuiTypography>

					</VuiBox>

				</VuiBox> */}
			</VuiBox>
		</Card>
	);
};

export default SatisfactionRate;
