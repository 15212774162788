

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { useHistory } from "react-router-dom";
import Footer from "../Footer";
import "./../../../App.css"

import VuiButton from "components/VuiButton";
import { useEffect, useState } from "react";
import eventEmitter from "../../../events/events";
import { getCollections } from "apiServices";
import { handleImport } from "helpers/setters";
import { checkImportStatus } from "apiServices";
import successTick from "./../../../assets/images/success.png"
import Grid from "@material-ui/core/Grid";
// import { walletConnect } from "../authentication/connectWallet";
import Modal from "../authentication/connectWallet/Modal/Modal";
// import { walletConnect } from "helpers/setters";

function Home() {
  const [openForm, setOpenForm] = useState(false)
  const [openDeleteForm, setOpenDeleteForm] = useState(false)
  const [visiblity, setVisibility] = useState("hide")

  const [collections, setCollections] = useState([])
  const [reloadCollections, setReloadCollections] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const history = useHistory()

  const handleReloadCollection = () => {
    setReloadCollections(!reloadCollections)
    setVisibility("hide")
  }

  eventEmitter.removeAllListeners("reloadCollections")
  eventEmitter.on("reloadCollections", handleReloadCollection)

  const handleClose = () => {
    setOpenForm(false)
    setOpenDeleteForm(false)
    console.log("fff")
  };

  eventEmitter.on("reload", () => {
    if (localStorage.getItem(connectedrole) !== undefined && localStorage.getItem(connectedrole) !== null && localStorage.getItem(connectedrole) !== "undefined" && localStorage.getItem(connectedrole) !== "null") {
      history.push('/dashboard')
    }
  })


  useEffect(() => {
    console.log("reload cont", reloadCollections)
    const fetchCollections = async () => {
      let coll = await getCollections()
      if (coll && coll?.results?.length > 0)
        setCollections(coll?.results[0])
    }
    fetchCollections()
  }, [reloadCollections])

  eventEmitter.removeAllListeners("reload")
  eventEmitter.on("reload", handleReloadCollection)



  return (

    <>
      <div className="home">
        {/* {showModal && ( */}
        <Modal></Modal>
        {/* )} */}
        {/* <DashboardNavbar /> */}
        {/* <VuiBox py={3}>
          <Grid container >
            <Grid item xs={12} justifyContent="center" >
              <Grid container direction="row" spacing={2}>

                <Grid item xs={12} align="center">
                  <VuiButton variant="gradient" color="info" size="medium" onClick={() => {
                    setShowModal(true)
                  }}>Connect Wallet</VuiButton>
                </Grid>

              </Grid>
            </Grid>
          </Grid>

          <VuiBox>
            <div className={visiblity}> <img src={successTick}></img>
              Action Completed Successfully</div>
          </VuiBox>
        </VuiBox> */}
      </div>
      <Footer />
    </>
  );
}

export default Home;
