export const networks = {
    "Binance Testnet": {
        chainId: 97,
        rpcs: "https://data-seed-prebsc-2-s2.binance.org:8545"
    },
    "Binance Mainnet": {
        chainId: 56,
        rpcs: "https://bsc-dataseed.binance.org/"
    },
    "Polygon Amoy": {
        chainId: 80002,
        rpcs: "https://polygon-amoy.g.alchemy.com/v2/_8umjnEsy_mMRsGDOowAguyPxCbcaIC_"
    },
    "Polygon": {
        chainId: 137,
        rpcs: "https://polygon-mainnet.g.alchemy.com/v2/_8umjnEsy_mMRsGDOowAguyPxCbcaIC_"
    }
}

export const getNetworkNameByChain = {
    97: "Binance Testnet",
    56: "Binance Mainnet",
    80002: "Polygon Amoy",
    137: "Polygon"
}