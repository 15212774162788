import { capitalizeWords } from "helpers/getters";

export const messages = {
  loginSuccess: capitalizeWords("Logged In successfully"),
  loginError: capitalizeWords("Error while login"),
  addCollectionSuccess: capitalizeWords("Collection added successfully"),
  addCollectionError: capitalizeWords("Error while adding collection"),
  addProductSuccess: capitalizeWords("Item added successfully"),
  addProductError: capitalizeWords("Error while adding Item"),
  userDenied: capitalizeWords("User denied request"),
  assignProductSuccess: capitalizeWords("Item assigned successfully"),
  assignProductError: capitalizeWords("Error while assigning Item"),
  removeProductSuccess: capitalizeWords("Item removed successfully"),
  removeProductError: capitalizeWords("Error while removing assigned Item"),
  notAnOwner: capitalizeWords("Not an Owner"),
  logoutSuccess: capitalizeWords("Logged out successfully"),
  signMessage: capitalizeWords("This is to verify if you are the real address to send this request"),
  requestAcceptSuccess: capitalizeWords("Redeem request accepted successfully"),
  requestAcceptError: capitalizeWords("Error while accepting redeem request"),
  tryAgain: capitalizeWords("Error encountered, Please try again after sometime"),
  importSuccess: capitalizeWords("Import Request sent successfully"),
  colDelSuccess: capitalizeWords("Imported Collection removed successfully"),
  alreadyImported: capitalizeWords("Token Already Imported"),
  inProcessing: capitalizeWords("One Request is in processing, Please wait before requesting again"),
  updateCollectionSuccess: capitalizeWords("Collection updated successfully"),
  updateCollectionError: capitalizeWords("Error while updating collection"),
  collectionNotOwned: capitalizeWords("You don't own this collection"),
  updateReqSent: capitalizeWords("Update request send successfully"),
  somethingsWrong: capitalizeWords("Something went wrong"),
  collectionImportIssue: capitalizeWords("You need to import Collection first, or if already imported then try reimporting"),
  invalidDateTime: capitalizeWords("Invalid Date or time"),
  delSuccess: capitalizeWords("Deleted successfully"),
  invalidEmail: capitalizeWords("Invalid Email Id"),
  mailSuccess: capitalizeWords("Mail Successfully Sent"),
  mailError: capitalizeWords("Mail not in mailbox! Please Enter Valid Email"),
  mailDataPostError: capitalizeWords("Please Check All Fields Again"),
  mailDataValidation: capitalizeWords("Incomplete Data in Fields! Please Check"),
  ContractNetworkError: capitalizeWords("Either contract address is not valid or not deployed on selected network"),
  invalidAddress: capitalizeWords("Invalid Address"),
  invalidChar: capitalizeWords("Invalid character"),
  noMailSelected: capitalizeWords("Please select atleast one valid mail"),
  addedToHot: capitalizeWords("Added to hot collection"),
  removedFromHot: capitalizeWords("removed from hot collection"),
  addedToBlock: capitalizeWords("collection blocked successfully"),
  removedFromBlock: capitalizeWords("collection unblocked successfully"),
  importInProgress: capitalizeWords("Import is in progress, can't edit"),
  requestRejectSuccess: capitalizeWords("Redeem request rejected successfully"),
  requestRejectError: capitalizeWords("Error while rejecting redeem request"),
  delAssignedProductError: capitalizeWords("Please Unassign the item and try deleting it again"),
  blockedCollection: capitalizeWords("Collection is blocked"),
  refreshMessage: capitalizeWords("Refresh is in Queue, it may take few minutes"),
  productLimitReached: capitalizeWords("Item Limit Reached"),
  previousLimitError: capitalizeWords("Item Limit cannot be less than previous limit"),
  startEndIndexError: capitalizeWords("Start index should be less than end index"),
  updateProductSuccess: capitalizeWords("Item updated successfully"),
  updateProductError: capitalizeWords("Error while upating Item"),
};

export const validationMessage = (param, type) => {
  return "Please " + type + " " + param;
};
