// @mui material components
import Card from "@mui/material/Card";
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Footer from "../Footer";
import Table from "../Tables/Table";
import "./../../../App.css";
import VuiButton from "components/VuiButton";
import { useEffect, useState, useRef } from "react";
import CircularUnderLoad from "components/common/spinner";
import toast from "react-hot-toast";
import { messages } from "config/messages";
import { handleDelete } from "helpers/setters";
import deleteIcon from "../../../assets/images/logos/delete.png";
import editIcon from "../../../assets/images/logos/edit.png";
import isHot from "../../../assets/images/logos/isHot.png";
import isNotHot from "../../../assets/images/logos/notHot.png";
import { supportedNetworks } from "config/supportedNetworks";
import ConfirmationModal from "components/common/ConfirmationModal";
import { blockUnblockCollection } from "apiServices";
import { getFilteredCollectionsOwner } from "apiServices";
import { networks } from "config/rpcs";
import { ownedCollectionFilterFields } from "helpers/constants";
import { ownedCollectionTableColumns } from "helpers/constants";
import { isValidAddress } from "helpers/getters";
import Pagination, { bootstrap5PaginationPreset } from "react-responsive-pagination";
import { capitalizeWords } from "helpers/getters";
import { pageLimit } from "helpers/constants";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Loader from "components/common/Loader";
import { CSVLink } from "react-csv";
import { connectedrole } from "config/localStorageNames";
import { handleCopyToClipboard } from "helpers/getters";
import { account } from "config/localStorageNames";

function OwnedCollections(props) {
  const [rows, setRows] = useState([]);
  const [collections, setCollections] = useState([]);
  const [reloadCollections, setReloadCollections] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchField, setSearchField] = useState("ALL");
  const [searchedText, setSearchedText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [counts, setCounts] = useState();
  const [loader, setLoader] = useState(false);
  const [allCollections, setAllCollections] = useState([]);
  const [searched, setSearched] = useState(false);
  const [toggleSearch, setToggleSearch] = useState(false);

  const nextPage = (pageNumber) => {
    console.log("nexttt");
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (localStorage.getItem(connectedrole) !== "owner") {
      window.location.href = "/";
    }
  }, [reloadCollections]);

  useEffect(() => {
    const fetchCollections = async () => {
      setLoading(true);
      let coll = await getFilteredCollectionsOwner({
        page: currentPage,
        limit: pageLimit,
        owner: localStorage.getItem(account)?.toLowerCase(),
      });
      let count = coll?.count;
      setCounts(count);
      if (coll && coll?.results?.length > 0) {
        setCollections(coll?.results[0]);
      }

      let req = {
        page: 1,
        limit: 100000,
      };

      let exportableItems = await getFilteredCollectionsOwner(req);

      if (exportableItems && exportableItems?.results?.length > 0) {
        setAllCollections(exportableItems?.results[0]);
      }
      setLoading(false);
    };
    fetchCollections();
    }, []);

  useEffect(() => {
    if (searchField === "ALL" && searched) {
      console.log("here", searchField);
      setSearchedText("");
      setSearched(false);
    }
    handleSearch(searched);
  }, [searched, toggleSearch, currentPage, reloadCollections]);

  useEffect(() => {
    let _rows =
      collections?.length > 0 && collections !== "none"
        ? collections?.map((c, i) => {
            return {
              Collection: (
                <VuiTypography variant="button" mb="4px">
                  {c?.collectionName}{" "}
                  <VuiTypography
                    variant="input"
                    mb="4px"
                    className="copy-icon"
                    onClick={() => handleCopyToClipboard(c?.collectionAddress)}
                  >
                    (
                    {c?.collectionAddress?.slice(0, 5) +
                      "..." +
                      c?.collectionAddress?.slice(38, 42)}
                    )
                    <ContentCopyIcon />
                  </VuiTypography>
                </VuiTypography>
              ),
              Email: (
                <VuiTypography variant="button" mb="4px" style={{ textTransform: "lowercase" }}>
                  {c?.email}
                </VuiTypography>
              ),
              "Chain ID": (
                <VuiTypography variant="button" mb="4px">
                  {c?.chainId}
                </VuiTypography>
              ),
              "Product Limit": (
                <VuiTypography variant="button" mb="4px">
                  {c?.productLimit}
                </VuiTypography>
              ),
              "Total Products": (
                <VuiTypography variant="button" mb="4px">
                  {c?.totalProducts}
                </VuiTypography>
              ),
            };
          })
        : [];

    setRows(_rows);
  }, [collections, reloadCollections]);

  const handleSearch = async () => {
    setLoading(true);
    let req = {
      page: currentPage,
      limit: pageLimit,
      owner: localStorage.getItem(account)?.toLowerCase(),
    };
    let coll;
    if (searched && searchField === "collectionAddress") {
      if (!isValidAddress(searchedText) && searchedText !== "") {
        toast.error(messages.invalidAddress);
        setLoading(false);
        return;
      }
      req = {
        ...req,
        collectionAddress: searchedText,
      };
    } else if (searched && searchField === "collectionName") {
      req = {
        ...req,
        collectionName: searchedText,
      };
    } else if (searched && searchField === "email") {
      req = {
        ...req,
        email: searchedText,
      };
    } else if (searched && searchField === "isImported") {
      req = {
        ...req,
        isImported: searchedText,
      };
    } else if (searched && searchField === "chainId") {
      req = {
        ...req,
        chainId: networks[searchedText]?.chainId,
      };
    } else if (searched && searchField === "isHotCollection") {
      req = {
        ...req,
        isHotCollection: searchedText,
      };
    }

    coll = await getFilteredCollectionsOwner(req);

    req.page = 1;
    req.limit = 10000;

    let exportableItems = await getFilteredCollectionsOwner({ ...req });

    if (exportableItems && exportableItems?.results?.length > 0) {
      setAllCollections(exportableItems?.results[0]);
    }

    // let coll = await getFilteredCollectionsOwner({})
    if (coll && coll?.results?.length > 0) {
      setCollections(coll?.results[0]);
      setCounts(coll?.count);
    } else {
      setCollections([]);
      setCounts(0);
    }

    setLoading(false);
    // }
  };

  const getSearchOptions = () => {
    if (searchField === "isBlocked" || searchField === "isHotCollection") {
      return ["true", "false"];
    } else if (searchField === "chainId") {
      return supportedNetworks;
    } else return [];
  };

  const showSelect = () => {
    if (
      searchField == "isBlocked" ||
      searchField == "chainId" ||
      searchField == "isHotCollection"
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="collection__main">
      {loader ? <Loader /> : ""}

      <VuiBox alignItems="center" mb="22px">
        <VuiTypography variant="lg" color="black">
          <div className="field_Bar">
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                if (searchedText === "") {
                  return;
                }
                setCurrentPage(1);
                setSearched(true);
                setToggleSearch(!toggleSearch);
              }}
            >
              <div className="fields_div">
                <select
                  className="form-select"
                  value={searchField}
                  onChange={(e) => {
                    setSearchedText("");
                    setSearched(false);
                    setSearchField(e.target.value);
                  }}
                  aria-label="Select Option"
                >
                  {ownedCollectionFilterFields &&
                    ownedCollectionFilterFields?.map((field, i) => {
                      return (
                        <option key={i} value={field?.value} selected>
                          {field?.key}
                        </option>
                      );
                    })}
                </select>

                {searchField !== "ALL" && searchField !== "" && (
                  <>
                    {showSelect() && (
                      <select
                        className="form-select search_field"
                        value={searchedText}
                        onChange={(e) => {
                          setSearchedText(e.target.value);
                          setSearched(false);
                        }}
                        aria-label="Select Option"
                      >
                        <option value="" selected>
                          Select Option
                        </option>
                        {getSearchOptions() && getSearchOptions()?.length > 0
                          ? getSearchOptions().map((o, i) => {
                              return (
                                <option key={i} value={o}>
                                  {o?.toUpperCase()}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    )}
                    {!showSelect() && (
                      <input
                        className="form-control search_field"
                        id="Input1"
                        value={searchedText}
                        onChange={(e) => {
                          setSearched(false);
                          setSearchedText(e.target.value.replace(/\s/g, ""));
                        }}
                        placeholder="Enter Search"
                      />
                    )}{" "}
                  </>
                )}
                <VuiButton
                  className="logoutBtn"
                  size="medium"
                  disabled={searchedText === "" && searchField !== "ALL"}
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentPage(1);
                    setSearched(true);
                    setToggleSearch(!toggleSearch);
                  }}
                >
                  Search
                </VuiButton>
              </div>
              {/* <VuiButton
                variant="gradient"
                color="info"
                size="medium"
                onClick={() => setOpenForm(true)}
              >
                {" "}
                Add Collection
              </VuiButton> */}
            </form>
          </div>
        </VuiTypography>
      </VuiBox>

      {/* <DashboardNavbar /> */}
      <VuiBox className="contentBox" py={3}>
        <VuiBox mb={3}>
          <Card className="contentTable">
            <VuiBox>
              <Table columns={ownedCollectionTableColumns} rows={rows} />
            </VuiBox>
            {loading ? (
              <CircularUnderLoad />
            ) : collections?.length === 0 ? (
              <VuiTypography variant="lg" className="NoData">
                No Collection Found
              </VuiTypography>
            ) : (
              ""
            )}
          </Card>

          <CSVLink
            data={allCollections}
            filename={"CollectionList.csv"}
            headers={[
              {
                label: "Collection Address",
                key: "collectionAddress",
              },
              {
                label: "Collection Name",
                key: "collectionName",
              },
              {
                label: "Email",
                key: "email",
              },
              {
                label: "Chain Id",
                key: "chainId",
              },
              {
                label: "Hot",
                key: "isHotCollection",
              },
              {
                label: "Block",
                key: "isBlocked",
              },
            ]}
          >
            <VuiButton variant="gradient" color="info" size="small">
              {" "}
              Export
            </VuiButton>
          </CSVLink>
          {Math.ceil(counts / pageLimit) > 1 && (
            <Pagination
              {...bootstrap5PaginationPreset}
              current={currentPage}
              total={Math.ceil(counts / pageLimit)}
              onPageChange={nextPage}
            />
          )}
        </VuiBox>
      </VuiBox>

      <Footer />
    </div>
  );
}

export default OwnedCollections;
