

import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard React context
import { useVisionUIController, setLayout } from "context";

import Logo from '../../../../assets/logoDark.png'

function PageLayout({ children }) {
  const [, dispatch] = useVisionUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "page");
  }, [pathname]);

  return (<>
    <VuiBox
      width="100vw"
      maxWidth="100%"
      height="100vh"
      className='AdminScreenText'
      // minHeight="100vh"
      sx={({ functions: { tripleLinearGradient }, palette: { gradients } }) => ({
        overflow: "hidden",
        backgroundColor: '#fff',
        position: "relative",
      })}
      >
      <div className="loginHeader">
        <img src={Logo}/>
      </div>
      {children}
    </VuiBox>
    </>);
}

// Setting default values for the props for PageLayout
PageLayout.defaultProps = {
  background: "default",
};

// Typechecking props for the PageLayout
PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageLayout;
