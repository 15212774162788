import React from 'react';
import './style.css';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';


function ConfirmationModal(props) {
  return (
    <div className="modal-container">
      <div className='modal-body'>
        <div className='modal-header'>
          <h4>{props.header}</h4>
          <span className="close-icon" onClick={props.handleClose}><CloseIcon /></span>
        </div>
        <div className="modal-content">
          <span>Are you sure you want to continue?</span>
          <div className='btn-group'>
            <Button variant="contained" className="confirm" color="success" onClick={props.handleConfirm}>
              Confirm
            </Button>
            <Button variant="outlined" className='cancel' color="error" onClick={props.handleClose}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationModal