/** 
  All of the routes for the Vision UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Vision UI Dashboard React layouts
import Dashboard from "./components/pages/dashboard/index";
import Tables from "./components/pages/collections/index";
import { IoStatsChart } from "react-icons/io5";
import { IoHome } from "react-icons/io5";
import { checkIfIsUserOrAdmin } from "helpers/getters";
import Home from "components/pages/Home";
import DashboardIcon from "./assets/Dashboard.png";
import collectionIcon from "./assets/collection.png";
import coinIcon from "./assets/coinIcon.png";
import reqIcon from "./assets/req.png";
import redReqIcon from "./assets/reqsIcon.png";

let currRole = checkIfIsUserOrAdmin();

export const defaultRoutes = [
  // {
  //   type: "collapse",
  //   name: "Home",
  //   key: "",
  //   route: "/",
  //   icon: <IoHome size="15px" color="inherit" />,
  //   component: Home,
  //   noCollapse: true,
  // },
];
export const userRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "",
    route: "/",
    icon: <img src={DashboardIcon} alt="dashboard" />,
    component: Dashboard,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Items",
    key: "items",
    route: "/items",
    icon: <img src={collectionIcon} alt="Requests" />,
    component: Tables,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Redeem Requests",
    key: "redeemRequests",
    route: "/redeemRequests",
    icon: <img src={redReqIcon} alt="Requests" />,
    component: Tables,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Owned Collections",
    key: "ownedCollections",
    route: "/ownedCollections",
    icon: <img src={redReqIcon} alt="Requests" />,
    component: Tables,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Assign Products",
  //   key: "assignProducts",
  //   route: "/assignProducts",
  //   icon: <img src={collectionIcon} alt="Requests" />,

  //   noCollapse: true,
  // },
];

export const adminRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/",
    icon: <img src={DashboardIcon} alt="dashboard" />,
    component: Dashboard,
    noCollapse: true,
  },
  currRole === "admin"
    ? {
        type: "collapse",
        name: "Collections",
        key: "collections",
        route: "/collections",
        icon: <img src={coinIcon} alt="collection" />,
        component: Tables,
        noCollapse: true,
      }
    : {},
  // currRole === "admin"
  //   ? {
  //       type: "collapse",
  //       name: "Items",
  //       key: "items",
  //       route: "/items",
  //       icon: <img src={collectionIcon} alt="Requests" />,
  //       component: Tables,
  //       noCollapse: true,
  //     }
  //   : {},
  currRole === "admin"
    ? {
        type: "collapse",
        name: "Mail",
        key: "sendMail",
        route: "/mail",
        icon: <img src={reqIcon} alt="mail" />,
        component: Tables,
        noCollapse: true,
      }
    : {},
  currRole === "admin"
    ? {
        type: "collapse",
        name: "FAQs",
        key: "addFaq",
        route: "/faqList",
        icon: <img src={reqIcon} alt="addFaq" />,
        component: Tables,
        noCollapse: true,
      }
    : {},

  // currRole === "admin"
  //   ? {
  //     type: "collapse",
  //     name: "Newsletter",
  //     key: "newsletter",
  //     route: "/newsletter",
  //     icon: <img src={reqIcon} alt="mail" />,
  //     component: Tables,
  //     noCollapse: true,
  //   }
  //   : {},

  // currRole === "admin"
  //   ? {
  //     type: "collapse",
  //     name: "Subscribers",
  //     key: "subscribers",
  //     route: "/subscribers",
  //     icon: <img src={reqIcon} alt="mail" />,
  //     component: Tables,
  //     noCollapse: true,
  //   }
  //   : {},
];
