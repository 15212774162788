import { Link, useHistory } from "react-router-dom";
import deleteIcon from "../../../assets/images/logos/delete.png";
import editIcon from "../../../assets/images/logos/edit.png";
import { deleteFaq, getFaqList } from "../../../apiServices";
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Footer from "../Footer";
import Table from "../Tables/Table";
import "./../../../App.css";
import VuiButton from "components/VuiButton";
import { useEffect, useState } from "react";
import eventEmitter from "../../../events/events";
import CircularUnderLoad from "components/common/spinner";
import { faqTableColumns } from "helpers/constants";
import { toast } from "react-hot-toast";
import { connectedrole } from "config/localStorageNames";

function FaqList(props) {
  const [rows, setRows] = useState([]);
  const [faqData, setFaqData] = useState([]);
  const [reloadFaq, setReloadFaq] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toggleNotesBtn, setToggleNotesBtn] = useState("disable-notes");
  const history = useHistory();
  const handleReloadFaq = () => {
    setReloadFaq(!reloadFaq);
  };

  const handleButtonToggle = () => {
    if (toggleNotesBtn === "active-notes") {
      setToggleNotesBtn("disable-notes");
    } else {
      setToggleNotesBtn("active-notes");
    }
  };
  const faqDeleteHandler = async (faqId) => {
    let id = { faqId };
    let res = await deleteFaq(id);
    if (res.statusCode == 200) {
      setTimeout(() => {
        toast.success("FAQ Deleted");

        window.location.reload();
        history.push("/faqList");
      }, 1000);
    } else if (res.statusCode == 404) {
      setTimeout(() => {
        toast.error("Invalid Data");
      }, 1000);
    } else if (res.statusCode == 406) {
      setTimeout(() => {
        toast.error("Role Invalid");
      }, 1000);
    } else {
      setTimeout(() => {
        toast.error("FAQ Saving Error!");
      }, 1000);
    }
  };
  eventEmitter.removeAllListeners("reload");
  eventEmitter.on("reload", handleReloadFaq);

  useEffect(() => {
    if (localStorage.getItem(connectedrole) !== "admin") {
      window.location.href = "/admin";
    }
  }, [reloadFaq]);

  useEffect(() => {
    const getFaq = async () => {
      setLoading(true);
      let Faq = await getFaqList();

      setFaqData(Faq.data);
      console.log("FAQ", Faq.data);
      setLoading(false);
    };
    getFaq();
  }, [reloadFaq]);

  useEffect(() => {
    let _rows =
      faqData?.length > 0 && faqData !== "none"
        ? faqData?.map((c, i) => {
            return {
              "S NO.": i + 1,
              Questions: (
                <VuiTypography
                  variant="button"
                  color="white"
                  fontWeight="medium"
                  mb="4px"
                  className=""
                  title={c?.question}
                  style={{ textTransform: "none" }}
                >
                  {c?.question ? c?.question.slice(0, 50) : ""}
                </VuiTypography>
              ),
              Answers: (
                <VuiTypography
                  variant="button"
                  color="white"
                  fontWeight="medium"
                  mb="4px"
                  className="popover-container"
                  style={{ textTransform: "none" }}
                  title={c.answer}
                >
                  {c?.answer ? c?.answer.slice(0, 50) : ""}
                </VuiTypography>
              ),
              Update: (
                <VuiTypography
                  className="btncont"
                  variant="button"
                  color="white"
                  fontWeight="medium"
                  mb="4px"
                  title="Update FAQ"
                >
                  <Link to={`/updateFaq/${c._id}`}>
                    <VuiButton className="edit-btnn whiteBg" disabled={!c?.question ? true : false}>
                      <img src={editIcon} alt="edit" />
                    </VuiButton>{" "}
                  </Link>
                </VuiTypography>
              ),
              Delete: (
                <VuiTypography
                  className="btncont"
                  variant="button"
                  color="white"
                  fontWeight="medium"
                  mb="4px"
                  title="Delete FAQ"
                >
                  <VuiButton
                    className="edit-btnn whiteBg"
                    onClick={() => {
                      faqDeleteHandler(c?._id);
                    }}
                    disabled={!c?.answer ? true : false}
                  >
                    <img src={deleteIcon} alt="delete" />
                  </VuiButton>
                </VuiTypography>
              ),
            };
          })
        : [];
    setRows(_rows);
  }, [faqData, reloadFaq]);

  return (
    <div className="collection__main">
      {/* <DashboardNavbar /> */}
      <div className="field_Bar">
        <form action="">
          <div className="fields_div"></div>
          <VuiButton
            variant="gradient"
            color="info"
            size="medium"
            onClick={() => history.push("/addFaq")}
          >
            Add FAQ
          </VuiButton>
        </form>
      </div>
      <VuiBox className="contentBox " py={3}>
        <VuiBox mb={3}>
          <Card className="contentTable">
            <VuiBox
              sx={{
                "& th": {
                  borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                    `${borderWidth[1]} solid ${grey[700]}`,
                },
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                      `${borderWidth[1]} solid ${grey[700]}`,
                  },
                },
              }}
            >
              <Table columns={faqTableColumns} rows={rows} />
            </VuiBox>
            {loading ? (
              <CircularUnderLoad />
            ) : faqData?.length === 0 ? (
              <VuiTypography variant="lg" className="NoData">
                No Record Found
              </VuiTypography>
            ) : (
              ""
            )}
          </Card>
        </VuiBox>
      </VuiBox>
      <div className={`sidebarNotesBtn ${toggleNotesBtn === "active-notes" ? "active" : ""}`}>
        <div className="toggleNotesBtn" onClick={handleButtonToggle}>
          Note
        </div>
        <div className={toggleNotesBtn}>
          Collection which you are adding should have Metadata URI set for each Token.
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FaqList;
