import React from 'react';
import { Card, Stack } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import colors from 'assets/theme/base/colors';
import linearGradient from 'assets/theme/functions/linearGradient';
import CircularProgress from '@mui/material/CircularProgress';
import { contentLoader } from 'helpers/getters';


function ReferralTracking(props) {


	return (
		<Card
			sx={{
				background: '#FFFFFF',
				height: '300px',
				borderRadius: '37px',
				border: '1px solid #323A45',
				boxShadow: '4px 4px 4px 2px rgba(0, 0, 0, 0.25)'
			}}>
			<VuiBox sx={{ width: '100%' }}>
				<VuiBox
					display='flex'
					alignItems='center'
					justifyContent='space-beetween'
					sx={{ width: '100%' }}
				>
					<VuiTypography variant='lg' sx={{
						fontStyle: 'normal',
						fontSize: '25px',
						fontWeight: '600',
						lineHeight: '29px',
						display: 'flex',
						alignItems: 'center',
						textAlign: 'center',
						color: '#323A45',
						margin: '10px auto',
						borderBottom: '1px solid #8A959E',
					}} >
						{props.title}
					</VuiTypography>

				</VuiBox>
				<VuiBox
					display='flex'
					sx={({ breakpoints }) => ({
						[breakpoints.up('xs')]: {
							flexDirection: 'column',
							gap: '16px',
							justifyContent: 'center',
							alignItems: 'center'
						},
						[breakpoints.up('md')]: {
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'center'
						}
					})}>

					<VuiBox sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
						<CircularProgress
							variant='determinate'
							value={props.percentage}
							size={170}
							color={props.color}
							sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
						/>
						<VuiBox
							sx={{
								top: 0,
								left: 0,
								bottom: 0,
								right: 0,
								position: 'absolute',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}>
							<VuiBox display='flex' flexDirection='column' justifyContent='center' alignItems='center'>

								<VuiTypography
									color='#323A45'
									variant='d5'
									fontWeight='600'
									sx={({ breakpoints }) => ({
										[breakpoints.only('xl')]: {
											fontSize: '35px'
										}
									})}>
									{props?.product}
								</VuiTypography>

							</VuiBox>
						</VuiBox>
					</VuiBox>
				</VuiBox>
			</VuiBox>
		</Card>
	);
}

export default ReferralTracking;
