import { exportInstance } from "apiServices"
import { ethers } from "ethers"
import erc721Abi from "./../config/abis/erc721.json"
import { account, connectedrole } from "config/localStorageNames";
import { toast } from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";

export const checkIfIsUserOrAdmin = () => {
    if (localStorage.getItem(account) !== undefined && localStorage.getItem(account) !== null && localStorage.getItem(connectedrole) === "owner") {
        return "owner"
    }
    else if (localStorage.getItem(connectedrole) !== undefined && localStorage.getItem(connectedrole) !== null && localStorage.getItem(connectedrole) === "admin") {
        return "admin"
    }
    else {
        return "none"
    }
}

export const checkIfOwned = async (collectionAddress, currOwner, networkId) => {
    try {
     
        let contract = await exportInstance(collectionAddress, erc721Abi, networkId)
        let owner = await contract.owner()
        console.log("ownerrr", owner)
        if (currOwner?.toLowerCase() !== owner?.toLowerCase()) {
            return false
        }
        return true
    }
    catch (e) {
        console.log("err", e)
        return false
    }
}

export const isValidAddress = (address) => {
    const verify = ethers.utils.isAddress(address)
    if (verify == true)
        return true
    else
        return false
}





export const capitalizeWords = (str) => {
    return str
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export const allnumeric = (inputtxt) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex
    if (!Number(inputtxt)) {
        return false
    }
    if (inputtxt === '' || re.test(inputtxt)) {
        // alert('Your Registration number has accepted....');
        return true
    }
    else {
        // alert('Please input numeric characters only');
        return false
    }
}


export const handleCopyToClipboard = async copyMe => {
    console.log("copy me")
    try {
        await navigator.clipboard.writeText(copyMe);
        toast.success('Copied!');
    } catch (err) {
        console.log("err", err)
        toast.error('Failed to copy!');
    }
};

export const contentLoader = () => {
    return (< ThreeDots
        height="30"
        width="30"
        radius="9"
        color="#232323"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}
        }
        wrapperClassName=""
        visible={true}
    />)
}