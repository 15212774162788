import React, { useEffect, useState } from "react";
import ImageResizer from "react-image-file-resizer";
import { withStyles, MenuItem } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { teal, grey } from "@material-ui/core/colors";
import "./collection.css";
import { updateCollectionDetails } from "apiServices";
import { exportInstance } from "apiServices";
import erc721Abi from "./../../../config/abis/erc721.json";
import "./../../../App.css";
import toast from "react-hot-toast";
import { validationMessage } from "config/messages";
import { messages } from "config/messages";
import { supportedNetworks } from "config/supportedNetworks";
import { networks } from "config/rpcs";
import { getNetworkNameByChain } from "config/rpcs";
import closeIcon from "../../../assets/images/small-logos/close-btn.png";
import AddCollectionPreview from "./addCollectionPreview";
import validator from "validator";
import eventEmitter from "../../../events/events";
import { allnumeric } from "helpers/getters";
import Loader from "components/common/Loader";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    overFlow: "hidden",
  },
  primaryColor: {
    color: teal[500],
  },
  secondaryColor: {
    color: grey[700],
  },

  padding: {
    padding: 0,
  },
  mainHeader: {
    backgroundColor: "rgba(0, 122, 219, 0.8)",
    padding: 20,
    alignItems: "center",
  },
  mainContent: {
    padding: 10,
    margin: 0,
  },
  secondaryContainer: {
    padding: "20px 25px",
    backgroundColor: grey[200],
  },
});

function UpdateCollection(props) {
  const { classes } = props;
  const [collectionAddress, setCollectionAddress] = useState("");
  const [owner, setOwner] = useState("");
  const [collectionName, setCollectionName] = useState("");
  const [addEvent, setAddEvent] = useState(0);
  const [logoImage, setLogoImage] = useState();
  const [network, setNetwork] = useState("");
  const [coverImage, setCoverImage] = useState();
  const [email, setEmail] = useState();
  const [productLimit, setProductLimit] = useState(props?.selectedRowData?.productLimit);
  const [isEdit1, setIsEdit1] = useState(true);
  const [isEdit2, setIsEdit2] = useState(true);
  const [previewData, setPreviewData] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [originalLimit, setOriginalLimit] = useState();
  const [loader, setLoader] = useState(false);
  const [compressedLogoImage, setCompressedLogoImage] = useState();
  const [compressedCoverImage, setCompressedCoverImage] = useState();
  useEffect(() => {
    const fetch = async () => {
      if (props?.selectedRowData && isEdit1 && isEdit2) {
        setCollectionAddress(props?.selectedRowData?.collectionAddress);
        setLogoImage(props?.selectedRowData?.image);
        setCoverImage(props?.selectedRowData?.banner);
        setProductLimit(props?.selectedRowData?.productLimit);
        setOriginalLimit(props?.selectedRowData?.productLimit);
        console.log("111111");

        if (props?.selectedRowData?.email) {
          setEmail(props?.selectedRowData?.email);
        }
        setNetwork(getNetworkNameByChain[props?.selectedRowData?.chainId]);
        setPreviewData({
          ...previewData,
          edit: true,
          productLimit: props?.selectedRowData?.productLimit,
          logoImage: props?.selectedRowData?.image,
          coverImage: props?.selectedRowData?.banner,
        });
      }
    };
    fetch();
  }, [props]);

  useEffect(() => {
    const fetch = async () => {
      setOwner("");
      setCollectionName("");
      await getOwner(props?.selectedRowData?.collectionAddress, network);
    };
    fetch();
  }, [network]);

  let getOwner = async (contractAddress, _network) => {
    setLoader(true);
    try {
      let collectionContract = await exportInstance(contractAddress, erc721Abi, _network);
      try {
        let _owner = await collectionContract.owner();
        setOwner(_owner);
      } catch (e) {
        if (e.code === "CALL_EXCEPTION") {
          toast.error(messages.ContractNetworkError);
          return;
        }
        console.log("err", e);
      }
      let _collectionName = await collectionContract.name();
      setCollectionName(_collectionName);
      setPreviewData({
        ...previewData,
        cName: _collectionName,
      });
      setLoader(false);
    } catch (e) {
      setLoader(false);
      console.log("e", e.code);
      if (e.code === "CALL_EXCEPTION") {
        toast.error(messages.ContractNetworkError);
        return;
      }
      console.log("err", e);
    }
  };

  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);

  const handleImageUpload = async (e) => {
    const type = e.target.files[0].type;
    if (
      type !== "image/webp" &&
      type !== "image/jpeg" &&
      type !== "image/jpg" &&
      type !== "image/png" &&
      type !== "image/gif"
    ) {
      toast.error("Please enter recommended file format");
      return;
    }
    setIsEdit1(false);
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      // const type1 = file?.type?.split("/");
      // const fileType = type1[1];
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
      if (e.target.files && e.target.files[0]) {
        ImageResizer.imageFileResizer(
          file,
          150, // width
          150, // height
          "webp", // format
          100, // quality
          0, // rotation
          (uri) => {
            setCompressedLogoImage(uri);
          },
          "blob" // outputType
        );
        setLogoImage(e.target.files[0]);
        setPreviewData({
          ...previewData,
          logoImage: URL.createObjectURL(e.target.files[0]),
        });
      }
    }
  };

  const uploadedImage2 = React.useRef(null);
  const imageUploader2 = React.useRef(null);

  const handleImageUpload2 = (e) => {
    const type = e.target.files[0].type;
    if (
      type !== "image/webp" &&
      type !== "image/jpeg" &&
      type !== "image/jpg" &&
      type !== "image/png" &&
      type !== "image/gif"
    ) {
      toast.error("Please enter recommended file format");
      return;
    }

    setIsEdit2(false);
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage2;
      current.file = file;
      // const type1 = file?.type?.split("/");
      // const fileType = type1[1];
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
      if (e.target.files && e.target.files[0]) {
        ImageResizer.imageFileResizer(
          file,
          300, // width
          200, // height
          "webp", // format
          100, // quality
          0, // rotation
          (uri) => {
            setCompressedCoverImage(uri);
          },
          "blob" // outputType
        );
        setCoverImage(e.target.files[0]);
        setPreviewData({
          ...previewData,
          coverImage: URL.createObjectURL(e.target.files[0]),
        });
      }
    }
  };

  let postData = async () => {
    try {
      setShowPreview(false);

      setAddEvent(1);
      if (originalLimit > productLimit) {
        toast.error(messages.previousLimitError);
        setAddEvent(0);
        return;
      }
      let fd = new FormData();
      fd.append("collectionAddress", collectionAddress);
      fd.append("owner", owner.toLowerCase());
      fd.append("name", collectionName);
      fd.append("logo", logoImage);
      fd.append("lowlogo", compressedLogoImage);
      fd.append("cover", coverImage);
      fd.append("lowcover", compressedCoverImage);
      fd.append("chainId", networks[network]?.chainId);
      fd.append("productLimit", productLimit);
      if (email !== "" && email !== undefined) {
        fd.append("email", email);
        if (!validator.isEmail(email)) {
          toast.error(messages.invalidEmail);
          setAddEvent(0);
          return;
        }
      }
      let res = await updateCollectionDetails(fd);
      if (res) {
        toast.success(messages.updateCollectionSuccess);
        await props.handleReloadCollection();
        setAddEvent(2);
      } else {
        toast.error(messages.updateCollectionError);
        setAddEvent(0);
      }
    } catch (e) {
      toast.error(messages.updateCollectionError);
      console.log("error of add collection data", e);
    }
  };

  eventEmitter.removeAllListeners("editCollection");
  eventEmitter.on("editCollection", postData);

  const handlePreviewCollection = async () => {
    try {
      if (owner == "") {
        toast.error(validationMessage("Owner to be added", "Wait for"));
        return;
      }
      if (collectionName == "") {
        toast.error(validationMessage("Collection Name to be added", "Wait for"));
        return;
      }

      if (logoImage === "" || logoImage === null || logoImage === undefined) {
        toast.error(validationMessage("Collection Logo", "Choose"));
        return;
      }
      if (coverImage === "" || coverImage === null || coverImage === undefined) {
        toast.error(validationMessage("Collection Cover Image", "Choose"));
        return;
      }
      console.log("previewData=====>", previewData);
      setShowPreview(true);
    } catch (e) {
      toast.error(messages.addCollectionError);
      console.log("error of add collection data", e);
    }
  };
  const UploadCoverHandle = () => {
    document.getElementById("uploadCoverImg").click();
  };

  const UploadLogoHandle = () => {
    document.getElementById("uploadLogoImg").click();
  };

  return addEvent === 1 ? (
    <Loader />
  ) : addEvent === 2 ? (
    "Completed"
  ) : (
    <Dialog
      className={`${classes.root} borderRd25`}
      fullWidth
      maxWidth="md"
      open={props.show}
      onClose={() => {
        props.handleClose();
      }}
      id="modal_container"
    >
      {showPreview ? (
        <AddCollectionPreview
          show={true}
          previewData={previewData}
          handleClose={() => setShowPreview(false)}
        />
      ) : (
        ""
      )}

      <DialogContent className={classes.padding} style={{ overflow: "hidden" }}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container direction="row" className={classes.mainHeader}>
              <Grid item xs={11}>
                <Typography variant="h5">Edit Collection Form</Typography>
              </Grid>
              <Grid
                item
                xs={1}
                align="right"
                className={classes.padding}
                onClick={() => props.handleClose()}
              >
                <IconButton
                  edge="start"
                  align="right"
                  color="inherit"
                  aria-label="Close"
                  style={{ padding: 8 }}
                >
                  <img src={closeIcon} alt="close" />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              className={classes.mainContent}
              spacing={1}
            >
              <div className="outerrrBorder">
                <Grid item xs={10} className="modalInput addCollectionInputs">
                  <label htmlFor="InputAddress" className="required">
                    Collection Address
                  </label>
                  <input
                    id="InputAddress"
                    className="form-control"
                    placeholder="Please enter collection address"
                    value={collectionAddress}
                    disabled
                  />
                </Grid>

                <Grid item xs={10} className="modalInput addCollectionInputs">
                  <label htmlFor="InputNetwork" className="required">
                    Network
                  </label>
                  <select
                    id="InputNetwork"
                    className="form-select"
                    aria-label="Select Option"
                    value={
                      network === ""
                        ? getNetworkNameByChain[props?.selectedRowData?.chainId]
                        : network
                    }
                    disabled={props?.selectedRowData?.isImported}
                    onChange={(e) => {
                      setNetwork(e.target.value);
                    }}
                  >
                    <option selected>Please select network</option>
                    {supportedNetworks
                      ? supportedNetworks.map((n, i) => {
                          return (
                            <option key={i} value={n}>
                              {n}
                            </option>
                          );
                        })
                      : ""}
                  </select>
                </Grid>

                <Grid item xs={10} className="modalInput addCollectionInputs">
                  <label htmlFor="InputName" className="required">
                    Name
                  </label>
                  <input
                    id="InputName"
                    className="form-control"
                    placeholder={`${
                      collectionName === "" || collectionName === undefined
                        ? "Loading..."
                        : "Contract Name"
                    }`}
                    value={collectionName}
                    disabled
                  />
                </Grid>

                <Grid item xs={10} className="modalInput addCollectionInputs">
                  <label htmlFor="InputOwner" className="required">
                    Owner
                  </label>
                  <input
                    id="InputOwner"
                    className="form-control"
                    placeholder={`${owner === "" || owner === undefined ? "Loading..." : "Owner"}`}
                    value={owner}
                    disabled
                  />
                </Grid>

                <Grid item xs={10} className="modalInput addCollectionInputs">
                  <label htmlFor="InputProductLimit" className="required">
                    Items Limit
                  </label>
                  <input
                    id="InputProductLimit"
                    className="form-control"
                    placeholder="Please enter items limit"
                    value={productLimit}
                    onChange={(e) => {
                      if (allnumeric(Number(e.target.value)) || e.target.value === "") {
                        setProductLimit(e.target.value);
                        previewData.productLimit = e.target.value;
                      } else {
                        toast.error(messages.invalidChar);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={10} className="modalInput addCollectionInputs">
                  <label htmlFor="InputEmail">Email</label>
                  <input
                    id="InputEmail"
                    className="form-control"
                    placeholder="Please enter your email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={10} className="modalInput addCollectionInputs">
                  <label> </label>
                  <div className="form-control d-flex flex-wrap border-0 px-0">
                    <div className="chooseImageDiv">
                      <h6 className="required">Logo Image</h6>
                      <div className="chooseImageDivInner">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          className="btnstyle"
                        >
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            ref={imageUploader}
                            style={{
                              display: "none",
                            }}
                            id="uploadLogoImg"
                          />
                          <div
                            className="update_btn pointer"
                            style={{
                              height: "100px",
                              width: "100%",
                              position: "relative",
                            }}
                            onClick={() => imageUploader.current.click()}
                          >
                            {isEdit1 ? (
                              <img
                                alt=""
                                ref={uploadedImage}
                                src={logoImage}
                                className="img-fluid profile_circle_img admin_profile_img"
                              />
                            ) : (
                              <img
                                alt=""
                                ref={uploadedImage}
                                src={URL.createObjectURL(logoImage)}
                                className="img-fluid profile_circle_img admin_profile_img"
                              />
                            )}
                          </div>
                          <button
                            type="button"
                            className="pointer text-center mt-2 justify-content-center "
                            onClick={UploadLogoHandle}
                          >
                            Choose File
                          </button>
                        </div>
                      </div>

                      <p className="recommendedText">We recommend image of at least 414*200 .PNG</p>
                    </div>
                    <div className="chooseImageDiv">
                      <h6 className="required">Cover Image</h6>
                      <div className="chooseImageDivInner">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          className="btnstyle"
                        >
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload2}
                            ref={imageUploader2}
                            style={{
                              display: "none",
                            }}
                            id="uploadCoverImg"
                          />
                          <div
                            className="update_btn pointer"
                            style={{
                              height: "100px",
                              width: "100%",
                              position: "relative",
                            }}
                            onClick={() => imageUploader2.current.click()}
                          >
                            {isEdit2 ? (
                              <img
                                alt=""
                                ref={uploadedImage2}
                                src={coverImage}
                                className="img-fluid profile_circle_img admin_profile_img"
                              />
                            ) : (
                              <img
                                alt=""
                                ref={uploadedImage2}
                                src={URL.createObjectURL(coverImage)}
                                className="img-fluid profile_circle_img admin_profile_img"
                              />
                            )}
                          </div>
                          <button
                            type="button"
                            className="pointer text-center mt-2 justify-content-center"
                            onClick={UploadCoverHandle}
                          >
                            Choose File
                          </button>
                        </div>
                      </div>
                      <p className="recommendedText">We recommend image of at least 414*200 .PNG</p>
                    </div>
                  </div>
                </Grid>
              </div>
              <Grid item xs={10} align="center">
                <Button
                  variant="contained"
                  className="textCapitalize"
                  color="inherit"
                  id="button-submit"
                  onClick={handlePreviewCollection}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default withStyles(styles)(UpdateCollection);