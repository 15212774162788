

// @mui material components
import Card from "@mui/material/Card";


// Custom styles for the SidenavCard
import { card } from "components/pages/Sidenav/styles/sidenavCard";

// Vision UI Dashboard React context
import { useVisionUIController } from "context";

function SidenavCard({ color, ...rest }) {
  const [controller] = useVisionUIController();
  const { miniSidenav, sidenavColor } = controller;

  return (
    <Card sx={(theme) => card(theme, { miniSidenav })} />
  );
}

export default SidenavCard;
