// @mui material components
import Card from "@mui/material/Card";
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Footer from "../Footer";
import Table from "../Tables/Table";
import "./../../../App.css";
import VuiButton from "components/VuiButton";
import { useEffect, useState, useRef } from "react";
import AddCollections from "./addCollections";
import eventEmitter from "./../../../events/events";
import { handleImport } from "helpers/setters";
import { checkImportStatus } from "apiServices";
import CircularUnderLoad from "components/common/spinner";
import toast from "react-hot-toast";
import { handleUpdateImportedCollection } from "helpers/setters";
import { updateCollectionStatus } from "apiServices";
import { messages } from "config/messages";
import { handleDelete } from "helpers/setters";
import UpdateCollections from "./updateCollections";
import deleteIcon from "../../../assets/images/logos/delete.png";
import editIcon from "../../../assets/images/logos/edit.png";
import isHot from "../../../assets/images/logos/isHot.png";
import isNotHot from "../../../assets/images/logos/notHot.png";
import { supportedNetworks } from "config/supportedNetworks";
import ConfirmationModal from "components/common/ConfirmationModal";
import { blockUnblockCollection } from "apiServices";
import { getFilteredCollections } from "apiServices";
import { networks } from "config/rpcs";
import { collectionFilterFields } from "helpers/constants";
import { collectionTableColumns } from "helpers/constants";
import { isValidAddress } from "helpers/getters";
import { hotCollectionUpdate } from "apiServices";
import Pagination, { bootstrap5PaginationPreset } from "react-responsive-pagination";
import { capitalizeWords } from "helpers/getters";
import { pageLimit } from "helpers/constants";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Loader from "components/common/Loader";
import { CSVLink } from "react-csv";
import { connectedrole } from "config/localStorageNames";
import { handleCopyToClipboard } from "helpers/getters";
import { deleteCollection } from "./../../../apiServices";

function Collections(props) {
  const [openForm, setOpenForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [collections, setCollections] = useState([]);
  const [reloadCollections, setReloadCollections] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toggleNotesBtn, setToggleNotesBtn] = useState("disable-notes");
  const [editForm, setEditForm] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState();
  const [searchField, setSearchField] = useState("ALL");
  const [searchedText, setSearchedText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [counts, setCounts] = useState();
  const [loader, setLoader] = useState(false);
  const [allCollections, setAllCollections] = useState([]);
  const [updtDisable, setUpdtDisable] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [searched, setSearched] = useState(false);
  const [toggleSearch, setToggleSearch] = useState(false)



  useEffect(() => {
    if (localStorage.getItem(connectedrole) !== "admin") {
      window.location.href = "/admin";
    }
  }, [reloadCollections]);

  useEffect(() => {
    const fetchCollections = async () => {
      setLoading(true);
      let coll = await getFilteredCollections({ page: currentPage, limit: pageLimit });
      let count = coll?.count;
      setCounts(count);
      if (coll && coll?.results?.length > 0) {
        setCollections(coll?.results[0]);
      }

      let req = {
        page: 1,
        limit: 100000,
      };

      let exportableItems = await getFilteredCollections(req);

      if (exportableItems && exportableItems?.results?.length > 0) {
        setAllCollections(exportableItems?.results[0]);
      }
      setLoading(false);
    };
    fetchCollections();
  }, []);

  useEffect(() => {
    console.log("handleSearch useEffect", currentPage, searchField, searched)
    setUpdtDisable(false);
    if (searchField === "ALL" && searched) {
      setSearchedText("");
      setSearched(false);
    }
    handleSearch(searched);
  }, [searched, currentPage, toggleSearch, reloadCollections]);

  useEffect(() => {
    let _rows =
      collections?.length > 0 && collections !== "none"
        ? collections?.map((c, i) => {
            console.log("collections=====>", collections);
            return {
              Collection: (
                <VuiTypography variant="button" mb="4px">
                  {c?.collectionName}{" "}
                  <VuiTypography
                    variant="input"
                    mb="4px"
                    className="copy-icon"
                    onClick={() => handleCopyToClipboard(c?.collectionAddress)}
                  >
                    (
                    {c?.collectionAddress?.slice(0, 5) +
                      "..." +
                      c?.collectionAddress?.slice(38, 42)}
                    )
                    <ContentCopyIcon />
                  </VuiTypography>
                </VuiTypography>
              ),
              Email: (
                <VuiTypography variant="button" mb="4px" style={{ textTransform: "lowercase" }}>
                  {c?.email}
                </VuiTypography>
              ),
              "Chain ID": (
                <VuiTypography variant="button" mb="4px">
                  {c?.chainId}
                </VuiTypography>
              ),
              Status: (
                <VuiTypography
                  className="underlinedText"
                  title="Check import status"
                  component="a"
                  href="#"
                  variant="caption"
                  onClick={async () => {
                    let res = await checkImportStatus({
                      collectionAddress: c?.collectionAddress,
                      chainId: c?.chainId,
                    });

                    if (res[0]?.status === "error") {
                      toast.error(res[0]?.status);
                      let data = await updateCollectionStatus({
                        collectionAddress: c?.collectionAddress,
                        isImported: false,
                        isError: true,
                        chainId: c?.chainId,
                      });
                      setReloadCollections(!reloadCollections);
                      if (data === false) {
                        toast.error(messages.somethingsWrong);
                        return;
                      }
                    } else {
                      if (res[0]?.status === "available") {
                        let data = await updateCollectionStatus({
                          collectionAddress: c?.collectionAddress,
                          isImported: true,
                          isError: false,
                          chainId: c?.chainId,
                        });
                        setReloadCollections(!reloadCollections);
                        if (data === false) {
                          toast.error(messages.somethingsWrong);
                          return;
                        }
                      }
                      if (res?.length <= 0 || res[0]?.status === "") {
                        toast.error(messages.collectionImportIssue);
                      } else toast.success(capitalizeWords(res[0]?.status));
                    }

                    console.log(res[0]?.status, "res");
                  }}
                >
                  Check Status
                </VuiTypography>
              ),
              Hot: (
                <VuiTypography variant="button" mb="4px">
                  <ul className="hot-Ul">
                    <li>
                      <input
                        className="hotCheckBoxInput"
                        type="checkbox"
                        id={`hotCheckBoxInput1${i}`}
                        checked={c?.isHotCollection}
                        onChange={(e) => {
                          handleHotCollection(e, c);
                        }}
                      />
                      <label className="hotCheckBoxLabel pointer" htmlFor={`hotCheckBoxInput1${i}`}>
                        <img className="hotCheckBoxImg isNotHotImg" src={isNotHot} />
                        <img className="hotCheckBoxImg isHotImg" src={isHot} />
                      </label>
                    </li>
                  </ul>
                </VuiTypography>
              ),
              Block: (
                <VuiTypography variant="button" color="white" fontWeight="medium" mb="4px">
                  <div className="blockCheckbox">
                    <input
                      type="checkbox"
                      id={`checkbox${i}`}
                      checked={c?.isBlocked}
                      onChange={(e) => {
                        handleBlockedCollection(e, c);
                      }}
                    />
                    <label htmlFor={`checkbox${i}`}></label>
                  </div>
                </VuiTypography>
              ),
              Action: (
                <>
                  <VuiButton
                    variant="gradient"
                    color="info"
                    size="medium"
                    title="Import metadata of the collection"
                    style={{ marginRight: "5px" }}
                    disabled={c?.isImported}
                    onClick={async () => {
                      let req = {
                        collectionAddress: c?.collectionAddress,
                        collectionName: c?.collectionName,
                        chainId: c?.chainId,
                      };

                      let res = await checkImportStatus({
                        collectionAddress: c?.collectionAddress,
                        chainId: c?.chainId,
                      });

                      if (c.isImported === false) {
                        if (res[0]?.status == "available") {
                          toast.success(messages.alreadyImported);
                          await updateCollectionStatus({
                            collectionAddress: c?.collectionAddress,
                            isImported: true,
                            isError: false,
                            chainId: c?.chainId,
                          });
                          setReloadCollections(!reloadCollections);
                          return;
                        } else if (res[0]?.status == "initialise" || res[0]?.status == "update") {
                          toast.success(messages.inProcessing);
                          setReloadCollections(!reloadCollections);
                          return;
                        } else if (res[0]?.status === "error") {
                          toast.error(res[0]?.status);
                          await updateCollectionStatus({
                            collectionAddress: c?.collectionAddress,
                            isImported: false,
                            isError: true,
                            chainId: c?.chainId,
                          });
                          return;
                        } else if (res?.length === 0) {
                          toast.success(messages.importSuccess);
                        }
                      }

                      let importRes = await handleImport(req);
                      if (importRes === false) {
                        toast.error(messages.tryAgain);
                      }
                    }}
                  >
                    Import
                  </VuiButton>

                  <VuiButton
                    variant="gradient"
                    color="info"
                    title="Update metadata of the collection"
                    size="medium"
                    disabled={!c?.isImported || (updtDisable && selectedIndex === i)}
                    onClick={async () => {
                      setSelectedIndex(i);
                      setUpdtDisable(true);
                      let res = await checkImportStatus({
                        collectionAddress: c?.collectionAddress,
                        chainId: c?.chainId,
                      });
                      if (res[0]?.status == "initialise" || res[0]?.status == "update") {
                        toast.success(messages.inProcessing);
                        setReloadCollections(!reloadCollections);
                        return;
                      } else if (res[0]?.status === "error") {
                        toast.error(res[0]?.status);
                        await updateCollectionStatus({
                          collectionAddress: c?.collectionAddress,
                          isImported: false,
                          isError: true,
                          chainId: c?.chainId,
                        });
                        return;
                      } else if (res?.length === 0) {
                        toast.success(messages.importSuccess);
                      }

                      let req = {
                        collectionAddress: c?.collectionAddress,
                        collectionName: c?.collectionName,
                        chainId: c?.chainId,
                      };
                      let updateRes = await handleUpdateImportedCollection(req);
                      if (updateRes === false) {
                        toast.error(messages.tryAgain);
                        return;
                      }
                      setReloadCollections(!reloadCollections);
                      toast.success(messages.updateReqSent);
                      setUpdtDisable(false);
                    }}
                  >
                    Update
                  </VuiButton>
                  <VuiButton
                    className="edit-btnn whiteBg"
                    title="Delete metadata of the collection"
                    onClick={async () => {
                      setSelectedIndex(i);
                      setUpdtDisable(true);
                      // let res = await checkImportStatus({
                      //   collectionAddress: c?.collectionAddress,
                      //   chainId: c?.chainId,
                      // });
                      // if (res[0]?.status == "initialise" || res[0]?.status == "update") {
                      //   toast.success(messages.inProcessing);
                      //   setReloadCollections(!reloadCollections);
                      //   return;
                      // } else if (res[0]?.status === "error") {
                      //   toast.error(res[0]?.status);
                      //   await updateCollectionStatus({
                      //     collectionAddress: c?.collectionAddress,
                      //     isImported: false,
                      //     isError: true,
                      //     chainId: c?.chainId,
                      //   });
                      //   return;
                      // } else if (res?.length === 0) {
                      //   toast.success(messages.importSuccess);
                      // }
                      setSelectedRowData(c);
                      setConfirmation(true);
                      setUpdtDisable(false);
                    }}
                    disabled={!c?.isImported || (updtDisable && selectedIndex === i)}
                  >
                    <img src={deleteIcon} alt="delete" />
                  </VuiButton>
                  <VuiButton
                    title="Modify collection"
                    className="edit-btnn whiteBg"
                    disabled={updtDisable && selectedIndex === i}
                    onClick={async () => {
                      setSelectedIndex(i);
                      setUpdtDisable(true);
                      let res = await checkImportStatus({
                        collectionAddress: c?.collectionAddress,
                        chainId: c?.chainId,
                      });
                      if (
                        c.isImported === false &&
                        (res[0]?.status == "initialise" || res[0]?.status == "update")
                      ) {
                        toast.success(messages.importInProgress);
                        return;
                      }
                      setSelectedRowData(c);
                      setEditForm(true);
                      setUpdtDisable(false);
                    }}
                  >
                    <img src={editIcon} alt="edit" />
                  </VuiButton>
                </>
              ),
            };
          })
        : [];

    setRows(_rows);
  }, [collections, reloadCollections]);

  const handleEditClose = () => {
    setEditForm(false);
  };

  const handleSearch = async () => {
    console.log("inside handleSearch", searched, searchedText, searchField);
    setLoading(true);
    let req = { page: currentPage, limit: pageLimit };
    let coll;
    if (searched && searchField === "collectionAddress") {
      if (!isValidAddress(searchedText) && searchedText !== "") {
        toast.error(messages.invalidAddress);
        setLoading(false);
        return;
      }
      req = {
        ...req,
        collectionAddress: searchedText,
      };
    } else if (searched && searchField === "collectionName") {
      req = {
        ...req,
        collectionName: searchedText,
      };
    } else if (searched && searchField === "owner") {
      if (!isValidAddress(searchedText) && searchedText !== "") {
        toast.error(messages.invalidAddress);
        setLoading(false);
        return;
      }
      req = {
        ...req,
        owner: searchedText,
      };
    } else if (searched && searchField === "email") {
      req = {
        ...req,
        email: searchedText,
      };
    } else if (searched && searchField === "isImported") {
      req = {
        ...req,
        isImported: searchedText,
      };
    } else if (searched && searchField === "isBlocked") {
      req = {
        ...req,
        isBlocked: searchedText,
      };
    } else if (searched && searchField === "chainId") {
      req = {
        ...req,
        chainId: networks[searchedText]?.chainId,
      };
    } else if (searched && searchField === "isHotCollection") {
      req = {
        ...req,
        isHotCollection: searchedText,
      };
    }

    coll = await getFilteredCollections(req);

    req.page = 1;
    req.limit = 10000;

    let exportableItems = await getFilteredCollections({ ...req });

    if (exportableItems && exportableItems?.results?.length > 0) {
      setAllCollections(exportableItems?.results[0]);
    }

    // let coll = await getFilteredCollections({})
    if (coll && coll?.results?.length > 0) {
      setCollections(coll?.results[0]);
      setCounts(coll?.count);
    } else {
      setCollections([]);
      setCounts(0);
    }
    console.log("collections list in handle search====>", coll?.results[0]);
    setLoading(false);
    // }
  };

  const handleConfirmClose = () => {
    setConfirmation(false);
  };

  const handleConfirm = async () => {
    setConfirmation(false);
    let req = {
      collectionAddress: (selectedRowData?.collectionAddress).toLowerCase(),
      collectionName: selectedRowData?.collectionName,
      chainId: selectedRowData?.chainId,
    };

    let delRes = await handleDelete(req);
   
    if (delRes === false) {
     
      toast.error(messages.tryAgain);
      return;
    }
    else{
      console.log("inside else block")
      let res = await deleteCollection(req);
      console.log("res", res)
    }
    setReloadCollections(!reloadCollections);
    toast.success(messages.colDelSuccess);
  };

  const getSearchOptions = () => {
    if (
      searchField === "isBlocked" ||
      searchField === "isHotCollection" ||
      searchField === "isImported"
    ) {
      return ["true", "false"];
    } else if (searchField === "chainId") {
      return supportedNetworks;
    } else return [];
  };

  const showSelect = () => {
    if (
      searchField == "isImported" ||
      searchField == "isBlocked" ||
      searchField == "chainId" ||
      searchField == "isHotCollection"
    ) {
      return true;
    }
    return false;
  };
  const handleBlockedCollection = async (e, data) => {
    e.preventDefault();
    setLoader(true);
    const { checked, id } = e.target;
    let reqData = {
      collectionAddress: data?.collectionAddress,
      isBlock: checked,
    };

    let res = await blockUnblockCollection(reqData);

    if (res !== {}) {
      setLoader(false);
      setCurrentPage(1);
      setReloadCollections(!reloadCollections);
      toast.success(`${checked ? messages.addedToBlock : messages.removedFromBlock}`);
    } else {
      setLoader(false);
      toast.error(messages.updateCollectionError);
    }
  };

  const handleHotCollection = async (e, data) => {
    e.preventDefault();
    console.log("Clickeddd");
    setLoader(true);
    const { checked, id } = e.target;
    let reqData = {
      collectionAddress: data?.collectionAddress,
      isHotCollection: checked,
    };

    let res = await hotCollectionUpdate(reqData);
    if (res !== {}) {
      setLoader(false);
      setCurrentPage(1);
      setReloadCollections(!reloadCollections);
      toast.success(`${checked ? messages.addedToHot : messages.removedFromHot}`);
    } else {
      setLoader(false);
      toast.error(messages.updateCollectionError);
    }
  };

  const nextPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleReloadCollection = () => {
    setSearchField("ALL");
    setSearched(false);
    setSearchedText("");
    setReloadCollections(!reloadCollections);
    setOpenForm(false);
    setEditForm(false);
  };

  const handleButtonToggle = () => {
    if (toggleNotesBtn === "active-notes") {
      setToggleNotesBtn("disable-notes");
    } else {
      setToggleNotesBtn("active-notes");
    }
  };

  eventEmitter.removeAllListeners("reload");
  eventEmitter.on("reload", handleReloadCollection);

  const handleClose = () => {
    setOpenForm(false);
  };

  return (
    <div className="collection__main">
      {loader ? <Loader /> : ""}

      <VuiBox alignItems="center" mb="22px">
        <VuiTypography variant="lg" color="black">
          <div className="field_Bar">
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                if (searchedText === "") {
                  return;
                }
                setCurrentPage(1);
                setSearched(true);
                setToggleSearch(!toggleSearch);
              }}
            >
              <div className="fields_div">
                <select
                  className="form-select"
                  value={searchField}
                  onChange={(e) => {
                    setSearchedText("");
                    setSearched(false)
                    setSearchField(e.target.value);
                  }}
                  aria-label="Select Option"
                >
                  {collectionFilterFields &&
                    collectionFilterFields?.map((field, i) => {
                      return (
                        <option key={i} value={field?.value} selected>
                          {field?.key}
                        </option>
                      );
                    })}
                </select>

                {searchField !== "ALL" && searchField !== "" && (
                  <>
                    {showSelect() && (
                      <select
                        className="form-select search_field"
                        value={searchedText}
                        onChange={(e) => {
                          setSearched(false)
                          setSearchedText(e.target.value);
                        }}
                        aria-label="Select Option"
                      >
                        <option value="" selected>
                          Select Option
                        </option>
                        {getSearchOptions() && getSearchOptions()?.length > 0
                          ? getSearchOptions().map((o, i) => {
                              return (
                                <option key={i} value={o}>
                                  {o?.toUpperCase()}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    )}
                    {!showSelect() && (
                      <input
                        className="form-control search_field"
                        id="Input1"
                        value={searchedText}
                        onChange={(e) => {
                          setSearched(false)                         
                           setSearchedText(e.target.value.replace(/\s/g, ""));
                        }}
                        placeholder="Enter Search"
                      />
                    )}{" "}
                  </>
                )}
                <VuiButton
                  className="logoutBtn"
                  size="medium"
                  disabled={searchedText === "" && searchField !== "ALL"}
                  onClick={(e) => {
                    console.log("clicked!!", searched, currentPage)
                    e.preventDefault();
                    setCurrentPage(1);
                    setSearched(true);
                    setToggleSearch(!toggleSearch)
                  }}
                >
                  Search
                </VuiButton>
              </div>
              <VuiButton
                variant="gradient"
                color="info"
                size="medium"
                onClick={() => setOpenForm(true)}
              >
                {" "}
                Add Collection
              </VuiButton>
            </form>
          </div>
        </VuiTypography>
      </VuiBox>

      {confirmation ? (
        <ConfirmationModal
          header="Confirm Delete"
          handleClose={handleConfirmClose}
          handleConfirm={handleConfirm}
        />
      ) : (
        ""
      )}
      {openForm ? (
        <AddCollections
          show={true}
          handleClose={handleClose}
          handleReloadCollection={handleReloadCollection}
        />
      ) : (
        ""
      )}
      {editForm ? (
        <UpdateCollections
          show={true}
          handleClose={handleEditClose}
          selectedRowData={selectedRowData}
          handleReloadCollection={handleReloadCollection}
        />
      ) : (
        ""
      )}
      {/* <DashboardNavbar /> */}
      <VuiBox className="contentBox" py={3}>
        <VuiBox mb={3}>
          <Card className="contentTable">
            <VuiBox>
              <Table columns={collectionTableColumns} rows={rows} />
            </VuiBox>
            {loading ? (
              <CircularUnderLoad />
            ) : collections?.length === 0 ? (
              <VuiTypography variant="lg" className="NoData">
                No Collection Found
              </VuiTypography>
            ) : (
              ""
            )}
          </Card>

          <CSVLink
            data={allCollections}
            filename={"CollectionList.csv"}
            headers={[
              {
                label: "Collection Address",
                key: "collectionAddress",
              },
              {
                label: "Collection Name",
                key: "collectionName",
              },
              {
                label: "Email",
                key: "email",
              },
              {
                label: "Chain Id",
                key: "chainId",
              },
              {
                label: "Hot",
                key: "isHotCollection",
              },
              {
                label: "Block",
                key: "isBlocked",
              },
            ]}
          >
            <VuiButton variant="gradient" color="info" size="small">
              {" "}
              Export
            </VuiButton>
          </CSVLink>
          {Math.ceil(counts / pageLimit) > 1 && (
            <Pagination
              {...bootstrap5PaginationPreset}
              current={currentPage}
              total={Math.ceil(counts / pageLimit)}
              onPageChange={nextPage}
            />
          )}
        </VuiBox>
      </VuiBox>
      <div className={`sidebarNotesBtn ${toggleNotesBtn === "active-notes" ? "active" : ""}`}>
        <div className="toggleNotesBtn" onClick={handleButtonToggle}>
          Note
        </div>
        <div className={toggleNotesBtn}>
          Collection which you are adding should have Metadata URI set for each Token.
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Collections;
