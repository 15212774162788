import React, { useState, useEffect } from "react";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import { useHistory, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { ColorRing } from "react-loader-spinner";
import { getFaqList, updateFaq } from "../../../apiServices";
import "./faq.css";
const UpdateFaq = () => {
  const [faqId, setFaqId] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(true);
  const [loader, setLoader] = useState();
  const [disabledSubscribe, setDisabledSubscribe] = useState();
  const params = useParams();
  const history = useHistory();
  const [faqList, setFaqList] = useState([]);
  useEffect(async () => {
    let res = await getFaqList();
    console.log("getFaqList------->", res.data);
    let data = res.data;

    const updateDetail = data.filter((ele) => {
      return ele._id === params.id;
    });
    setUsersData(updateDetail[0]);
  }, []);

  useEffect(() => {
    setFaqId(usersData._id);
    setQuestion(usersData.question);
    setAnswer(usersData.answer);
  }, [usersData]);
  const updateFaqHandler = async (e) => {
    console.log("isAdmin------->", isAdmin);
    e.preventDefault();
    setLoader(true);
    setDisabledSubscribe(true);
    if (question.trim() == "") {
      setTimeout(() => {
        toast.error("Please Question Field");
        setLoader(false);
        setDisabledSubscribe(false);
      }, 800);
      return;
    } else if (answer.trim() == "") {
      setTimeout(() => {
        toast.error("Please Fill Answer Field");
        setLoader(false);
        setDisabledSubscribe(false);
      }, 800);
      return;
    } else {
      let data = { faqId, question, answer, isAdmin };
      const res = await updateFaq(data);
      console.log(res);
      if (res.statusCode == 200) {
        setQuestion("");
        setAnswer("");
        setTimeout(() => {
          toast.success("FAQ Updated");
          setLoader(false);
          setDisabledSubscribe(false);
          history.push("/faqList");
        }, 1000);
      } else if (res.statusCode == 406) {
        setTimeout(() => {
          toast.error("Role Invalid");
          setLoader(false);
          setDisabledSubscribe(false);
        }, 1000);
      } else {
        setTimeout(() => {
          toast.error("FAQ Saving Error!");
          setLoader(false);
          setDisabledSubscribe(false);
        }, 1000);
      }
    }
  };
  return (
    <>
      <div className="collection__main">
        <VuiBox alignItems="center" mb="22px">
          <VuiTypography variant="lg" color="black" align="center">
            <div className="field_Bar">
              <form action="">
                <div className="fields_div">
                  {/* <select
                    className="form-select"
                    value={searchField}
                    onChange={(e) => {
                      setSearchField(e.target.value);
                    }}
                    aria-label="Select Option"
                  >
                    {collectionFilterFields &&
                      collectionFilterFields?.map((field, i) => {
                        return (
                          <option key={i} value={field?.value} selected>
                            {field?.key}
                          </option>
                        );
                      })}
                  </select>

                  {searchField !== "ALL" && searchField !== "" && (
                    <>
                      {showSelect() && (
                        <select
                          className="form-select search_field"
                          value={searchedText}
                          onChange={(e) => {
                            setSearchedText(e.target.value);
                          }}
                          aria-label="Select Option"
                        >
                          <option value="" selected>
                            Select Option
                          </option>
                          {getSearchOptions() && getSearchOptions()?.length > 0
                            ? getSearchOptions().map((o, i) => {
                                return (
                                  <option key={i} value={o}>
                                    {o?.toUpperCase()}
                                  </option>
                                );
                              })
                            : ""}
                        </select>
                      )}
                      {!showSelect() && (
                        <input
                          className="form-control search_field"
                          id="Input1"
                          onChange={(e) => {
                            setSearchedText(e.target.value.replace(/\s/g, ""));
                          }}
                          placeholder="Enter Search"
                        />
                      )}{" "}
                    </>
                  )}
                  <VuiButton
                    className="logoutBtn"
                    size="medium"
                    disabled={searchedText === "" && searchField !== "ALL"}
                    onClick={handleSearch}
                  >
                    Search
                  </VuiButton> */}
                </div>
                <VuiButton
                  variant="gradient"
                  color="info"
                  size="medium"
                  onClick={() => history.push("/faqList")}
                >
                  FAQ List
                </VuiButton>
              </form>
            </div>
            <section className="Banner_section my-0">
              <div className="container_FAQ text-center">
                <div className="banner_container">
                  <h2>Update Frequently Asked Questions</h2>
                </div>
              </div>
            </section>

            <section className="hot_collection">
              <div className="col-md-8 offset-md-2 mt-md-5">
                <form className="needs-validation">
                  <div className="form-row row ">
                    <div className="col-md-12 mx-auto mb-3 mt-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type Question"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        required
                        disabled={disabledSubscribe}
                      />
                    </div>

                    <div className="col-md-12 mx-auto mb-3 mt-3">
                      <textarea
                        className="form-control"
                        placeholder="Type Answer"
                        rows="7"
                        value={answer}
                        onChange={(e) => setAnswer(e.target.value)}
                        required
                        disabled={disabledSubscribe}
                      ></textarea>
                    </div>
                    <div className="col-md-12 mx-auto mt-3 mb-3 field_Bar" align="center">
                      <button
                        type="submit"
                        className="mx-auto justify-content-center"
                        onClick={updateFaqHandler}
                        disabled={disabledSubscribe}
                      >
                        Save
                        {loader ? (
                          <ColorRing
                            visible={true}
                            height="25"
                            width="25"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
                          />
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </VuiTypography>
        </VuiBox>
      </div>
    </>
  );
};

export default UpdateFaq;
